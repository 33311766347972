import Button from "components/Button/button";
import Field from "components/Field/field";
import QrReader from "modern-react-qr-reader";
import { QrCode } from "phosphor-react";
import { useRef, useState } from "react";
import { MobileView } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import styles from "./surveyHome.module.scss";

const SurveyHome = () => {
  const navigate = useNavigate();
  const [orderCode, setOrderCode] = useState(null)

  const videoRef = useRef(null);
  const [showCamera, setShowCamera] = useState(false);
  const [showInput, setInput] = useState(true);

  const handleScan = (data) => {
    console.log(data); 
    if(data != null){
      window.location.replace(data)
    }
  };

  const handleError = (err) => {
    console.error(err);
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const getCameraPermission = async () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: false })
      .then(async (stream) => {
        setInput(false);
        await delay(1000);
        setShowCamera(true);
      });
  };

  return (
    <div className={styles.surveyHome}>
      <div>
        <div className={styles.surveyTitle}>ISE SURVEY</div>
        <div className={styles.surveyDescription}>Sistema de Avaliação</div>
        {showCamera ? (
          <>
            <QrReader
              ref={videoRef}
              constraints={{
                facingMode: "environment",
              }}
              style={{ width: "100%" }}
              onError={(data) => handleError(data)}
              onScan={(data) => handleScan(data)}
            ></QrReader>
            <div className={styles.surveyButton}>
              <Button
                className="secondaryOrange"
                onClick={() => window.location.reload(true)}
              >
                Cancelar
              </Button>
            </div>
          </>
        ) : null}
        {showInput ? (
          <>
            <div className={styles.surveyFieldContainer}>
              <div className={styles.surveyFieldLabel}>
                Digite o código da avaliação para iniciar
              </div>
              <MobileView>
                <QrCode
                  className={styles.searchIcon}
                  delay={600}
                  size={32}
                  onClick={() => {
                    getCameraPermission();
                  }}
                />
              </MobileView>
              <Field onChange={(event) => setOrderCode(event.target.value)} className={styles.surveyField}></Field>
            </div>
            <div className={styles.surveyButton}>
              <Button onClick={() => navigate(`/${orderCode}`)} className="primaryOrange">Iniciar</Button>
            </div>
          </>
        ) : null}
      </div>
      <div className={styles.adminArea} onClick={() => navigate("/login")}>
        <span>Área administrativa</span>
      </div>
    </div>
  );
};

export default SurveyHome;

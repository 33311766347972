import { verifySession } from "Services/authenticationServices";

const stage = process.env.REACT_APP_STAGE;
export async function deleteUser(userId) {
  const fetchData = async (jwtToken) => {
    fetch(
      `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users/` +
        userId,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      }
    )
      .then((res) => res.text()) // or res.json()
      .then((res) => console.log(res));
  };

  //Verifies user session to check if it's authenticated
  let getJwt = await verifySession();

  await fetchData(getJwt);
}

export async function deleteUsers(userIds) {
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        deletions: userIds,
      }),
    };

    fetch(
      `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users`,
      parameters
    )
      .then((res) => res.text()) // or res.json()
      .then((res) => console.log(res));
  };

  //Verifies user session to check if it's authenticated
  let getJwt = await verifySession();

  await fetchData(getJwt);
}

export async function newPassword(userId, userEmail) {
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({
        user_email: userEmail,
      }),
    };

    // Return the fetch response with status code and response body
    const response = await fetch(
      `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users/${userId}/resetpassword`,
      parameters
    );

    // Extract the status code and response body
    const statusCode = response.status;
    const responseBody = await response.text(); // or response.json()

    return {
      statusCode,
      responseBody,
    };
  };

  // Verifies user session to check if it's authenticated
  let jwtToken = await verifySession();

  // Fetch data and return the result
  const result = await fetchData(jwtToken);
  return result;
}


export async function getAllUsers(setList, setLoading) {
  const users = await getUsers();
  console.log(users);
  setList(users);
  setLoading(false);
  return users
}

export async function getUsers() {
  let jwtToken = await verifySession();
  let users;

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(
    `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users`,
    parameters
  )
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      users = data.Users;
    });

  return users;
}

import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  Archive,
  ArrowsDownUp,
  DotsThree,
  FilePdf,
  PlusCircle,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";
import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import {
  archiveAssessmentById,
  getAllAssessmentByStatus,
} from "../assessmentListsServices";
import styles from "./closedAssessmentLists.module.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ClosedAssessmentLists = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [archiveConfirmationModal, setArchiveConfirmationModal] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState("");
  const [ascendingOrDescending, setAscendingOrDescending] = useState('ascending');
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  useEffect(() => {
    if(capabilities.includes("avaliações")) {
    if (resultList.length === 0) {
      getAllAssessmentByStatus(
        "closed",
        setResultList,
        setMemResultList,
        setLoading
      );
    }}
    else {
      setLoading(false);
    }
  }, []);

  const sortObjByKey = (key, order = null) => {
    let _resultList = [...resultList];

    const compare = (a, b) => {
      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      };

      const getDateValue = (obj, key) =>
        key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB);
        return order === 'ascending' ? parsedDateA - parsedDateB : parsedDateB - parsedDateA;
      } else {
        const type = typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        if (type === 'string') {
          const result = ascendingOrDescendingTitle === 'A'
            ? dateA.localeCompare(dateB)
            : dateB.localeCompare(dateA);
          setAscendingOrDescendingTitle(ascendingOrDescendingTitle === 'A' ? 'B' : 'A');
          return result;
        } else {
          return dateA - dateB;
        }
      }
    };

    if (memResultList.length === 0 && listIsSorted === false) {
      setMemResultList(resultList);
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      _resultList = listIsSorted ? memResultList : _resultList.sort(compare);
      setListIsSorted(!listIsSorted);
    }

    setResultList(_resultList);
    return _resultList;
  };

  const archiveAssessment = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);

    archiveAssessmentById(itemIdToEdit).then((response) => {
      if (response.status === 200) {
        setArchiveConfirmationModal(false);
        let message = "Avaliação arquivada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllAssessmentByStatus(
          "closed",
          setResultList,
          setMemResultList,
          setLoading
        );
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };

  const renderContent = () => {
    switch (loading) {
      case true:
        return (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        );
      case false:
        if(capabilities.includes("avaliações")) {
          return (
            <div>
              <TableControl
                resultList={resultList}
                setResultList={setResultList}
                originalListTable={memResultList}
                setTablePagination={setTablePagination}
                setSlice={setSlice}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                showByPageDefault={10}
                searchPlaceholder={"Buscar Item"}
                hideBulkActions={true}
                hideFilter={true}
              />
              <Pagination
                tablePagination={tablePagination}
                slice={slice}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
              {alerts.map((i, k) => (
                <Alert key={k} message={i.message} type={i.type} />
              ))}
              <table className={styles.table}>
                <thead>
                  <tr style={{ backgroundColor: "white" }}>
                    <th
                      style={{
                        width: "40vw",
                        textAlign: "left",
                        paddingLeft: "30px",
                      }}
                    >
                      Título
                      <ArrowsDownUp
                        onClick={() => sortObjByKey("title")}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "20%" }}>
                      Data da avaliação
                      <ArrowsDownUp
                        onClick={() => {
                          if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending');
                          else setAscendingOrDescending('ascending');
                          sortObjByKey("initialDate", ascendingOrDescending === 'descending' ? 'ascending' : 'descending');
                        }}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "20%" }}>
                      Final da Coleta
                      <ArrowsDownUp
                        onClick={() => {
                          if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending');
                          else setAscendingOrDescending('ascending');
                          sortObjByKey("finalDate", ascendingOrDescending === 'descending' ? 'ascending' : 'descending');
                        }}
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "20%" }}>
                      Autor
                      <ArrowsDownUp
                        onClick={() =>
                          sortObjByKey("author.first_name")
                        }
                        className={styles.arrowsDownUp}
                        size={16}
                      />
                    </th>
                    <th style={{ width: "15%" }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {slice.map((item, i) => {
                    let id = item.id;
                    return (
                      <tr key={i} id={"actions" + i} className={styles.tableRow}>
                        <td
                          className={
                            styles.assessmentRow +
                            " " +
                            styles.assessmentTitle +
                            " " +
                            styles.rowTitle
                          }
                        >
                          {item.title}
                        </td>
                        <td className={styles.assessmentRow}>
                          {item.initialDate}
                        </td>
                        <td className={styles.assessmentRow}>{item.finalDate}</td>
                        <td className={styles.assessmentRow}>
                          {item.author.first_name + " " + item.author.last_name}
                        </td>
                        <td className={styles.actionsContainer}>
                          <FilePdf
                            className={styles.pdfAssessmentButton}
                            size={28}
                          />
                          <div className={styles.helpPopupPdf}>Download PDF</div>
                          <ConfirmationModal
                            id={"archiveAssessment"}
                            iconComponent={
                              <>
                                <Archive
                                  size={28}
                                  className={styles.archiveActionButton}
                                  onClick={() => { }}
                                />
                                <div className={styles.helpPopupArchive}>
                                  Arquivar
                                </div>
                              </>
                            }
                            title={`Deseja realmente arquivar esta avaliação?`}
                            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                            onClick={() => {
                              setItemIdToEdit(item.id);
                              setArchiveConfirmationModal(true);
                            }}
                            open={archiveConfirmationModal}
                            onOk={() => archiveAssessment(showMoreActions[id])}
                            onCancel={() => {
                              setArchiveConfirmationModal(false);
                              let _showMoreActions = { ...showMoreActions };
                              _showMoreActions = {};
                              setShowMoreActions(_showMoreActions);
                            }}
                          />
                          <DotsThree
                            size={28}
                            className={styles.moreActionsButton}
                            onClick={() => {
                              let _showMoreActions = { ...showMoreActions };
                              _showMoreActions = {};
                              if (!showMoreActions[id]) {
                                _showMoreActions[id] = true;
                              }
                              setShowMoreActions(_showMoreActions);
                            }}
                          />
                          <div className={styles.helpPopupAction}>Mais ações</div>
                          {showMoreActions[id] === true ? (
                            <MoreActionsClosedList
                              index={i}
                              id={item.id}
                              setShowMoreActions={setShowMoreActions}
                              showMoreActions={showMoreActions}
                              alerts={alerts}
                              setAlerts={setAlerts}
                              setResultList={setResultList}
                              setMemResultList={setMemResultList}
                              setLoading={setLoading}
                            />
                          ) : null}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          );}
          else{
            return (
              <div className={styles.error}>
                <center>
                  <Alert
                    type={"danger"}
                    message={
                      "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                    }
                  />
                </center>
              </div>
            );
          }
      default:
        return null;
    }
  };

  return (
    <Layout>
      <div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.createAssessmentButton + " " + "primaryOrange"}
            onClick={() => navigate("/order/new")}
          >
            <PlusCircle size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Criar Avaliação</span>
          </Button>
          <Button
            title={"Avaliações Arquivadas"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedOrders")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
        </div>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Avaliações Encerradas</PageTitle>
        </div>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default ClosedAssessmentLists;

export const MoreActionsClosedList = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
  alerts,
  setAlerts,
  setResultList,
  setMemResultList,
  setLoading,
}) => {
  const navigate = useNavigate();

  const wrapperRef = useRef(null);

  const [activePopUp, setActivePopUp] = useState(true);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)} />
      </div>
      <div
        className={styles.popupOption}
        onClick={() =>
          window.open("/" + id + "/preview", '_blank').focus()
        }
      >
        Visualizar
      </div>
      <div
        className={styles.popupOption}
        onClick={() => window.open("/order/" + id, '_blank').focus()}
      >
        Editar
      </div>
    </div>
  );
};

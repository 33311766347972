import { verifySession } from "Services/authenticationServices";

const stage = process.env.REACT_APP_STAGE;
const baseAssessmentURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/`;

export async function getAssessmentById(id) {
  let jwtToken = await verifySession();
  let assessmentData;

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz",
    },
  };

  const getAssessmentByIdURL = baseAssessmentURL + id;

  await fetch(getAssessmentByIdURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      assessmentData = data;
    });

  return assessmentData;
}

export async function createAssessment(assessmentBody) {
  let apiResponse;
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(assessmentBody),
    };

    await fetch(baseAssessmentURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

export async function updateAssessmentById(assessmentBody, id) {
  let apiResponse;
  let jwtToken = await verifySession();
  console.log("updating assessment " + id)
  let updateAssessmentURL = baseAssessmentURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(assessmentBody),
    };

    await fetch(updateAssessmentURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function getSubmissionsCount(
  id,
  setAnswersCount,
  itemStatus,
  setDisableEdit
) {
  let jwtToken = await verifySession();
  let count;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  const getSubmissionsURL =
    `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/` +
    id +
    "/submissions/";

  await fetch(getSubmissionsURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      setAnswersCount(data.length);
      if (
        data.length > 0 &&
        (itemStatus == "ready" || itemStatus == "closed")
      ) {
        setDisableEdit(true);
      }
    });
}

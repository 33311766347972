import styles from "./Alert.module.scss";
import { Info } from "phosphor-react";

// - types:
// success -> green
// warning -> yellow
// danger -> red
// info -> grey

const Alert = ({ type, message, style }) => {
  return (
    <div className={styles.alertStructure + " " + styles[type]} style={style}>
      <Info size={22} className={styles.alertIcon} weight="fill" />{" "}
      <span>{message}</span>
    </div>
  );
};

export default Alert;

import React from "react";

import unarchiveSVG from "../../assets/icons/unarchive.svg";

export const UnarchiveIcon = ({ className, size, onClick }) => {
  return (
    <img
      className={className}
      style={{ width: size + "px", paddingBottom: "2px" }}
      src={unarchiveSVG}
      alt={"Desarquivar"}
      onClick={onClick}
    ></img>
  );
};

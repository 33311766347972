import { verifySession } from "Services/authenticationServices";
import { transformQuestionList } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseQuestionURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/questions`;

export async function getAllQuestions(setList, setMemList, setLoading) {
  const questions = await getQuestions();
  setList(questions);
  setMemList(questions);
  setLoading(false);
}

export async function getQuestions() {
  let jwtToken = await verifySession();
  let questionData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(baseQuestionURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      questionData = data;
      questionData = transformQuestionList(questionData);
    });

  return questionData;
}

import { SearchOutlined } from "@ant-design/icons";
import { Input, Select } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import Button from "components/Button/button";
import styles from "./tableControl.module.scss";
import TableFilter from "components/TableFilter/TableFilter";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import ReportsFilter from "components/ReportsList/reportsFilter/reportsFilter";
// import styled from "styled-components";

// Code Documentation:

// - originalListTable: objeto da lista de items que formam a tabela, mas que não será alterado, para que possamos manter sempre umaa lista original de dados.

// - resultList: (useState criado no pai, com valor inicial do originalListTable e passado para o componente tableControl) Através dele, iremos obter a quantidade de páginas necessárias e quais os itens ficam em cada página (slice), de acordo com exibição por página e quantidade de items, com ou sem search.

// - tablePagination: (useState criado no pai e passado para o componente tableControl) array contendo os números de páginas. ex: [1,2,3,4]

// - slice: (useState criado no pai e passado para o componente tableControl) array contendo os itens da página atual.

// - currentPage: (useState criado no pai, com valor inicial 1, e passado para o componente tableControl) responsável por atualizar o número da página escolhida/atual.

// - showByPageDefault: atributo com o valor (número) de quantidade de itens por página por default.

// - filterableParameters: the parameters of the listed entity that users will be able to apply filters with.

// - performBulkAction: is the bulkaction function that will be passed from the pattern component through this parameter in order to be executed wheen an user clicks on the button.

// - actionsList: are the bulk actions available
//ex of actionsList:
// const actionsList = [{ value: "delete", label: "excluir" }]

const TableControl = ({
  resultList,
  setResultList,
  originalListTable,
  actionsList,
  setTablePagination,
  setSlice,
  currentPage,
  setCurrentPage,
  showByPageDefault,
  searchPlaceholder,
  setSelectedBulkAction,
  performBulkAction,
  filterableParameters,
  hideBulkActions,
  hideFilter,
  ...props
}) => {
  const [itemsByPage, setItemsByPage] = useState(showByPageDefault);
  const [closingConfirmationModal, setClosingConfirmationModal] =
    useState(false);

  const showByPageList = [
    { value: 5, label: "5 por página" },
    { value: 10, label: "10 por página" },
    { value: 30, label: "30 por página" },
    { value: 50, label: "50 por página" },
  ];

  const url = window.location.href;

  const handleTableControl = useCallback(() => {
    const calculatePagination = () => {
      const paginationList = [];
      const numOfPages = Math.ceil(resultList.length / itemsByPage);
      let i = 1;
      for (let i = 1; i <= numOfPages; i++) {
        paginationList.push(i);
      }
      return paginationList;
    };

    const calculateSlice = () => {
      return resultList.slice(
        (currentPage - 1) * itemsByPage,
        currentPage * itemsByPage
      );
    };

    const sliceList = calculateSlice();
    setSlice([...sliceList]);
    const pagination = calculatePagination();
    setTablePagination([...pagination]);
  }, [itemsByPage, resultList, setTablePagination, currentPage, setSlice]);

  useEffect(() => {
    handleTableControl();
  }, [handleTableControl]);

  const searchItem__ = (event) => {
    const searchValue = event.target.value;
    //console.log(originalListTable)
  };

  const matchItem = (key, value, list) => {
    let retrieve = null;
    list.map((i) => {
      if (i[key] === value) {
        retrieve = i
        //console.log(retrieve)
      }
    })
    return retrieve
  }

  const searchItem = (event) => {
    const searchValue = event.target.value;
    //console.log(originalListTable)
    if (searchValue) {
      let valuesToSearchFor = []
      originalListTable.map((value) => {
        let obj = value
        if ("photo" in obj) {
          delete obj["photo"]
        } 
        if ("collection" in obj) {
          delete obj["collection"]
        }
        if ("createdAt" in obj) {
          delete obj["createdAt"]
        }
        valuesToSearchFor.push(obj)
      })
      const searchData = valuesToSearchFor.filter((item) => {

        let results = Object.values(item)
          .join("")
          .toLocaleLowerCase()
          .includes(searchValue.toLocaleLowerCase());
          //console.log(results)
        return results
      });
      if (searchData) {
        //console.log(searchData);
        let searchResults = [];
        //console.log(originalListTable)
        originalListTable.map((i) => {
          let checkIfItemShouldBeReturned;
          if("first_name" in i){
            checkIfItemShouldBeReturned = matchItem("first_name", i.first_name, searchData)
          }else{
            checkIfItemShouldBeReturned = matchItem("title", i.title, searchData)
          }
           
          console.log("check")
          console.log(checkIfItemShouldBeReturned)
          if (checkIfItemShouldBeReturned != null) {
            //console.log(i)
            searchResults.push(i)
          }
        })
        setResultList(searchResults);
        console.log(searchResults)
        setSlice(searchResults);
      } else {
        setResultList(originalListTable);
      }
    } else {
      setCurrentPage(1);
      setResultList(originalListTable);
    }
  };

  const searchOldItem = (event) => {
    const searchValue = event.target.value.toLocaleLowerCase();

    if (searchValue) {
        const searchResults = originalListTable.filter((item) => {
            const fieldsToSearch = [
                item.ID,
                item.post_title,
                item.Escola,
                item.Área,
                item.Programa,
                item.Turma,
                item.Empresa,
                item.Linha1,
                item.Linha2,
                item.Linha3,
                item.Linha4,
                item.Data
            ];

            return fieldsToSearch.some((field) => 
                field && field.toString().toLocaleLowerCase().includes(searchValue)
            );
        });

        setResultList(searchResults);
        setSlice(searchResults);
    } else {
        setCurrentPage(1);
        setResultList(originalListTable);
    }
};


  const handleShowByPage = (event) => {
    setItemsByPage(event);
  };

  return (
    <div className={styles.tableControlContainer}>
      <div className={styles.showByPageContainer}>
          <label>Exibir: </label>
          <Select
            name="showByPage"
            id="showByPage"
            placeholder="Selecione"
            options={showByPageList}
            onChange={handleShowByPage}
            className={styles.showByPage}
            defaultValue={showByPageDefault}
          />
        </div>
        <div className={styles.selectsContainer}>
        {hideBulkActions ? null : (
          <div>
            <Select
              name="bulkActions"
              id="bulkActions"
              placeholder="Ações em massa"
              options={actionsList}
              className={styles.bulkActions}
              onChange={(event) => {
                setSelectedBulkAction(event);
              }}
            />
            <ConfirmationModal
              onClick={() =>
                setClosingConfirmationModal(true)
              }
              title={
                props.title
              }
              onOk={() => {
                performBulkAction()
                setClosingConfirmationModal(false)
              }}
              open={closingConfirmationModal}
              onCancel={() => setClosingConfirmationModal(false)}
              iconComponent={
                <Button className={styles.buttonGo} >
                  Ir
                </Button>
              }
            />

          </div>
        )}        
      </div>
      <div className={styles.search}>
        {
          url.includes("oldReports") ? <Input
          prefix={<SearchOutlined />}
          placeholder={searchPlaceholder}
          onChange={searchOldItem}
          className={styles.searchInput}
        ></Input> : <Input
            prefix={<SearchOutlined />}
            placeholder={searchPlaceholder}
            onChange={searchItem}
            className={styles.searchInput}
          ></Input>
        }
        {hideFilter ? null : (
          url.includes("reports") ? 
          <ReportsFilter 
          className={styles.filter}
          setResultList={setResultList} 
          list={resultList}/> :
          <TableFilter
            className={styles.filter}
            setList={setResultList}
            list={resultList}
            filterableParameters={filterableParameters}
          />
        )}
      </div>
    </div>
  );
};

export default TableControl;

// dict was created to language ptBR, because Amplify doesn't have this package language native

const dict = {
  "pt-br": {
    "Sign In": "Entrar",
    "Sign in": "Entrar",
    "Sign out": "Sair",
    "Sign Out": "Sair",
    Username: "Nome de usuário",
    Password: "Senha",
    "Forgot your password?": "Esqueceu a senha?",
    "Change Password": "Alterar senha",
    "New Password": "Nova senha",
    Email: "E-mail",
    "Confirm a Code": "Confirmar código",
    "Confirm Sign Up": "Confirmar registro",
    "Create Account": " ",
    "Have an account?": "Já tem uma conta?",
    "Reset password": "Redefinir senha",
    "Send code": "Enviar código",
    "Back to Sign In": "Voltar para entrar",
    Confirm: "Confirmar",
    "Resend Code": "Reenviar código",
    "Code *": "Código",
    Code: "Código",
    "Confirm Password": "Confirmar senha",
    "No account?": " ",
    "Resend a Code": "Reenviar um código",
    Submit: "Enviar",
    Verify: "Verificar",
    "Enter your Email": "Digite seu e-mail",
    "Enter your email": "Digite seu e-mail",
    "Enter your Password": "Digite sua senha",
    "Please confirm your Password": "Confirme sua senha",
    "Reset Password": "Redefinir Senha",
    "Sending...": "Enviando...",
    Sending: "Enviando...",
    "Submitting...": "Enviando...",
    Submitting: "Enviando...",
    "Attempt limit exceeded, please try after some time.":
      "Limite de solicitações excedido, por favor tente novamente mais tarde",
    "Creating account": "Criando conta",
    "We Emailed You": "Enviamos um e-mail para você",
    "Your code is on the way. To log in, enter the code we emailed to":
      "Seu código está a caminho. Para entrar, digite o código enviado para o e-mail",
    "It may take a minute to arrive": "Ele pode levar um tempo para chegar",
    "Confirmation Code": "Código de confirmação",
    "Confirmation code": "Código de confirmação",
    "Enter your code": "Digite seu código",
    Confirming: "Confirmando",
    "Invalid verification code provided, please try again.":
      "Código de verificação inválido, tente novamente",
    "An account with the given email already exists.":
      "Já existe uma conta cadastrada com o e-mail informado.",
    "We Texted You": "Enviamos um e-mail para você",
    "Your code is on the way. To log in, enter the code we sent you.":
      "Seu código está a caminho. Para entrar, digite o código que enviamos para você.",
    "Signing in": "Entrando",
  },
};

export default dict;

import React from 'react';
import { Rate } from 'antd';
import "./Rating.css";

const Rating = (props) => {
    return (
        <Rate className={props.className} style={props.style} />
    )
}

export default Rating;
import { Select } from "antd";
import React from "react";

//documentation -> https://ant.design/components/select

//For both option the listOfItems should follow the following model
//[ {value: 'value1', label: 'labelForValue1'},
//  {value: 'value2', label: 'labelForValue2'},
//  {value: 'value3', label: 'labelForValue3'} ]

//Dropdown list with searching feature
//Allows users to type in order to filter the list items
export const SearchableDropdownList = ({
  className,
  style,
  onChange,
  listOfItems,
  placeholder,
  id,
  name,
  defaultValue,
  title,
}) => {
  return (
    <Select
      name={name}
      id={id}
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      filterOption={(input, option) =>
        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
      }
      options={listOfItems}
      className={className}
      style={style}
      onChange={onChange}
      defaultValue={defaultValue}
      title={title}
    />
  );
};

//Regular dropdown list
export const DropdownList = ({
  className,
  style,
  onChange,
  listOfItems,
  placeholder,
  id,
  name,
  defaultValue,
  value,
  disabled,
  title,
}) => {
  return (
    <Select
      name={name}
      id={id}
      placeholder={placeholder}
      style={style}
      options={listOfItems}
      className={className}
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      disabled={disabled}
      title={title}
    />
  );
};

const DropdownListField = ({
  className,
  style,
  onChange,
  listOfItems,
  placeholder,
  search,
  id,
  name,
  defaultValue,
  value,
  disabled,
  title,
}) => (
  <div>
    {
      //Check if the developer wants to use a dropdownlist with searching feature that allows users to type in order to filter the list
      search == true ? (
        <SearchableDropdownList
          defaultValue={defaultValue}
          className={className}
          name={name}
          id={id}
          style={style}
          onChange={onChange}
          listOfItems={listOfItems}
          placeholder={placeholder}
          title={title}
        />
      ) : (
        <DropdownList
          defaultValue={defaultValue}
          className={className}
          name={name}
          id={id}
          style={style}
          onChange={onChange}
          listOfItems={listOfItems}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          title={title}
        />
      )
    }
  </div>
);

export default DropdownListField;

import { SkipBack, SkipForward } from "phosphor-react";
import React, { useEffect } from "react";

import styles from "./pagination.module.scss";

// Code Documentation:

// - tablePagination: (useState criado no pai e passado para o componente tableControl) array contendo os números de páginas. ex: [1,2,3,4]

// - slice: (useState criado no pai e passado para o componente tableControl) array contendo os itens da página atual.

// - currentPage: (useState criado no pai, com valor inicial 1, e passado para o componente tableControl) responsável por atualizar o número da página escolhida/atual.

const Pagination = ({
  tablePagination,
  currentPage,
  setCurrentPage,
  slice,
}) => {
  useEffect(() => {
    if (slice.length < 1 && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }, []);

  const backPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const previousPage = () => {
    setCurrentPage(currentPage + 1);
  };
  return (
    <>
      {tablePagination == 0 ? null : (
        <div className={styles.paginationContainer}>
          {currentPage > 1 ? (
            <div className={styles.backPage}>
              <SkipBack size={16} onClick={backPage} />{" "}
            </div>
          ) : null}

          <span>
            {currentPage} de {tablePagination.length}
          </span>

          {currentPage < tablePagination.length ? (
            <div className={styles.previousPage}>
              <SkipForward size={16} onClick={previousPage} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default Pagination;

import avatar from "../../assets/avatar.jpg";
import styles from "./avatar.module.scss";

const Avatar = ({ src, width }) => {

  const validateURL = (url) => {
    return !!url ? url : avatar;
  };
  return (
    <img
      src={validateURL(src)}
      alt="Perfil"
      style={{ width: width }}
      className={styles.avatarImg}
    ></img>
  );
};

export default Avatar;

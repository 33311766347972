import ExpandedMenu from "components/ExpandedMenu/expandedMenu";
import SmallMenu from "components/SmallMenu/smallMenu";
import { useState } from "react";

const Menu = (menuType) => {

  const menuStatus = localStorage.getItem("Menu#", menuType);
  const [menu, setMenu] = useState(menuStatus);

  return (
    <>
      {menu === "SmallMenu" ? (
        <SmallMenu setMenu={setMenu}>
        </SmallMenu>

      ) : (
        <ExpandedMenu setMenu={setMenu}>
        </ExpandedMenu>
      )}
    </>
  );
};

export default Menu;

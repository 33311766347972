import React from "react";
import styles from "./question.module.scss";
import Checkbox from "components/Checkbox/checkbox";
import Radio from "components/Radio/radio";
import TextArea from "antd/es/input/TextArea";
import { Rate } from "antd";

const QuestionPreview = ({ questionData, questionType, header }) => {


    function npsText(header) {
        console.log(header)
        if (header.pt && header.pt !== null) {
            return (<span className={styles.npsHeader} style={{ color: '#0064a2' }}>- Em uma escala de 0 a 10, o quanto você indicaria {questionData.subject.pt}{" "}
                para um amigo ou colega?</span>)
        } if (header.en && header.en !== null) {
            return (
                <span className={styles.npsHeader} style={{ color: '#0064a2' }}>- How likely is it that you would recommend {questionData.subject.en}{" "} to a friend or colleague?</span>
            )
        } if (header.es && header.es !== null) {
            return (
                <span className={styles.npsHeader} style={{ color: '#0064a2' }}>- ¿Qué tan probable es que recomiendes {questionData.subject.es}{" "} a colegas y amigos?</span>
            )
        } else {
            return <></>
        }
    }

    function headerText(header) {
        if (header.pt && header.pt !== null) {
            return header.pt
        } if (header.en && header.en !== null) {
            return header.en
        } if (header.es && header.es !== null) {
            return header.es
        } else {
            return <></>
        }
    }

    switch (questionType) {
        case "multiple-choice":
            return (
                <div className={styles.questionContainer}>
                    <span>
                        <div className={styles.questTitle} style={{ color: '#0064a2' }}>
                            <span className={styles.prefix} style={{ color: '#0064a2' }}>-</span>
                            {headerText(header)}
                            {
                                questionData.required == true &&
                                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
                            }
                        </div>
                    </span>
                    {questionData.alternatives.map((header) => (
                        <div className={styles.questionText}>
                            <Checkbox />
                            {headerText(header)}
                            <br />
                        </div>
                    ))}
                </div>
            );
        case "single-choice":
            return (
                <div className={styles.questionContainer}>
                    <span>
                        <div className={styles.questTitle} style={{ color: '#0064a2' }}>
                            <span className={styles.prefix} style={{ color: '#0064a2' }}>-</span>
                            {headerText(header)}
                            {
                                questionData.required == true &&
                                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
                            }
                        </div>
                    </span>
                    <div className={styles.questionText}>
                        {questionData.alternatives.map((header) => (
                            <>
                                <div className={styles.radioText} >
                                    <Radio />
                                    {headerText(header)}
                                </div>
                                <br />
                            </>
                        ))}
                    </div>
                </div>
            );
        case "rating-scale":
            return (
                <div className={styles.questionContainer}>
                    <div className={styles.questTitle} style={{ color: '#0064a2' }}>
                        <span className={styles.prefix} style={{ color: '#0064a2' }}>-</span>
                        <span>{headerText(header)}
                            {
                                questionData.required == true &&
                                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
                            }
                        </span>
                    </div>
                    <div className={styles.questionText}>
                        <Rate />
                    </div>
                </div>
            );
        case "nps":
            return (
                <div className={styles.questionContainer}>
                    <p style={{ color: '#0064a2' }}>
                        {
                            questionData.required == true &&
                            <span style={{ color: 'red', fontWeight: 700 }}>*</span>
                        }
                    </p>
                    <p className={styles.npsText}>
                        {npsText(header)}
                    </p>
                    <div className={styles.npsForm}>
                        <label for="1" className={styles.npsSquare}>
                            <span>1</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="1"
                                value="1">
                            </input>
                        </label>
                        <label for="2" className={styles.npsSquare}>
                            <span>2</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="2"
                                value="2"></input>
                        </label>
                        <label for="3" className={styles.npsSquare}>
                            <span>3</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="3"
                                value="3"></input>
                        </label>
                        <label for="4" className={styles.npsSquare}>
                            <span>4</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="4"
                                value="4"></input>
                        </label>
                        <label for="5" className={styles.npsSquare}>
                            <span>5</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="5"
                                value="5"></input>
                        </label>
                        <label for="6" className={styles.npsSquare}>
                            <span>6</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="6"
                                value="6"></input>
                        </label>
                        <label for="7" className={styles.npsSquare}>
                            <span>7</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="7"
                                value="7"></input>
                        </label>
                        <label for="8" className={styles.npsSquare}>
                            <span>8</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="8"
                                value="8"></input>
                        </label>
                        <label for="9" className={styles.npsSquare}>
                            <span>9</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="9"
                                value="9"></input>
                        </label>
                        <label for="10" className={styles.npsSquare}>
                            <span>10</span>
                            <input
                                type="radio"
                                className={styles.npsRadio}
                                name="nps"
                                id="10"
                                value="10"
                            ></input>
                        </label>
                    </div>
                </div>
            );
        case "text-area":
            return (
                <div className={styles.questionContainer}>
                    <div className={styles.questTitle} style={{ color: '#0064a2' }}>
                        <span className={styles.prefix} style={{ color: '#0064a2' }}>-</span>
                        <span>{headerText(header)}
                            {
                                questionData.required == true &&
                                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
                            }
                        </span>
                    </div>
                    <div>
                        <TextArea />
                    </div>
                </div>
            );
        case "label":
            return (
                <div className={styles.questionContainer}>
                    <div className={styles.labelQuestion} style={{ color: '#0064a2', textTransform: 'capitalize' }}>
                        <span>{headerText(header)}
                            {
                                questionData.required == true &&
                                <span style={{ color: 'red', fontWeight: 700 }}>*</span>
                            }
                        </span>
                    </div>
                </div>
            );
        default:
            console.log("outro tipo");
    }
}

export default QuestionPreview
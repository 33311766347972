import { verifySession } from "Services/authenticationServices";

const stage = process.env.REACT_APP_STAGE;
const baseParameterURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/parameters`;

export async function getAllParameterItems(
  setList,
  setMemList,
  setLoading,
  parameter
) {
  const items = await getParameterItems(parameter);
  setList(items);
  setMemList(items);
  setLoading(false);
}

export async function getParameterItems(parameter) {
  let jwtToken = await verifySession();
  let parameterUrl = baseParameterURL + "/" + parameter;
  let parameterData;
  const parametersRequest = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(parameterUrl, parametersRequest)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      parameterData = data;
    });
  return parameterData;
}

export async function createParameterItem(itemBody, parameter) {
  let apiResponse;
  let jwtToken = await verifySession();
  let parameterUrl = baseParameterURL + "/" + parameter;
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(itemBody),
    };

    await fetch(parameterUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function grantAccessToUser(parameter, itemId, userId) {
  let apiResponse;
  let jwtToken = await verifySession();
  let parameterUrl = baseParameterURL + "/" + parameter + "/" + itemId + "/access";
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({"user":userId}),
    };

    await fetch(parameterUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data; 
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function removeAccessOfUser(parameter, itemId, userId) {
  let apiResponse;
  let jwtToken = await verifySession();
  let parameterUrl = baseParameterURL + "/" + parameter + "/" + itemId + "/access";
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({"user":userId}),
    };

    await fetch(parameterUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data; 
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function updateParameterItem(itemBody, parameter, id) {
  let apiResponse;
  let jwtToken = await verifySession();
  let parameterUrl = baseParameterURL + "/" + parameter + "/" + id;
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(itemBody),
    };

    await fetch(parameterUrl, parameters)
      .then((response) => {
        apiResponse = response.json()
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

import "./userName.scss";
import editSVG from "../../assets/icons/pencil.svg";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate } from "react-router-dom";

const UserName = ({ className, isEditable = false }) => {
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);

  const id = user.username;

  const userName = localStorage.getItem("firstName#" + id);
  return (
    <>
      <span className={className}>{userName} </span>
      {isEditable ? (
        <img
          className="userNameEdit"
          src={editSVG}
          alt={"editar"}
          onClick={() => {
            navigate("/user/" + id);
          }}
        ></img>
      ) : (
        ""
      )}
    </>
  );
};

export default UserName;

import MenuItems from "components/MenuItems/menuItems";
import MenuSubCategory from "components/MenuSubCategory/menuSubCategory";
import { useState } from "react";
import { useNavigate } from "react-router";

import styles from "./gridMenu.module.scss";

const GridMenu = ({ menuItemsProps, colorLine, title, iconUrl, imgColor }) => {
  const [isShowMenuItems, setIsShownMenuItems] = useState(false);

  const navigate = useNavigate();

  const handleMouseOver = () => {
    setIsShownMenuItems(true);
  };

  const handleMouseOut = () => {
    setIsShownMenuItems(false);
  };

  return (
    <div
      style={{ borderTop: `6px ${colorLine} solid` }}
      className={styles.gridMenu}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {isShowMenuItems ? (
        menuItemsProps.map((menuItem) =>
          menuItem.type === "menuSubCategory" ? (
            <MenuSubCategory
              key={menuItem.children}
              iconUrl={menuItem.iconUrl}
              iconClass={menuItem.iconClass}
              labelClass={menuItem.labelClass}
              menuItemClass={menuItem.menuItemClass}
              menuSubItemsProps={menuItem.menuSubItemsProps}
            >
              {menuItem.children}
            </MenuSubCategory>
          ) : (
            <MenuItems
              key={menuItem.children}
              iconUrl={menuItem.iconUrl}
              iconClass={menuItem.iconClass}
              labelClass={menuItem.labelClass}
              menuItemClass={menuItem.menuItemClass}
              onClick={() => {
                if ("url" in menuItem) {
                  navigate(menuItem.url);
                }
              }}
            >
              {menuItem.children}
            </MenuItems>
          )
        )
      ) : (
        <div className={styles.gridMenuTitle}>
          <h4>{title}</h4>
          <img src={iconUrl} alt={title} style={{ filter: imgColor }}></img>
        </div>
      )}
    </div>
  );
};

export default GridMenu;

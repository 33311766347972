import SurveyStatusItem from "components/SurveyStatusItem/surveyStatusItem";

import activeSVG from "../../assets/icons/check-square.svg";
import closeSVG from "../../assets/icons/lock.svg";
import editSVG from "../../assets/icons/pencil.svg";
import styles from "./surveyStatus.module.scss";

const SurveyStatus = () => {
  return (
    <div className={styles.surveyStatus}>
      {/* após ter a requisição de BE para dados do usuário (autenticação), fazer a lógica para pegar a quantidade dentro do componente SurveyStatus e substituir os dados mockados*/}
      <SurveyStatusItem
        iconUrl={editSVG}
        number="15"
        label="Avaliações em preparação"
      ></SurveyStatusItem>
      <SurveyStatusItem
        iconUrl={activeSVG}
        number="33"
        label="Avaliações ativas"
      ></SurveyStatusItem>
      <SurveyStatusItem
        iconUrl={closeSVG}
        number="832"
        label="Avaliações encerradas"
      ></SurveyStatusItem>
    </div>
  );
};

export default SurveyStatus;

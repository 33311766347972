import reportsSVG from "../assets/icons/activity.svg";
import patternSVG from "../assets/icons/bookmark-simple.svg";
import alternativeSVG from "../assets/icons/bookmarks.svg";
import modelsSVG from "../assets/icons/books.svg";
import recordSVG from "../assets/icons/cardholder.svg";
import powerBISVG from "../assets/icons/cell-signal-full.svg";
import doneSVG from "../assets/icons/check-square.svg";
import othersSVG from "../assets/icons/cube.svg";
import adminSVG from "../assets/icons/gear.svg";
import designationsSVG from "../assets/icons/identification-badge.svg";
import resultsSVG from "../assets/icons/lightbulb.svg";
import generalSVG from "../assets/icons/list-bullets.svg";
import closeSVG from "../assets/icons/lock.svg";
import editSVG from "../assets/icons/pencil.svg";
import surveySVG from "../assets/icons/qr-code.svg";
import questionsSVG from "../assets/icons/question.svg";
import listsSVG from "../assets/icons/tag.svg";
import userSVG from "../assets/icons/user.svg";
import presentationSVG from "../assets/icons/presentation.svg";
import variablesStyles from "../styles/_variables.scss";

const menuItemsSurvey = [
  {
    iconUrl: editSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Elaboração",
    menuItemClass: "menuItemsCyan",
    url: "/preparationOrders",
  },
  {
    iconUrl: doneSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Prontas",
    menuItemClass: "menuItemsCyan",
    url: "/readyOrders",
  },
  {
    iconUrl: closeSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Encerradas",
    menuItemClass: "menuItemsCyan",
    url: "/closedOrders",
  },
];

const menuItemsResult = [
  {
    iconUrl: reportsSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Relatórios",
    menuItemClass: "menuItemsCyan",
    url: "/reports",
  },
  {
    iconUrl: presentationSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Relatórios Antigos",
    menuItemClass: "menuItemsCyan",
    url: "/oldReports",
  }
  // {
  //   iconUrl: powerBISVG,
  //   iconClass: "iconWhite",
  //   labelClass: "labelWhite",
  //   children: "Power BI",
  //   menuItemClass: "menuItemsCyan",
  // },
];

const menuItemsRecords = [
  {
    iconUrl: userSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Usuários",
    menuItemClass: "menuItemsCyan",
    url: "/users",
  },
  {
    iconUrl: modelsSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Modelos",
    type: "menuSubCategory",
    menuItemClass: "menuItemsCyan",
    menuSubItemsProps: [
      {
        iconUrl: patternSVG,
        iconClass: "iconOrange",
        labelClass: "labelOrange",
        children: "Padrão",
        menuItemClass: "menuItemsOrange",
        url: "/collections",
      },
      // {
      //   iconUrl: alternativeSVG,
      //   iconClass: "iconOrange",
      //   labelClass: "labelOrange",
      //   children: "Alternativo",
      //   menuItemClass: "menuItemsOrange",
      // },
      {
        iconUrl: questionsSVG,
        iconClass: "iconOrange",
        labelClass: "labelOrange",
        children: "Questões",
        menuItemClass: "menuItemsOrange",
        url: "/questions",
      },
    ],
  },
  {
    iconUrl: listsSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Listas",
    type: "menuSubCategory",
    menuItemClass: "menuItemsCyan",
    menuSubItemsProps: [
      {
        iconUrl: generalSVG,
        iconClass: "iconOrange",
        labelClass: "labelOrange",
        children: "Geral",
        menuItemClass: "menuItemsOrange",
        url: "/generalLists",
      },
      {
        iconUrl: othersSVG,
        iconClass: "iconOrange",
        labelClass: "labelOrange",
        children: "Outros",
        menuItemClass: "menuItemsOrange",
        url: "/assetsLists",
      },
    ],
  },
];

const menuItemsAdmin = [
  {
    iconUrl: userSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Usuários",
    menuItemClass: "menuItemsCyan",
    url: "/users",
  },
  {
    iconUrl: modelsSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Modelos",
    menuItemClass: "menuItemsCyan",
  },
  {
    iconUrl: listsSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    children: "Listas",
    menuItemClass: "menuItemsCyan",
  },
];

export const gridsMenu = [
  {
    title: "avaliações",
    menuItemsProps: menuItemsSurvey,
    colorLine: variablesStyles.cyan500,
    iconUrl: surveySVG,
    imgColor: variablesStyles.cyan500SVG,
  },
  {
    title: "resultados",
    menuItemsProps: menuItemsResult,
    colorLine: variablesStyles.red700,
    iconUrl: resultsSVG,
    imgColor: variablesStyles.red700SVG,
  },
  {
    title: "cadastros",
    menuItemsProps: menuItemsRecords,
    colorLine: variablesStyles.green700,
    iconUrl: recordSVG,
    imgColor: variablesStyles.green700SVG,
  },
  {
    title: "Admin",
    menuItemsProps: menuItemsAdmin,
    colorLine: variablesStyles.orange100,
    iconUrl: adminSVG,
    imgColor: variablesStyles.orange100SVG,
  },
];

export const categoryMenu = [
  {
    title: "avaliações",
    menuItemsProps: menuItemsSurvey,
    iconUrl: surveySVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    menuItemClass: "menuItemsCyan900",
  },
  {
    title: "resultados",
    menuItemsProps: menuItemsResult,
    iconUrl: resultsSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    menuItemClass: "menuItemsCyan900",
  },
  {
    title: "cadastros",
    menuItemsProps: menuItemsRecords,
    iconUrl: recordSVG,
    iconClass: "iconWhite",
    labelClass: "labelWhite",
    menuItemClass: "menuItemsCyan900",
  },
];

import MenuItems from "components/MenuItems/menuItems";
import { useState } from "react";
import { useNavigate } from "react-router";

import caretDownSVG from "../../assets/icons/caret-down.svg";
import caretUpSVG from "../../assets/icons/caret-up.svg";
import styles from "./menuSubCategory.module.scss";

import ConfirmationModal from "components/confirmationModal/confirmationModal";

const MenuSubCategory = ({
  iconUrl,
  iconClass,
  labelClass,
  children,
  menuItemClass,
  menuSubItemsProps,
  smallMenu,
}) => {
  const [isShowMenuItems, setIsShownMenuItems] = useState(false);
  const [subCategoryIconClass, setSubCategoryIconClass] = useState(iconClass);
  const [subCategoryLabelClass, setsubCategoryLabelClass] =
    useState(labelClass);
  const [subCategoryMenuItemClass, setSubCategoryMenuItemClass] =
    useState(menuItemClass);
  const [subCategoryCaretIconClass, setSubCategoryCaretIconClass] =
    useState(iconClass);
  const [subCategoryCaretIconUrl, setSubCategoryCaretIconUrl] =
    useState(caretDownSVG);

  const [closingConfirmationModal, setClosingConfirmationModal] =
    useState(false);
  const currentUrl = window.location.href;
  const [goTo, setGoTo] = useState("");

  const navigate = useNavigate();

  const setClassesBackgroundCyan = () => {
    setSubCategoryIconClass("iconWhite");
    setsubCategoryLabelClass("labelWhite");
    setSubCategoryCaretIconClass("iconWhite");
    setSubCategoryMenuItemClass("menuItemsCyan");
    setSubCategoryCaretIconUrl(caretDownSVG);
  };

  const setClassesBackgroundWhite = () => {
    setSubCategoryIconClass("iconWhite");
    setsubCategoryLabelClass("labelWhite");
    setSubCategoryCaretIconClass("iconWhite");
    setSubCategoryMenuItemClass("menuItemsCyanCategory");
    setSubCategoryCaretIconUrl(caretUpSVG);
  };

  const setClasses = () => {
    isShowMenuItems ? setClassesBackgroundCyan() : setClassesBackgroundWhite();
  };

  const handleClick = () => {
    setIsShownMenuItems(!isShowMenuItems);
    setClasses();
  };

  return (
    <div>
      <ConfirmationModal
        onClick={() => setClosingConfirmationModal(true)}
        title={"Deseja sair sem alterar os dados?"}
        open={closingConfirmationModal}
        onOk={() => {
          navigate(goTo);
        }}
        onCancel={() => setClosingConfirmationModal(false)}
        iconComponent={null}
      />
      <div onClick={handleClick}>
        <MenuItems
          key={children}
          iconUrl={iconUrl}
          iconClass={subCategoryIconClass}
          labelClass={subCategoryLabelClass}
          menuItemClass={subCategoryMenuItemClass}
          caretClasses={subCategoryCaretIconClass}
          caretIconUrl={subCategoryCaretIconUrl}
          smallMenu={smallMenu}
        >
          {children}
        </MenuItems>
      </div>
      {isShowMenuItems
        ? menuSubItemsProps.map((subItem) => (
            <MenuItems
              key={subItem.children}
              iconUrl={subItem.iconUrl}
              iconClass={
                currentUrl.includes(subItem.url)
                  ? styles.iconOrange
                  : subItem.iconClass
              }
              labelClass={
                currentUrl.includes(subItem.url)
                  ? styles.menuItemsOrangeCurrentPage
                  : subItem.labelClass
              }
              menuItemClass={
                currentUrl.includes(subItem.url)
                  ? styles.menuItemsOrangeCurrentPage
                  : subItem.menuItemClass
              }
              smallMenu={smallMenu}
              onClick={() => {
                if (
                  currentUrl.includes("/order/") ||
                  currentUrl.includes("/collection/") ||
                  currentUrl.includes("/question/") ||
                  currentUrl.includes("/user/")
                ) {
                  setGoTo(subItem.url);
                  setClosingConfirmationModal(true);
                }
                if (
                  "url" in subItem &&
                  !currentUrl.includes("/order") &&
                  !currentUrl.includes("/collection/") &&
                  !currentUrl.includes("/question/") &&
                  !currentUrl.includes("/user/")
                ) {
                  navigate(subItem.url);
                }
              }}
            >
              {subItem.children}
            </MenuItems>
          ))
        : ""}
    </div>
  );
};

export default MenuSubCategory;

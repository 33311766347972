import "../CloseButton/closeButton.module.scss";
import "./confirmationModal.scss";
import styles from "./confirmationModal.scss";

import { Button, Modal } from "antd";

// import { useState } from "react";
// import { useNavigate } from "react-router-dom";
const ConfirmationModal = ({
  onClick,
  open,
  onCancel,
  key,
  id,
  onOk,
  title,
  iconComponent,
  maskStyle,
}) => {
  // useState and useNavigate will be imported on the page where the component is used
  // const navigate = useNavigate()
  // const [modal2Open, setModal2Open] = useState(false);

  return (
    <>
      {/* set the state from useState(true) below */}
      <Button type="text" onClick={onClick} id={id}>
        {iconComponent}
      </Button>

      <Modal
        title={title}
        centered
        // set the initial state from useState below
        open={open}
        // set navigate(-1) back to previous page
        onOk={onOk}
        // set the state from useState(false) below
        onCancel={onCancel}
        cancelText="Não"
        cancelButtonProps="danger"
        maskStyle={maskStyle}
        okText="Sim"
        width={"25rem"}
        closable={false}
        key={key}
        id={id}
      >
        <br />
      </Modal>
    </>
  );
};
export default ConfirmationModal;

import { useAuthenticator } from "@aws-amplify/ui-react";
import MenuCategory from "components/MenuCategory/menuCategory";
import MenuItems from "components/MenuItems/menuItems";
import User from "components/User/user";
import { useNavigate } from "react-router";

import homeSVG from "../../assets/icons/house.svg";
import burguerSVG from "../../assets/icons/list.svg";
import logoutSVG from "../../assets/icons/power.svg";
import * as constants from "../../constants/menuItemsContants";
import styles from "./expandedMenu.module.scss"; import { useState } from "react";
import ConfirmationModal from "components/confirmationModal/confirmationModal";

const ExpandedMenu = ({ setMenu }) => {
  const handleClick = () => {
    const menuType = "SmallMenu"
    setMenu(menuType);
    localStorage.setItem("Menu#", menuType);
  };

  const navigate = useNavigate();

  const { signOut } = useAuthenticator((context) => [context.signOut]);

  const { user } = useAuthenticator((context) => [context.user]);

  const userRole = localStorage.getItem("role#" + user.username);

  const capabilities = localStorage.getItem("capabilities#" + user.username);
 

  function logOut() {
    signOut();
    navigate("/login");
  }

  const [closingConfirmationModal, setClosingConfirmationModal] = useState(false);
  const currentUrl = window.location.href
  const [goTo, setGoTo] = useState("");

  return (
    <div className={styles.expandedMenu}>
      <ConfirmationModal
        onClick={() => setClosingConfirmationModal(true)}
        title={"Deseja sair sem alterar os dados?"}
        open={closingConfirmationModal}
        onOk={() => {
          if (goTo === "Home") {
            navigate("/dashboard")
          } if (goTo === "Logout") {
            logOut()
          }
        }}
        onCancel={() => setClosingConfirmationModal(false)}
        iconComponent={null}
      />
      <div className={styles.expandedMenuHeader}>
        <div onClick={handleClick} className={styles.expandedMenuImg}>
          <img src={burguerSVG} alt="Menu"></img>
        </div>
        <div className={styles.expandedMenuUser}>
          <User></User> 
        </div>
      </div>
      <div className={styles.expandedMenuItems}>
        <MenuItems
          iconUrl={homeSVG}
          iconClass="iconWhite"
          labelClass="labelWhite"
          menuItemClass="menuItemsCyan"
          onClick={() => {
            if (currentUrl.includes('/order/') || currentUrl.includes('/collection/') || currentUrl.includes('/question/') || currentUrl.includes('/user/')) {
              setClosingConfirmationModal(true)
              setGoTo("Home")
              console.log(goTo)
            } else { navigate("/dashboard") }

          }}
        >
          Home
        </MenuItems>
        {constants.categoryMenu.map((category) => {
          if(capabilities){
            // console.log(capabilities)
            if (capabilities.includes(category.title)) {
              return (
                <MenuCategory
                  key={category.title}
                  menuItemsProps={category.menuItemsProps}
                  title={category.title}
                  iconUrl={category.iconUrl}
                  iconClass={category.iconClass}
                  labelClass={category.labelClass}
                  menuItemClass={category.menuItemClass}
                />
              )
            }
        }
        })}
        <div className={styles.logout}>
          <MenuItems
            iconUrl={logoutSVG}
            iconClass="iconWhite"
            labelClass="labelWhite"
            menuItemClass="menuItemsCyan900Clickable"
            onClick={() => {
              if (currentUrl.includes('/order/') || currentUrl.includes('/collection/') || currentUrl.includes('/question/') || currentUrl.includes('/user/')) {
                setClosingConfirmationModal(true)
                setGoTo("Logout")
                console.log(goTo)
              } else { logOut() }

            }}
          >
            Sair
          </MenuItems>
        </div>
      </div>
    </div>
  );
};

export default ExpandedMenu;

import Checkbox from "components/Checkbox/checkbox";
import Field from "components/Field/field";
import ListControl from "components/ListControl/ListControl";
import { ListSelectColor } from "components/SelectColor/selectColor";
import { DotsSixVertical, MagnifyingGlass } from "phosphor-react";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import styles from "./manageCollection.module.scss";
import { getColors } from "Services/globalServices";

const ManageCollection = ({ items, setItems, questions }) => { 

  //save reference for dragItem and dragOverItem
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
 

  //Updates the "items" list  after dragging 'n dropping an element to another index
  const handleSort = () => {
    //duplicates items list to deal with the changes
    let _items = [...items];

    //remove and save the dragged item content
    const draggedItemContent = _items.splice(dragItem.current, 1)[0];

    //switch the position
    _items.splice(dragOverItem.current, 0, draggedItemContent);

    //reset the variable
    dragItem.current = null;
    dragOverItem.current = null;

    //set the actual array
    setItems(_items);
  };

  //defaultItem to be added as an element to "item" list after clicking on "+" button
  let newDefaultItem = {
    question: null,
    highlight: false,
    required: false, 
    prefix: null,
  };

  //Adds a new item to te "items" list
  const addNewItem = (defaultItem, indexOfItemToInteractWith) => {
    //Get array of items
    let _items = [...items];

    //Add new item to the array 
    _items.splice(indexOfItemToInteractWith, 0, defaultItem);

    //set modified array
    setItems(_items);
  };

  //removeOption will remove the desired index from the list
  function removeOption(index) {
    //Gets list
    let _items = [...items];

    //Avoids user removing all items (the list should have at least one item)
    if (_items.length > 1) {
      _items.splice(index, 1);
      setItems(_items);
    }
  }

  //This function verifies if there's a match between the string the user typed in the 'question' field and the available 'questions' in the 'questions' list
  function verifyElementIsInListOfObjects(listToVeirfy, itemToFind) {
    let itemIsPresent = false;

    listToVeirfy.map((i) => {
      if (i.value == itemToFind) {
        itemIsPresent = true;
        // console.log(i.value + " = " + itemToFind);
      }
    });
    return itemIsPresent;
  }

  return (
    <div style={{ width: "100%" }}>
      <div className={styles.listContainer + " " + styles.externalLabels}>
        <div className={styles.row}>
          <span className={styles.internalLabels}>Questão</span>
          <div style={{ width: "77%", display: "block", position: "relative" }}>
            Adicionar questão<span style={{ color: "red" }}>*</span>
          </div>
          <div style={{ width: "10%", display: "block", position: "relative" }}>
            <center>Obrigatória</center>
          </div>
          <div
            style={{
              width: "18%",
              display: "block",
              position: "relative",
              paddingLeft: "18%",
            }}
          >
            Negrito
          </div> 
          <div
            style={{
              width: "35%",
              left: "7%",
              display: "block",
              position: "relative",
              textAlign: "center",
            }}
          >
            Prefixo
          </div>
        </div>
      </div>
      <div className={styles.listContainer}>
        {items.map((item, index) => {
          let currentQuestionIndex = questions.findIndex(
            (question) => question.value == item.questionId
          );
          // console.log("INDEX " + currentQuestionIndex)
          // console.log(questions[currentQuestionIndex])
          // console.log(item)


          return (
            <div
              key={index}
              className={styles.listItem}
              draggable
              onDragStart={(event) => (dragItem.current = index)}
              onDragEnter={(event) => (dragOverItem.current = index)}
              onDragEnd={() => {
                handleSort();
              }}
              onDragOver={(event) => event.preventDefault}
            >
              {" "}
              <div className={styles.dragContainer}>
                <DotsSixVertical className={styles.dragIcon} size={32} />
              </div>
              <div className={styles.row}>
                <span className={styles.internalLabels}>Questão</span>
                <div
                  className={styles.firstRow}
                  style={{
                    width: "100%",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <MagnifyingGlass className={styles.searchIcon} size={22} />
                  <Field
                    value={items[index].questionLabel}
                    placeholder={"Digite a questão desejada..."}
                    name={"itemQuestion" + index + 1}
                    id={"itemQuestion" + index + 1}
                    list={"questions"}
                    className={
                      styles.searchField + " " + styles.fieldAddQuestion
                    }
                    onChange={(event) => {
                      console.log(event.target.value)
                      const questionIndex = questions.findIndex(
                        (question) => question.value == event.target.value
                      );
                      let questionLabel;
                      let _items = [...items];
                      if (questionIndex > -1) {
                        questionLabel = questions[questionIndex].label;
                        _items[index].questionLabel = questionLabel;
                        _items[index].questionId = event.target.value;
                        _items[index].questionType = questions[questionIndex].type;
                      } else {
                        _items[index].questionId = undefined;
                        _items[index].questionLabel = questionLabel;
                        //_items[index].questionType = questions[questionIndex].type;
                      }
                      setItems(_items);
                      //document.getElementById(event.target.id).style.border = '1px solid red'
                      let checkTypedString = verifyElementIsInListOfObjects(
                        questions,
                        event.target.value
                      );
                      if (checkTypedString == false) {
                        document.getElementById(event.target.id).style.border =
                          "1px solid red";
                      } else {
                        document.getElementById(event.target.id).style.border =
                          "1px solid #d9d9d9";
                      }
                    }}
                  />
                </div>
                {

                  currentQuestionIndex > -1 &&
                    questions[currentQuestionIndex].type !== 'label' ?
                    <div className={styles.requiredQuestion}>
                      <center>
                        <span className={styles.internalLabels}>Obrigatória</span>
                        <Checkbox
                          className={styles.checkboxField}
                          checked={item.required}
                          onClick={() => {
                            let _items = [...items];
                            if (
                              _items[index].required == null ||
                              _items[index].required == false
                            ) {
                              _items[index].required = true;
                            } else {
                              _items[index].required = false;
                            }
                            setItems(_items);
                          }}
                        />
                      </center>
                    </div>
                    :
                    <div className={styles.requiredQuestion}>
                      <center>
                        <span className={styles.internalLabels}>Obrigatória</span>

                        <Checkbox
                          className={styles.checkboxField}
                          checked={item.required}
                          disabled={true}
                        />
                      </center>
                    </div>
                }

                <div className={styles.highlightQuestion}>
                  <center>
                    <span className={styles.internalLabels}>Negrito</span>
                    <Checkbox
                      className={styles.checkboxField}
                      checked={item.highlight}
                      onClick={() => {
                        let _items = [...items];
                        if (
                          _items[index].highlight == null ||
                          _items[index].highlight == false
                        ) {
                          _items[index].highlight = true;
                        } else {
                          _items[index].highlight = false;
                        }
                        setItems(_items);
                      }}
                    />
                  </center>
                </div> 
                <div className={styles.smallField}>
                  <span className={styles.internalLabels}>
                    Prefixo<br></br>
                  </span>
                  <Field
                    value={items[index].prefix}
                    name={"itemColor" + index + 1}
                    id={"itemColor" + index + 1}
                    className={styles.fieldPrefix + " " + styles.smallField}
                    onChange={(event) => {
                      let _items = [...items];
                      _items[index].prefix = event.target.value;
                      setItems(_items);
                    }}
                  />
                </div>
                <div className={styles.listControlContainer}>
                  <ListControl
                    className={styles.listControl}
                    newItemDefaultContent={newDefaultItem}
                    addsNewItem={addNewItem}
                    indexOfItemToInteractWith={index}
                    removesAnItem={removeOption}
                  />
                </div>
              </div>
            </div>
          );
        })}
        <datalist id="questions">
          {questions.map((i, k) => {
            return (
              <option key={k} value={i.value} label={i.label}>
                {i.label}
              </option>
            );
          })}
        </datalist>
      </div>
    </div>
  );
};

export default ManageCollection;

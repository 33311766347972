import styles from "./pageTitle.module.scss";

//Children is the content that will be passed between the opening and closing tags
//The align prop will receive center={true} in case of a centralized title
const PageTitle = ({children, center,style}) => {

  let alignment;

  if (center == true){
    alignment = styles.center
  }else{
    alignment = styles.left
  } 

  const classes = styles.pageTitle + styles.pageTitleLeft
  //add class pageTitle and pageTitleLeft or pageTitleCentered to set the alignment of title
  return <span className={styles.pageTitle + ' ' + alignment} style={style}>{children}</span>;
};

export default PageTitle;

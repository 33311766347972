import Menu from "components/Menu/menu";

import styles from "./layout.module.scss";

const Layout = ({ children }) => {
  return (
    <>
      <Menu></Menu>
      <div className={styles.layoutContainer}>{children}</div>
    </>
  );
};

export default Layout;

export async function SubmitAnswer(orderId, answer) {
    const stage = process.env.REACT_APP_STAGE;
    const baseUrl = `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/${orderId}/submissions/answers`;
    
    try {
        const response = await fetch(baseUrl, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz",
            },
            body: JSON.stringify(answer),
        });

        if (!response.ok) {
             
            throw new Error(`Failed to submit answer: ${response.status} ${response.statusText}`);
        }
        console.log(response)
        return response; // Return the full response object
    } catch (error) {
        console.error("Error submitting answer:", error);
        // You can handle errors as needed, such as showing an error message to the user.
        return { success: false, message: "An error occurred while submitting your answer." };
    }
}

import { verifySession } from "./authenticationServices";

const stage = process.env.REACT_APP_STAGE;
const baseURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/`;

//to format date, you can pass the string options: "DD/MM/YYYY", "MM/DD/YYYY"
export const formatDate = (date, format) => {
  let objectDate = new Date(date);
  let day = String(objectDate.getDate()).padStart(2, "0");
  let month = String(objectDate.getMonth() + 1).padStart(2, "0");
  let year = objectDate.getFullYear();

  switch (format) {
    case "DD/MM/YYYY":
      return day + "/" + month + "/" + year;
    case "MM/DD/YYYY":
      return month + "/" + day + "/" + year;
    default:
      return "--/--/----";
  }
};

export function transformQuestionList(questionList) {
  const handleQuestionTypeLabel = (questionType) => {
    switch (questionType) {
      case "rating-scale":
        return "Classificação";
      case "text-area":
        return "Dissertação";
      case "single-choice":
        return "Alternativas";
      case "multiple-choice":
        return "Multipla escolha";
      case "nps":
        return "NPS";
      case "label":
        return "Cabeçalho"
      default:
        return null;
    }
  };

  const handleQuestionLanguage = (header) => {
    let languages = [];
    if (header.pt) {
      languages.push("Português");
    }
    if (header.en) {
      languages.push("Inglês");
    }
    if (header.es) {
      languages.push("Espanhol");
    }

    return languages;
  };

  questionList.map((question) => {
    question.date = formatDate(question.createdAt, "DD/MM/YYYY");
    question.questionTypeLabel = handleQuestionTypeLabel(question.questionType);
    question.languages = handleQuestionLanguage(question.header);
  });
  return questionList;
}

export const handleHeaderLabel = (header) => {
  if (header.pt) {
    return header.pt;
  } else if (header.en) {
    return header.en;
  } else if (header.es) {
    return header.es;
  } else {
    return "";
  }
};

export async function getColors() {
  let jwtToken = await verifySession();
  let colorsData;

  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  const getColorsURL = baseURL + "/assets/color";

  await fetch(getColorsURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      colorsData = data;
    });
  return colorsData;
}

export async function imageToBase64(image) {
  const base64 = await fetch(image)
    .then((response) => response.blob())
    .then((blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((res) => {
        reader.onloadend = () => {
          res(reader.result);
        };
      });
    });
  return base64;
}

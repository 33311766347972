import logo from "../../assets/logo-header.png";
import styles from "./header.module.scss";

const Header = () => {
  return (
    <div className={styles.header}>
      <img src={logo} alt="Logo ISE" className={styles.headerLogo}></img>
    </div>
  );
};

export default Header;

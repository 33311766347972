import "./checkbox.scss";

const Checkbox = ({ className, children, id, onClick, checked, disabled, style }) => {
  if (disabled == true) {
    return (
      // div to align items with display 'flex'
      <div className="alignCheckboxItems">
        <label className={className}>
          {/* the text of label */}
          {children}
          <input type="checkbox" id={id} style={style} onClick={onClick} defaultChecked={checked} disabled />
          {/* set the classes checkmark and chkGray, chkCyan or chkOrange for the color of checkbox */}
          <span className={className}></span>
        </label>
      </div>
    );
  } else {
    return (
      // div to align items with display 'flex'
      <div className="alignCheckboxItems">
        <label className={className}>
          {/* the text of label */}
          {children}
          <input type="checkbox" id={id} style={style} onClick={onClick} defaultChecked={checked} />
          {/* set the classes checkmark and chkGray, chkCyan or chkOrange for the color of checkbox */}
          <span className={className}></span>
        </label>
      </div>
    );
  }

};

export default Checkbox;

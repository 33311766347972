import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Field from "components/Field/field";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import UploadImageField from "components/UploadImageField/uploadImageField";
import {
  Archive,
  ArrowsDownUp,
  Image,
  Palette,
  Pencil,
  PlusCircle,
} from "phosphor-react";
import React, { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";
import { addNewAlert } from "Services/alertsManagement";
import { imageToBase64 } from "Services/globalServices";

import styles from "./assetsLists.module.scss";
import {
  archiveAssetById,
  createAssetItem,
  getAllAssetsItems,
  updateAssetById,
} from "./assetsListsServices";
import Loading from "components/Loading/loading";

const AssetsLists = () => {
  const [currentButton, setCurrentButton] = useState("image");
  const [loading, setLoading] = useState(true);
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPopupOpenned, setIsPopupOpenned] = useState(false);
  const [newTitle, setNewIitle] = useState();
  const [newImageItem, setNewImageItem] = useState();
  const [alertsPopup, setAlertsPopup] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [colorCod, setColorCod] = useState();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [isEditingItem, setIsEditingItem] = useState(false);
  const [itemIdToEdit, setItemIdToEdit] = useState("");
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  const navigate = useNavigate();

  const handleTabClass = (button) => {
    let tabsButtonClass =
      button == currentButton ? styles.tabsButtonSelected : "secondaryBlue";

    return styles.tabsButton + " " + tabsButtonClass;
  };

  function controlPopUp() {
    if (isPopupOpenned == true) {
      setAlertsPopup([]);
      setIsPopupOpenned(false);
      setNewIitle("");
      setColorCod("");
      setNewImageItem("");
      setDisplayColorPicker(false);
    } else {
      setIsPopupOpenned(true);
    }
  }

  const validationItem = () => {
    let isValid = true;

    if (newTitle == undefined || newTitle == "") {
      return (isValid = false);
    }

    if (currentButton == "image") {
      if (newImageItem == undefined || newImageItem == "") {
        return (isValid = false);
      }
    }

    if (currentButton == "color") {
      if (colorCod == undefined || colorCod == "") {
        return (isValid = false);
      }
    }
    return isValid;
  };

  const buildItemBody = async () => {
    let content;
    if (currentButton == "image") {
      let image = await imageToBase64(newImageItem);
      content = image;
    }

    if (currentButton == "color") {
      content = colorCod;
    }

    return {
      title: newTitle,
      content: content,
    };
  };

  const addNewItem = async () => {
    const isValid = await validationItem();
    let updateAlertList = "";
    setAlertsPopup([]);
    if (isValid == true) {
      let body = await buildItemBody();

      createAssetItem(body, currentButton).then((response) => {
        let messageResponse = response.message;

        if (messageResponse.includes("successfully stored")) {
          getAllAssetsItems(
            setResultList,
            setMemResultList,
            setLoading,
            currentButton
          );
          setIsPopupOpenned(false);

          let message = "Item criado com sucesso!";
          updateAlertList = addNewAlert([], { message, type: "success" });

          setAlerts(updateAlertList);
          setNewIitle("");
          setColorCod("");
          setNewImageItem("");
          setDisplayColorPicker(false);
        } else {
          let message = "Erro ao tentar criar item!";
          updateAlertList = addNewAlert([], { message, type: "danger" });
          setAlertsPopup(updateAlertList);
        }
      });
    } else {
      let message = "Preencha todos os campos!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlertsPopup(updateAlertList);
    }
  };

  const editItem = async () => {
    const isValid = await validationItem();
    let updateAlertList = "";
    setAlertsPopup([]);

    if (isValid == true) {
      let body = await buildItemBody();
      updateAssetById(body, currentButton, itemIdToEdit).then((response) => {
        let messageResponse = response.message;

        if (messageResponse.includes("successfully patched")) {
          getAllAssetsItems(
            setResultList,
            setMemResultList,
            setLoading,
            currentButton
          );
          setIsPopupOpenned(false);

          let message = "Item criado com sucesso!";
          updateAlertList = addNewAlert([], { message, type: "success" });

          setAlerts(updateAlertList);
          setNewIitle("");
          setColorCod("");
          setNewImageItem("");
          setDisplayColorPicker(false);
          setIsEditingItem(false);
          setItemIdToEdit("");
        } else {
          let message = "Erro ao tentar criar item!";
          updateAlertList = addNewAlert([], { message, type: "danger" });
          setAlertsPopup(updateAlertList);
        }
      });
    } else {
      let message = "Preencha todos os campos!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlertsPopup(updateAlertList);
    }
  };

  const triggerEditItem = async (id) => {
    setIsEditingItem(true);
    let data = await resultList.find((item) => {
      return item.id == id;
    });

    if (currentButton == "image") {
      setNewImageItem(data.content);
    }

    if (currentButton == "color") {
      setColorCod(data.content);
    }

    setItemIdToEdit(id);
    setNewIitle(data.title);
    setIsPopupOpenned(true);
  };

  const archiveItem = async () => {
    let updateAlertList = "";
    archiveAssetById(currentButton, itemIdToEdit).then((response) => {
      let messageResponse = response.message;

      if (messageResponse.includes("successfully archived")) {
        getAllAssetsItems(
          setResultList,
          setMemResultList,
          setLoading,
          currentButton
        );

        let message = "Item arquivado com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });

        setAlerts(updateAlertList);
        setArchiveConfirmationModal(false);
      } else {
        let message = "Erro ao tentar arquivar item!";
        updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
        setArchiveConfirmationModal(false);
      }
    });
  };

  //Sort list by specific key or restore original response
  function sortObjByKey(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  useEffect(() => {
    setLoading(true);
    getAllAssetsItems(
      setResultList,
      setMemResultList,
      setLoading,
      currentButton
    );
  }, [currentButton]);

  return (
    <Layout>
      <div>
        <div className={styles.pageHeader}>
          <PageTitle style={{marginTop: '-0.5rem'}} center={true}>Outros</PageTitle>
        </div>
        <div className={styles.tabsContainer}>
          <Button
            className={handleTabClass("image")}
            onClick={() => {
              setCurrentButton("image");
            }}
          >
            <Image size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Imagens</span>
          </Button>
          <Button
            className={handleTabClass("color")}
            onClick={() => {
              setCurrentButton("color");
            }}
          >
            <Palette size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Cores</span>
          </Button>
        </div>
        <div className={styles.tabsContainerSecoundRow}>
          <Button
            className={styles.tabsButtonNew + " " + "primaryOrange"}
            onClick={() => setIsPopupOpenned(true)}
          >
            <PlusCircle size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Novo Item</span>
          </Button>
          <Button
            title={"Itens Arquivados"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedAssets")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
        </div>
        {loading == true ? (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        ) : (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Item"}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  <th style={{ width: "40vw" }} className={styles.tableTitle}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  {currentButton == "image" && (
                    <th style={{ width: "25%" }}>Prévia</th>
                  )}
                  {currentButton == "color" && (
                    <th style={{ width: "25%" }}>Código da Cor</th>
                  )}
                  <th style={{ width: "30%" }}>
                    Data
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("created_At")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td className={styles.itemRow + " " + styles.itemTitle}>
                        {item.title}
                      </td>
                      {currentButton == "image" && (
                        <td
                          className={styles.itemRow + " " + styles.alignCenter}
                        >
                          <img
                            src={item.content}
                            alt={item.title}
                            className={styles.imagePreview}
                          ></img>
                        </td>
                      )}
                      {currentButton == "color" && (
                        <td
                          className={styles.itemRow + " " + styles.alignCenter}
                        >
                          <div>
                            {item.content}
                            <span
                              className={styles.colorDot}
                              style={{ backgroundColor: item.content }}
                            ></span>
                          </div>
                        </td>
                      )}
                      <td className={styles.itemRow + " " + styles.alignCenter}>
                        {item.date}
                      </td>
                      <td className={styles.actionsContainer}>
                        <Pencil
                          className={styles.editItemButton}
                          onClick={() => {
                            triggerEditItem(item.id);
                          }}
                          size={28}
                        />
                        <div className={styles.helpPopupEdit}>Editar Item</div>
                        <ConfirmationModal
                          id={"archiveItem" + item.id}
                          iconComponent={
                            <>
                              <Archive
                                className={styles.archiveItemButton}
                                onClick={() => { }}
                                size={28}
                              />
                              <div className={styles.helpPopupArchive}>
                                Arquivar Item
                              </div>
                            </>
                          }
                          title={"Deseja realmente arquivar este item?"}
                          maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.05)' }}
                          onClick={() => {
                            setItemIdToEdit(item.id);
                            setArchiveConfirmationModal(true);
                          }}
                          open={archiveConfirmationModal}
                          onOk={() => {
                            archiveItem();
                          }}
                          onCancel={() => setArchiveConfirmationModal(false)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          </div>
        )}
      </div>
      {isPopupOpenned == true ? (
        <>
          <div className={styles.newItemPopUp}>
            <div className={styles.header}>
              {isEditingItem ? "Editar Item" : "Novo Item"}
            </div>
            {currentButton == "image" && (
              <div className={styles.popupRow}>
                {alertsPopup.map((i, k) => {
                  return <Alert message={i.message} type={i.type} />;
                })}
                <span>
                  Nome da Imagem<span style={{ color: "red" }}>*</span>
                </span>
                <Field
                  key={"newImageTitle"}
                  name={"newImageTitle"}
                  id={"newImageTitle"}
                  value={newTitle}
                  onChange={(event) => {
                    setNewIitle(event.target.value);
                  }}
                ></Field>
                <div className={styles.imageContainer}>
                  <UploadImageField
                    id={"imageItem"}
                    image={newImageItem}
                    setImage={setNewImageItem}
                  ></UploadImageField>
                </div>
              </div>
            )}
            {currentButton == "color" && (
              <>
                <div className={styles.popupRow}>
                  {alertsPopup.map((i, k) => {
                    return <Alert message={i.message} type={i.type} />;
                  })}
                  <span>
                    Nome da Cor<span style={{ color: "red" }}>*</span>
                  </span>
                  <Field
                    key={"newColorTitle"}
                    name={"newColorTitle"}
                    id={"newColorTitle"}
                    value={newTitle}
                    onChange={(event) => {
                      setNewIitle(event.target.value);
                    }}
                  ></Field>
                </div>
                <div className={styles.popupRow + " " + styles.colorContainer}>
                  <div className={styles.colorPreviewContainer}>
                    <div
                      onClick={() => {
                        displayColorPicker
                          ? setDisplayColorPicker(false)
                          : setDisplayColorPicker(true);
                      }}
                      className={styles.colorPreview}
                      style={{ backgroundColor: colorCod }}
                    ></div>
                  </div>
                  {displayColorPicker ? (
                    <div style={{ position: "relative", zIndex: "2" }}>
                      <ChromePicker
                        width={130}
                        disableAlpha={true}
                        color={colorCod}
                        presetColors={[]}
                        onChange={(color) => {
                          setColorCod(color.hex);
                        }}
                      />
                    </div>
                  ) : null}
                  <div className={styles.colorCodeField}>
                    <Field
                      key={"colorCod"}
                      name={"colorCod"}
                      id={"colorCod"}
                      value={colorCod}
                      onChange={(event) => {
                        setColorCod(event.target.value);
                      }}
                    ></Field>
                  </div>
                </div>
                <div className={styles.popupRow}></div>
              </>
            )}
            <div className={styles.popupFooter}>
              <Button
                className={styles.tabsButtonFooter + " " + "primaryOrange"}
                onClick={() => (isEditingItem ? editItem() : addNewItem())}
              >
                {isEditingItem ? "Salvar" : "Adicionar"}
              </Button>
              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => {
                  setIsPopupOpenned(false);
                  setNewIitle("");
                  setNewImageItem("");
                  setNewIitle("");
                  setColorCod("");
                  setDisplayColorPicker(false);
                  setAlertsPopup([]);
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div className={styles.overlay} onClick={() => controlPopUp()}></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </Layout>
  );
};

export default AssetsLists;

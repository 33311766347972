import React from "react";
import styles from "./formContainer.module.scss";

const FormContainer = (props) => {
  return (
    <div
      className={
        props.className
          ? styles.FormContainer + " " + props.className
          : styles.FormContainer
      }
    >
      {props.children}
    </div>
  );
};

export default FormContainer;

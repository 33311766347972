import Button from "components/Button/button";
import React, { useState } from "react";
import styles from "./buttonDropdown.module.scss";
//For listOfItems should follow the following model
//[ {className: 'value1', icon: 'icon1', action: 'functionName1', label: 'label1'},
//  {className: 'value2', icon: 'icon2', action: 'functionName2', label: 'label2'},
//  {className: 'value3', icon: 'icon3', action: 'functionName3', label: 'label3'} ]

//For onClick, the father component should given a react state to manage de drop and down of the button
const ButtonDropdown = ({
  label,
  className,
  icon,
  listOfItems,
  blockIndex,
}) => {
  const [open, setOpen] = useState(false);
  const handleClass = (className) => {
    const buttonOpenned = open ? styles.buttonOpenned : null;
    className = className + " " + buttonOpenned;
    return className;
  };

  return (
    <div className={styles.buttonContainer}>
      <Button
        className={handleClass(className)}
        icon={icon}
        onClick={() => setOpen(!open)}
      >
        <span>{label}</span>
      </Button>
      {open &&
        listOfItems.map((item, index) => (
          <Button
            key={index}
            className={item.className}
            icon={item.icon}
            onClick={() => item.action(blockIndex)}
          >
            {item.label}
          </Button>
        ))}
    </div>
  );
};

export default ButtonDropdown;

import { verifySession } from "./authenticationServices";
const stage = process.env.REACT_APP_STAGE;
export async function getGroupedResults(orderId){
    let jwtToken = await verifySession();
    let results;
  
    const parameters = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };
  
    await fetch(
      `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/`+orderId+"/submissions/answers/grouped",
      parameters
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        results = data; 
      });
  
    return results;
  }
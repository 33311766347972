import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseCollectionsURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/collections/archive/`;

export async function getAllArchivedCollections(
  setList,
  setMemList,
  setLoading
) {
  const collections = await getArchivedCollections();
  setList(collections);
  setMemList(collections);
  setLoading(false);
}

export async function getArchivedCollections() {
  let jwtToken = await verifySession();
  let collections;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(baseCollectionsURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      collections = data;
      collections.map((collection) => {
        collection.date = formatDate(collection.createdAt, "DD/MM/YYYY");
      });
    });

  return collections;
}

export async function unarchiveCollectionById(id) {
  let apiResponse;
  let getJwt = await verifySession();

  const unarchiveCollectionURL = baseCollectionsURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveCollectionURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}

export async function deleteCollectionById(id) {
  let apiResponse;
  let getJwt = await verifySession();

  const unarchiveCollectionURL = baseCollectionsURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveCollectionURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}

import { verifySession } from "Services/authenticationServices";
import { transformQuestionList } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseQuestionURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/questions/archive/`;

export async function getAllArchivedQuestions(setList, setMemList, setLoading) {
  const questions = await getArchivedQuestions();
  setList(questions);
  setMemList(questions);
  setLoading(false);
}

export async function getArchivedQuestions() {
  let jwtToken = await verifySession();
  let questions;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(baseQuestionURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      questions = transformQuestionList(data);
    });

  return questions;
}

export async function unarchiveQuestionById(id) {
  let apiResponse;
  let getJwt = await verifySession();

  const unarchiveQuestionURL = baseQuestionURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveQuestionURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}

export async function deleteQuestionById(id) {
  let apiResponse;
  let getJwt = await verifySession();

  const unarchiveQuestionURL = baseQuestionURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(unarchiveQuestionURL, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(getJwt);
  return apiResponse;
}

import React, { useState, useEffect, useRef } from 'react';
import styles from './loading.module.scss';

const phrases = [
  "Coletando informações...",
  "Verificando permissões...",
  "Conectando ao servidor...",
  "Carregando dados...",
  "Configurando ambiente...",
  "Otimizando recursos...",
  "Checando atualizações...",
  "Inicializando componentes...",
  "Preparando interface...",
  "Finalizando processos..."
];

const colors = [
  { background: "#f0f0f0", text: "#000000" },
  { background: "#e0f7fa", text: "#00796b" },
  { background: "#ffe0b2", text: "#e65100" },
  { background: "#f3e5f5", text: "#6a1b9a" },
  { background: "#e8f5e9", text: "#2e7d32" },
  { background: "#fffde7", text: "#fbc02d" },
  { background: "#fbe9e7", text: "#d84315" },
  { background: "#e3f2fd", text: "#1565c0" },
  { background: "#ede7f6", text: "#4527a0" },
  { background: "#f9fbe7", text: "#9e9d24" }
];

const Loading = () => {
  const [currentPhraseIndex, setCurrentPhraseIndex] = useState(0);
  const [loadingText, setLoadingText] = useState("Carregando as informações...");
  const [previousText, setPreviousText] = useState("");
  const [olderText, setOlderText] = useState("");
  const [oldestText, setOldestText] = useState("");
  const progressBarRef = useRef(null);

  useEffect(() => {
    const showNextPhrase = () => {
      const randomDuration = Math.random() * 2 + 2; // Entre 1 e 3 segundos
      const duration = Math.floor(randomDuration * 1000);

      // document.body.style.backgroundColor = colors[currentPhraseIndex].background;
      setLoadingText(phrases[currentPhraseIndex]);

      setOldestText(olderText);
      setOlderText(previousText);
      setPreviousText(loadingText);

      updateProgressBar(duration);

      setTimeout(() => setCurrentPhraseIndex((currentPhraseIndex + 1) % phrases.length), duration);
    };

    const updateProgressBar = (duration) => {
      if (progressBarRef.current) {
        progressBarRef.current.style.transition = 'none';
        progressBarRef.current.style.width = '0';
        setTimeout(() => {
          if (progressBarRef.current) { // Verifica novamente dentro do setTimeout
            progressBarRef.current.style.transition = `width ${duration / 1000}s linear`;
            progressBarRef.current.style.width = '100%';
          }
        }, 50);
      }
    };

    const intervalId = setInterval(showNextPhrase, 3000);
    showNextPhrase(); // Chamar a função imediatamente ao montar o componente

    return () => clearInterval(intervalId);
  }, [currentPhraseIndex, loadingText, olderText, previousText]);

  return (
    <div className={styles.bodyLoading}>
      <div className={styles.backgroundPattern}></div>
      <div className={styles.loadingContainer}>
        <div className={styles.loadingText}>{loadingText}</div>
        <div className={styles.progressBar}>
          <div className={styles.progressBarInner} ref={progressBarRef}></div>
        </div>
      </div>
    </div>
  );
};

export default Loading;



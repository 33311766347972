import Header from "components/Header/header";
import { Outlet } from "react-router-dom";

import styles from "./defaultPage.module.scss";

const DefaultPage = () => {
  return (
    <main className={styles.main}>
      <Header className={styles.mainHeader}></Header>
      <Outlet></Outlet>
    </main>
  );
};

export default DefaultPage;

import "./menuItems.scss";

const MenuItems = ({
  iconUrl,
  iconClass,
  labelClass,
  children,
  menuItemClass,
  caretClasses,
  caretIconUrl,
  smallMenu,
  onClick,
}) => {
  const icon = smallMenu ? "iconSmall" : "icon";
  const iconCaret = smallMenu ? "iconCaretSmall" : "iconCaret";
  const label = smallMenu ? "labelSmall" : "label";
  const menuItems = smallMenu ? "menuItemsSmall" : "menuItems";
  const iconClasses = `${icon} ${iconClass}`;
  const iconCaretClasses = `${iconCaret} ${caretClasses}`;
  const labelClasses = `${label} ${labelClass}`;
  const menuItemsClasses = `${menuItems} ${menuItemClass}`;
  return (
    <div className={menuItemsClasses} onClick={onClick}>
      <img className={iconClasses} src={iconUrl} alt={children}></img>
      <span className={labelClasses}>{children}</span>
      {!smallMenu && caretClasses && caretIconUrl ? (
        <img
          className={iconCaretClasses}
          src={caretIconUrl}
          alt={children}
        ></img>
      ) : (
        ""
      )}
    </div>
  );
};

export default MenuItems;

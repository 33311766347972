import Alert from "components/Alert/Alert";
import { getArchivedQuestions } from "components/ArchivedQuestionsList/archivedQuestionsServices";
import Button from "components/Button/button";
import CloseButton from "components/CloseButton/closeButton";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Field from "components/Field/field";
import FormContainer from "components/FormContainer/formContainer";
import Layout from "components/Layout/layout";
import ManageCollection from "components/ManageCollection/manageCollection";
import PageTitle from "components/PageTitle/pageTitle";
import { getQuestions } from "components/QuestionsList/questionsService";
import { Archive, Star } from "phosphor-react";
import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useNavigate, useParams } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";

import {
  archiveCollectionById,
  createCollection,
  getCollectionById,
  updateCollectionById,
} from "./collectionServices";
import styles from "./editCollection.module.scss";
import { PlusCircle } from "phosphor-react";
import Loading from "components/Loading/loading";

const EditCollection = () => {
  let { collectionToEdit } = useParams();

  let pageTitle;

  pageTitle = collectionToEdit === "new" ? "Novo Padrão" : "Editar Padrão";

  const navigate = useNavigate();

  const [closingConfirmationModal, setClosingConfirmationModal] =
    useState(false);

  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  const [items, setItems] = useState([
    {
      questionId: null,
      prefix: null,
      highlight: false,
      required: false,
    },
  ]);

  const [collectionTitle, setCollectionTitle] = useState(null);

  const [favorite, setFavorite] = useState(false);

  const [loading, setLoading] = useState(true);
  const [questionsList, setQuestionsList] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [archivedAndAvailablequestions, setArchivedAndAvailablequestions] =
    useState([]);

  const [disabledSave, setDisableSave] = useState(true);
  const [alerts, setAlerts] = useState([]);

  const [ordersUsing, setOrdersUsing] = useState(0);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);
  

  const handleCollection = (collections) => {
    let _collections = [];

    collections.map((collection) => {
      if (archivedAndAvailablequestions.length > 0) {
        const questionIndex = archivedAndAvailablequestions.findIndex(
          (question) => question.id == collection.questionId
        );

        const questionLabel =
          archivedAndAvailablequestions[questionIndex].title;

        collection.questionLabel = questionLabel;
        _collections.push(collection);
      }
    });
    setItems(_collections);
    setLoading(false);
  };

  // 2 - fetch on question API.
  const fetchQuestionsData = async () => {
    let allQuestions = [];
    console.log("fetching...")
    const questionsData = await getQuestions();
    questionsData.map((data) => allQuestions.push(data));

    const archivedQuestionsData = await getArchivedQuestions();
    archivedQuestionsData.map((data) => allQuestions.push(data));

    setArchivedAndAvailablequestions(allQuestions);
    setQuestionsList(questionsData);

    if (collectionToEdit == "new") {
      setDisableSave(false);
      setLoading(false);
    }

    //setLoading(false)
  };

  // 3 - this effect will be execute after questionsList to be setting (fetchQuestionsData), this effect will get the id and label of questions and set Questions
  useEffect(() => {
    if (questionsList.length > 0) {
      let _questions = [...questions];
      _questions = [];
      console.log("setting questions list")
      questionsList.map((question) => {
        _questions.push({
          value: question.id,
          label: question.title,
          type: question.questionType,
        });
      });
      setQuestions(_questions);
      console.log("questions are set")
    }
  }, [questionsList]);

  // 4 - this effect will be execute after questions to be setting, and will fecth collections(fetchCollectionData)
  useEffect(() => {
    if (questions.length > 0 && collectionToEdit != "new" && collectionTitle === null) {
      fetchCollectionData();
      console.log("Fetching collection data...")
    }
  }, [questions]);

  // 5
  const fetchCollectionData = async () => {
    if (questions.length > 0) {
      const collectionData = await getCollectionById(collectionToEdit);

      setCollectionTitle(collectionData.title);
      console.log(collectionData)
      if ("ordersUsing" in collectionData) {
        setOrdersUsing(collectionData.ordersUsing)
      } else {
        setOrdersUsing(0)
      }
      setFavorite(collectionData.favorite);

      handleCollection(collectionData.collection);
    }

    console.log("Collection data fetched!")

  };

  //1 - the first useEffect to trigger fetchQuestionsData
  useEffect(() => {
    const fetchData = async () => {
      if (questionsList.length === 0) {
        // Fetch questions data
        await fetchQuestionsData();
      }
    };

    fetchData();
  }, [questionsList]);


  const hasChanged = async () => {
    const originalCollection = await getCollectionById(collectionToEdit);
    console.log(originalCollection.message);

    if (originalCollection.message) {
      navigate("/notFound");
      return
    }
    let _originalCollection = [];

    originalCollection.collection.map((collection) => {
      if (questions.length > 0) {
        const questionIndex = archivedAndAvailablequestions.findIndex(
          (question) => question.id == collection.questionId
        );
        const questionLabel =
          archivedAndAvailablequestions[questionIndex].title;
        collection.questionLabel = questionLabel;
        _originalCollection.push(collection);
      }
    });
    if (loading == false && questions.length > 0) {
      if (collectionTitle != originalCollection.title) {
        //console.log("teve alteração no título!");
        setDisableSave(false);
      }
      if (favorite != originalCollection.favorite) {
        //console.log("teve alteração no favorito!");
        setDisableSave(false);
      }
      if (JSON.stringify(items) !== JSON.stringify(_originalCollection)) {
        //console.log("teve alteração na collections!");
        setDisableSave(false);
      }
    }
  };

  const collectionValidation = () => {
    let isValid = true;

    if (collectionTitle == undefined || collectionTitle == "") {
      return (isValid = false);
    }

    const isQuestionEmpty = items.find((item) => item.questionId == null);
    if (isQuestionEmpty) {
      return (isValid = false);
    }

    return isValid;
  };

  const buildCollectionBody = () => {
    let _items = [...items];


    let collectionBody = {
      title: collectionTitle,
      collection: items,
      favorite: favorite,
    };

    return collectionBody;
  };

  const createNewCollection = async () => {
    const isValid = await collectionValidation();
    let updateAlertList = "";
    if (isValid == true) {
      let collectionBody = buildCollectionBody();
      createCollection(collectionBody).then((newCollection) => {
        console.log(newCollection);
        if (newCollection.status == 200) {
          const id = newCollection.body.collection.pk.split("#");
          setDisableSave(true);
          navigate("/collection/" + id[1]);
          let message = "Padrão '" + collectionTitle + "' criado com sucesso!";
          updateAlertList = addNewAlert([], { message, type: "success" });
        } else {
          let messageError = newCollection.body.message;
          updateAlertList = addNewAlert([], {
            messageError,
            type: "danger",
          });
        }
        setAlerts(updateAlertList);
        setLoading(false);
      });
    } else {
      let message =
        "Preencha todos os campos obrigátorios para salvar o padrão!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
    }
  };

  const updateCollection = async () => {
    setLoading(true);
    const isValid = await collectionValidation();
    let updateAlertList = "";
    if (isValid == true) {
      let collectionBody = buildCollectionBody();

      updateCollectionById(collectionBody, collectionToEdit).then(
        (responseCollection) => {
          if (responseCollection.status == 200) {
            setDisableSave(true);
            let message =
              "Padrão '" + collectionTitle + "' atualizado com sucesso!";
            updateAlertList = addNewAlert([], { message, type: "success" });
          } else {
            let messageError = responseCollection.body.message;
            updateAlertList = addNewAlert([], {
              messageError,
              type: "danger",
            });
          }
          setAlerts(updateAlertList);
          setLoading(false);
        }
      );
    } else {
      let message =
        "Preencha todos os campos obrigátorios para atualizar o padrão!";
      updateAlertList = addNewAlert([], { message, type: "danger" });
      setAlerts(updateAlertList);
    }
  };

  const archiveCollection = () => {
    archiveCollectionById(collectionToEdit).then((response) => {
      if (response.status == 200) {
        setArchiveConfirmationModal(false);
        let message = "Padrão " + collectionTitle + " arquivado com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };

  useEffect(() => {
    //console.log(items);
    if (collectionToEdit != "new") {
      hasChanged();
    }
  }, [collectionTitle, items, favorite]);

  const handleClass = () => {
    const buttonDisabled = disabledSave ? styles.buttonDisabled : null;
    const className =
      styles.saveButton + " " + "primaryOrange" + " " + buttonDisabled;
    return className;
  };
  //used to remove shadow and add background in div dragged
  document.addEventListener("dragstart", function (event) {
    event.dataTransfer.setDragImage(event.target, window.outerWidth, window.outerHeight);
    event.target.style.background = '#d9d9d9'
    event.target.style.borderRadius = '8px'
    event.target.style.cursor = 'grabbing'
  }, false);

  document.addEventListener("dragover", function (event) {
    event.preventDefault();
  }, false);

  //used to remove background in div dragged
  document.addEventListener("dragend", function (event) {
    event.target.style.background = 'none'
    event.target.style.borderRadius = 'none'
    event.target.style.cursor = 'grab'
  }, false);

  const renderContent = () => {

    switch (loading){
      case true:
        return (
          <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
        )
      case false:
        if(capabilities.includes("cadastros")){
          return(
            <>
              <div className={styles.buttonsContainer} >
                <Button
                  className={styles.createQuestionButton + " " + "primaryOrange"}
                  onClick={() => {
                    navigate("/collection/new");
                    window.location.reload();
                  }}
                >
                  <PlusCircle size={22} className={styles.addIcon} />
                  <span style={{ paddingBottom: "3px" }}>Criar Padrão</span>
                </Button>
              </div>
              <div style={{ overflow: "auto" }}>
                {alerts.map((i, k) => {
                  return <Alert message={i.message} type={i.type} style={{ width: '90%' }} />;
                })}
                {ordersUsing > 0 &&
                  <Alert message={`Este padrão está sendo utilizado em ${ordersUsing} pedidos.`} type={"warning"} />
                }
                <FormContainer className={styles.container}>
                  <label>
                    Título<span style={{ color: "red" }}>*</span>
                  </label>
                  <Field
                    placeholder={"Título do padrão"}
                    name={"collectionTitle"}
                    id={"collectionTitle"}
                    defaultValue={collectionTitle}
                    onChange={(event) => setCollectionTitle(event.target.value)}
                  />
                  <ManageCollection
                    items={items}
                    setItems={setItems}
                    questions={questions}
                  />
                  <div className={styles.favoriteContainer}>
                    {favorite == false ? (
                      <a
                        onClick={() => {
                          setFavorite(true);
                        }}
                      >
                        <Star className={styles.checkAsFavorite} />
                      </a>
                    ) : (
                      <a onClick={() => setFavorite(false)}>
                        <Star className={styles.checkAsFavorite} weight="fill" />
                      </a>
                    )}
                    <span>&nbsp;&nbsp;Marcar como favorito</span>
                  </div>
                </FormContainer>
              </div>
              {ordersUsing < 1 &&
                <div className={styles.buttonsContainer}>
                  <div id={"saveButton"}>
                    <Button
                      id={"saveButton"}
                      isDisabled={disabledSave}
                      className={handleClass()}
                      onClick={() => {
                        if (collectionToEdit == "new") {
                          createNewCollection();
                        } else {
                          updateCollection();
                        }
                      }}
                    >
                      Salvar
                    </Button>
                  </div>
                  <div style={{ marginLeft: "auto" }} id={"cancelButton"}>
                    <Button
                      id={"cancelButton"}
                      className={styles.cancelButton + " " + "secondaryOrange"}
                      onClick={() => setClosingConfirmationModal(true)}
                    >
                      Cancelar
                    </Button>
                  </div>
                </div>
              }

            </>
          )
          }else{
            return(
              <div className={styles.error}>
              <center>
                <Alert
                  type={"danger"}
                  message={
                    "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                  }
                />
              </center>
            </div>
            )
          }
        }
    }

  return (
    <Layout>
      <div className={styles.pageHeader}>
                <PageTitle>{pageTitle}</PageTitle>
                <div className={styles.closeButton}>
                  {collectionToEdit != "new" ? (
                    <ConfirmationModal
                      id={"archiveCollection"}
                      iconComponent={
                        <Button title={"Arquivar"} className={styles.archiveButton}>
                          <Archive size={20} className={styles.archiveIcon} />
                        </Button>
                      }
                      title={"Deseja realmente arquivar este padrão?"}
                      onClick={() => setArchiveConfirmationModal(true)}
                      open={archiveConfirmationModal}
                      onOk={archiveCollection}
                      onCancel={() => setArchiveConfirmationModal(false)}
                    />
                  ) : null}
                  <ConfirmationModal
                    onClick={() => setClosingConfirmationModal(true)}
                    title={"Deseja sair?"}
                    open={closingConfirmationModal}
                    onOk={() => navigate("/collections")}
                    onCancel={() => setClosingConfirmationModal(false)}
                    iconComponent={<CloseButton />}
                  />
                </div>
              </div>
      {renderContent()}
    </Layout>
  );
};

export default EditCollection;

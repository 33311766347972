import { verifySession } from "Services/authenticationServices";

const stage = process.env.REACT_APP_STAGE;
const baseQuestionURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/questions/`;

export async function getQuestionById(id) {
  let jwtToken = await verifySession();
  let questionData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz",
    },
  };
  const getQuestionByIdURL = baseQuestionURL + id;

  await fetch(getQuestionByIdURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      questionData = data;
    });
  return questionData;
}

export async function createQuestion(questionBody) {
  let apiResponse;
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(questionBody),
    };

    await fetch(baseQuestionURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

export async function updateQuestionById(id, questionBody) {
  let apiResponse;
  const updateQuestionURL = baseQuestionURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(questionBody),
    };

    await fetch(updateQuestionURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

export async function archiveQuestionById(id) {
  let apiResponse;
  const archiveQuestionURL = baseQuestionURL + "archive/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(archiveQuestionURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

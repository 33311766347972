import React from "react";
import Layout from "components/Layout/layout";
import { getGroupedResults } from "Services/results";
import { useNavigate, useParams } from "react-router-dom";
import { getAssessmentById } from "components/EditAssessment/assessmentService";
import { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import styles from "./defaultReport.module.scss";
import { WarningCircle, Star } from "phosphor-react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Button from "components/Button/button";
import { DotsThreeOutline } from "phosphor-react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Loading from "components/Loading/loading";
import logo1 from "../../assets/logo1.png";
import logo2 from "../../assets/logo2.png";
const { PDFExport } = require("@progress/kendo-react-pdf");


export const DefaultReportBlockInformation = ({ blockInfo, index }) => {
  return (
    <>
      {blockInfo.blockHeader.headerLines ? (
        <div
          className={`${styles.headerInfo} ${index !== 0 ? "page-break" : ""}`}
        >
          <div className={styles.academicInfo}>
            {blockInfo.blockHeader.headerLines &&
              blockInfo.blockHeader.headerLines.map((lines) => {
                if (lines.type === "teacher") {
                  return (
                    <>
                      <span className={styles.spanAcademicInfo}>
                        <span className={styles.labelBold}>
                          {(() => {
                            switch (lines.values.designation) {
                              case "Professor":
                                return "Professor(a):";
                              case "Convidado":
                                return "Convidado(a):";
                              case "Palestrante":
                                return "Palestrante:";
                              default:
                                return "";
                            }
                          })()}
                        </span>
                        <span className={styles.text}>{lines.values.name}</span>
                      </span>
                    </>
                  );
                }
              })}
          </div>

          <div className={styles.casesInfo}>
            {blockInfo.blockHeader.headerLines &&
              blockInfo.blockHeader.headerLines.map((lines) => {
                if (
                  lines.type === "labeledText" &&
                  lines.values.label !== null &&
                  lines.values.text !== null &&
                  lines.values.label !== ""
                ) {
                  return (
                    <div>
                      <span>
                        <span
                          className={
                            lines.values.labelBold === true
                              ? styles.labelBold
                              : styles.labelRegular
                          }
                        >
                          {typeof lines.values.label !== "string"
                            ? lines.values.label.pt
                            : lines.values.label}
                        </span>
                        <span className={styles.casesInfoText}>
                          {typeof lines.values.text !== "string"
                            ? lines.values.text.pt
                            : lines.values.text}
                        </span>
                      </span>
                    </div>
                  );
                }
                if (
                  lines.type === "labeledText" &&
                  lines.values.label !== null &&
                  lines.values.text === null
                ) {
                  return (
                    <>
                      <span
                        className={
                          lines.values.labelBold === true
                            ? styles.labelBold
                            : styles.labelRegular
                        }
                      >
                        {lines.values.label}
                      </span>
                    </>
                  );
                }
                if (
                  lines.type === "labeledText" &&
                  lines.values.label === null &&
                  lines.values.text !== null
                ) {
                  return (
                    <span className={styles.casesInfoText}>
                      {lines.values.text}
                    </span>
                  );
                }
              })}
          </div>
        </div>
      ) : (
        <div
          className={`${styles.headerNoInfo} ${
            index !== 0 ? "page-break" : ""
          }`}
        ></div>
      )}
      <>
        <div className={styles.customListContainer}>
          <ul className={styles.customList + " " + styles.blockInfoListColor}>
            <li></li>
          </ul>
        </div>
      </>
    </>
  );
};

export const DefaultReportTemplate = ({
  order,
  questions,
  results,
  pdfExportComponent,
}) => {
  const currentUrl = window.location.href;
  /**
   * Counts the number of responses for a specific alternative in a survey.
   * @param {string} blockId - The ID of the survey block.
   * @param {string} questionId - The ID of the question within the block.
   * @param {string} alternativeId - The ID of the alternative to count.
   * @returns {number} - The number of times the specified alternative was selected.
   */
  function countsNumberOfResponsesForAlternative(
    blockId,
    questionId,
    alternativeId
  ) {
    // Initialize a variable to keep track of the count.
    let numberOfSelections = 0;

    // Loop through survey results to count responses.
    results &&
      results.results.map((block, indexOfBlock) => {
        if (block.blockId === blockId) {
          block.questions.map((question, indexOfQuestion) => {
            if (question.questionId == questionId) {
              question.answers.map((answer, indexOfAnswer) => {
                // Check if the answer is a string (single-choice question).
                if (answer.answer === null) {
                  numberOfSelections += 0;
                } else if (
                  typeof answer.answer === "string" &&
                  answer.answer === alternativeId
                ) {
                  numberOfSelections += 1;
                } else if (
                  typeof answer.answer.id === "string" &&
                  answer.answer.id === alternativeId
                ) {
                  if (answer.answer.weight) {
                    numberOfSelections += answer.answer.weight;
                  } else {
                    numberOfSelections += 1;
                  }
                }
                // Check if the answer is an array (multiple-choice question).
                else if (Array.isArray(answer.answer)) {
                  answer.answer.map((choice, indexOfChoice) => {
                    if (choice.id === alternativeId) {
                      if (choice.weight) {
                        numberOfSelections += choice.weight;
                      } else {
                        numberOfSelections += 1;
                      }
                    } else if (
                      typeof choice === "string" &&
                      choice === alternativeId
                    ) {
                      numberOfSelections += 1;
                    }
                  });
                }
              });
            }
          });
        }
      });

    return numberOfSelections;
  }

  /**
   * Retrieves text answers for a specific question in a survey block.
   * @param {string} blockId - The ID of the survey block.
   * @param {string} questionId - The ID of the question to retrieve text answers for.
   * @returns {string[]} - An array of text answers for the specified question.
   */
  function getsTextAnswersForQuestion(blockId, questionId) {
    // Initialize an array to store text answers.
    let textAnswers = [];

    // Loop through survey results to collect text answers.
    results &&
      results.results.map((block, indexOfBlock) => {
        if (block.blockId === blockId) {
          block.questions.map((question, indexOfQuestion) => {
            if (question.questionId == questionId) {
              question.answers.map((answer, indexOfAnswer) => {
                // Check if the answer is a string (text answer).
                if (typeof answer.answer === "string") {
                  textAnswers.push(answer.answer);
                }
              });
            }
          });
        }
      });

    return textAnswers;
  }

  /**
   * Counts the number of responses with a specific rating in a survey.
   * @param {string} blockId - The ID of the survey block.
   * @param {string} questionId - The ID of the question to count responses for.
   * @param {number} rating - The rating value to count.
   * @returns {number} - The number of times the specified rating was selected.
   */
  function getsRatingScaleAnswers(blockId, questionId, rating) {
    // Initialize a variable to keep track of the count.
    let numberOfSelections = 0;

    // Loop through survey results to count responses.
    results &&
      results.results.map((block, indexOfBlock) => {
        if (block.blockId === blockId) {
          block.questions.map((question, indexOfQuestion) => {
            if (question.questionId == questionId) {
              question.answers.map((answer, indexOfAnswer) => {
                // Check if the answer is a number and equals the specified rating.
                if (
                  typeof answer.answer === "number" &&
                  Number.isInteger(answer.answer) &&
                  answer.answer === rating
                ) {
                  numberOfSelections += 1;
                }
              });
            }
          });
        }
      });

    return numberOfSelections;
  }

  /**
   * Gets the number of answers for a specific question in a survey block.
   * @param {string} blockId - The ID of the survey block.
   * @param {string} questionId - The ID of the question to count answers for.
   * @param {any} content - (Optional) The specific answer content to filter by, or null to count all answers.
   * @returns {number} - The number of answers that match the provided content (or all answers if content is null).
   */
  function countAnswers(blockId, questionId, content = null) {
    // Initialize a variable to keep track of the count.
    let numberOfAnswers = 0;

    // Loop through survey results to count answers.
    results &&
      results.results.map((block, indexOfBlock) => {
        if (block.blockId === blockId) {
          block.questions.map((question, indexOfQuestion) => {
            if (question.questionId == questionId) {
              if (content === null) {
                numberOfAnswers = question.answers.length;
              } else {
                question.answers.map((answer, indexOfAnswer) => {
                  if (answer.answer === content) {
                    numberOfAnswers += 1;
                    // console.log(numberOfAnswers)
                  }
                });
              }
            }
          });
        }
      });

    return numberOfAnswers;
  }

  /**
   * Calculates the average rating for a specific question in a survey block.
   * @param {string} blockId - The ID of the survey block.
   * @param {string} questionId - The ID of the question to calculate the average rating for.
   * @returns {number} - The average rating for the specified question.
   */
  function getAverageRating(blockId, questionId) {
    // console.log("getAverageRating");
    // console.log(blockId)
    // Initialize an array to store available answers.
    let availableAnswers = [];
    // console.log(availableAnswers)
    // Loop through survey results to collect answers.
    results &&
      results.results.map((block, indexOfBlock) => {
        if (block.blockId === blockId) {
          block.questions.map((question, indexOfQuestion) => {
            if (question.questionId == questionId) {
              question.answers.map((answer, indexOfAnswer) => {
                if (answer.answer !== null) {
                  availableAnswers.push(answer.answer);
                }
              });
            }
          });
        }
      });
    // console.log(availableAnswers)
    // Calculate the total and the average rating.
    let total = availableAnswers.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    let average = total / availableAnswers.length;

    let result = average.toFixed(1);
    let formattedResult = result.replace(".", ",");
    return formattedResult;
  }

  /**
   * Calculates the standard deviation of ratings for a specific question within a given block.
   *
   * @param {string} blockId - The unique identifier for the block.
   * @param {string} questionId - The unique identifier for the question.
   * @returns {number} The standard deviation of the ratings for the specified question in the given block.
   */
  function calculateStandardDeviation(blockId, questionId) {
    // An array of possible classifications (e.g., ratings from 1 to 5).
    let classifications = [1, 2, 3, 4, 5];

    // Initialize variables to keep track of total answers and mean.
    let totalAnswers = 0;
    let mean = 0;

    // Calculate the mean by summing up products of ratings and their counts.
    classifications.forEach((n) => {
      let numberOfAnswersForN = countAnswers(blockId, questionId, n);
      mean += n * numberOfAnswersForN;
      totalAnswers += numberOfAnswersForN;
    });

    // Calculate the actual mean by dividing the sum by the total number of answers.
    mean /= totalAnswers;

    // Initialize a variable to keep track of the sum of squared differences.
    let sumOfSquaredDifferences = 0;

    // Calculate the sum of squared differences for each rating.
    classifications.forEach((n) => {
      let numberOfAnswersForN = countAnswers(blockId, questionId, n);
      sumOfSquaredDifferences += numberOfAnswersForN * Math.pow(n - mean, 2);
    });

    // Calculate the variance.
    let variance = sumOfSquaredDifferences / totalAnswers;

    // Calculate the standard deviation by taking the square root of the variance.
    let standardDeviation = Math.sqrt(variance);

    // Return the calculated standard deviation.
    return standardDeviation.toFixed(1);
  }

  function calculateScore(scoreArray) {
    if (scoreArray.length === 0) {
      return 0;
    }
    return scoreArray
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      .toFixed(1);
  }

  function calculateNPS(blockId, questionId) {
    // Define the arrays to store scores.
    let promoter = [];
    let passive = [];
    let detractor = [];

    // Loop through survey results to count responses.
    results &&
      results.results.map((block, indexOfBlock) => {
        if (block.blockId === blockId) {
          block.questions.map((question, indexOfQuestion) => {
            if (question.questionId == questionId) {
              question.answers.map((answer, indexOfAnswer) => {
                // Check if the answer is a number and equals the specified rating.
                if (
                  typeof answer.answer === "number" &&
                  Number.isInteger(answer.answer)
                ) {
                  if (answer.answer >= 9 && answer.answer <= 10) {
                    promoter.push(answer.answer);
                  } else if (answer.answer >= 7 && answer.answer <= 8) {
                    passive.push(answer.answer);
                  } else if (answer.answer >= 0 && answer.answer <= 6) {
                    detractor.push(answer.answer);
                  }
                }
              });
            }
          });
        }
      });

    let totalResponses = promoter.length + passive.length + detractor.length;

    // Calculate the scores for promoters, passives, and detractors.
    let promoterScore = (promoter.length / totalResponses) * 100;
    let passiveScore = (passive.length / totalResponses) * 100;
    let detractorScore = (detractor.length / totalResponses) * 100;

    // Calculate the NPS.
    let nps = promoterScore - detractorScore;

    const R = 100;
    let x = nps.toFixed(0); // Atualiza a seta e o texto central com o valor de x

    const circleContainer = document.getElementById("circleContainer");
    const circleContainer2 = document.getElementById("circleContainer2");
    const h = 0.2 * 2 * R;
    const A_P1 = 180 + Math.asin((R - h) / R) * (180 / Math.PI);
    const A_P2 = 360 - Math.asin((R - h) / R) * (180 / Math.PI);

    function positionPoints() {
      if (
        !document.getElementById("p1").style.left &&
        !document.getElementById("p2").style.left
      ) {
        // console.log("carregando nps");
      } else {
        document.getElementById("p1").style.left = `${
          R + R * Math.cos((Math.PI * A_P1) / 180)
        }px`;
        document.getElementById("p1").style.top = `${
          R - R * Math.sin((Math.PI * A_P1) / 180)
        }px`;

        document.getElementById("p2").style.left = `${
          R + R * Math.cos((Math.PI * A_P2) / 180)
        }px`;
        document.getElementById("p2").style.top = `${
          R - R * Math.sin((Math.PI * A_P2) / 180)
        }px`;
      }
    }

    function angleForX(x) {
      if (x < 0) {
        return A_P1 + ((90 - A_P1) * (x + 100)) / 100;
      } else if (x === 0) {
        return 90;
      } else {
        const x_0 = (90 * 100) / (A_P1 - 90);
        if (x < x_0) {
          return 90 - (90 * x) / x_0;
        } else {
          return 360 - ((360 - A_P2) * (x - x_0)) / (100 - x_0);
        }
      }
    }

    function updatePxPosition(x) {
      const angleDeg = angleForX(x);
      const angleRad = angleDeg * (Math.PI / 180);
      const xCoord = R + R * Math.cos(angleRad);
      const yCoord = R - R * Math.sin(angleRad);
      const px = document.getElementById("px");
      if (!px.style.left && !px.style.top && !px.style.transform) {
        // console.log("carregando nps");
      } else {
        px.style.left = `${xCoord}px`;
        px.style.top = `${yCoord}px`;
        px.style.transform = `translate(-50%, -50%) rotate(${
          90 - angleDeg + 180
        }deg)`;
      }
      document.getElementById("centerText").textContent = x;
    }

    setTimeout(() => {
      positionPoints();
      updatePxPosition(x);
    }, 500);

    // console.log('promoter:', promoter.length, 'passive:', passive.length, 'detractor', detractor.length)

    return {
      promoter: promoterScore.toFixed(0),
      passive: passiveScore.toFixed(0),
      detractor: detractorScore.toFixed(0),
      nps: nps.toFixed(0),
      promoterNumber: promoter.length,
      detractorNumber: detractor.length,
      passiveNumber: passive.length,
    };
  }

  const pageTemplate = (props) => {
    return (
      <div
        style={{
          position: "absolute",
          top: "10px",
          left: "20px",
          width: "100%",
          textAlign: "left",
          fontSize: "12px",
          paddingBottom: "15px", // Garante que haja espaço
        }}
      >
        Página {props.pageNum} de {props.totalPages} - As respostas em texto
        livre refletem fielmente o que os participantes digitaram.
      </div>
    );
  };

  return (
    <PDFExport
      ref={pdfExportComponent}
      margin={{ top: "1cm", left: "10cm", right: 0, bottom: 0 }}
      scale={0.8}
      paperSize={["500mm", "700mm"]}
      content="text/html; charset=utf-8"
      fileName={
        currentUrl.includes("/report/")
          ? `Relatório da Avaliação - ${order.title}.pdf`
          : ``
      }
      forcePageBreak=".page-break"
      pageTemplate={pageTemplate}
    >
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta charset="utf-8" />

      <div className={styles.report}>
        {console.log(order)}
        {order ? (
          <div
            className={`${styles.infoBlock} prevent-split`}
            style={{ keepTogether: "true" }}
          >
            <span
              className={styles.infoBlockTitle + " " + styles.infoBlockLine1}
            >
              {order.header.headerLine1.pt}
            </span>
            <span
              className={styles.infoBlockLine + " " + styles.infoBlockLine2}
            >
              {order.header.headerLine2.pt}
            </span>
            <span
              className={styles.infoBlockLine + " " + styles.infoBlockLine3}
            >
              {order.header.headerLine3.pt}
            </span>
            <span
              className={styles.infoBlockLine + " " + styles.infoBlockLine4}
            >
              {order.header.headerLine4.pt}
            </span>

            {order.header.logos ? (
              <div className={styles.logoContainer}>
                {order.header.logos.logo1 &&
                  (order.header.logos.logo2 || order.header.logos.logo3) &&
                  order.header.logos.logo1.content && (
                    <img
                      className={styles.logoSize}
                      src={order.header.logos.logo1.content}
                      alt="Logo1"
                    />
                  )}
                {order.header.logos.logo1 &&
                  !order.header.logos.logo2 &&
                  !order.header.logos.logo3 && (
                    <img
                      className={styles.OnelogoSize}
                      src={order.header.logos.logo1.content}
                      alt="Logo1"
                    />
                  )}
                {order.header.logos.logo2 &&
                  order.header.logos.logo2.content && (
                    <img
                      className={styles.logoSize}
                      src={order.header.logos.logo2.content}
                      alt="Logo2"
                    />
                  )}
                {order.header.logos.logo3 &&
                  order.header.logos.logo3.content && (
                    <img
                      className={styles.logoSize}
                      src={order.header.logos.logo3.content}
                      alt="Logo3"
                    />
                  )}
              </div>
            ) : (
              <div />
            )}

            {results &&
              results.totalSubmissions &&
              results.totalSubmissions > 1 && (
                <span
                  className={styles.infoBlockLine + " " + styles.infoBlockLine5}
                >
                  {`Resultado: ${results.totalSubmissions} respostas coletadas`}
                </span>
              )}
            {results &&
              results.totalSubmissions &&
              results.totalSubmissions == 1 && (
                <span
                  className={styles.infoBlockLine + " " + styles.infoBlockLine5}
                >
                  {`Resultado: ${results.totalSubmissions} resposta coletada`}
                </span>
              )}
            {results && results.totalSubmissions === 0 && (
              <span
                className={styles.infoBlockLine + " " + styles.infoBlockLine5}
              >
                Nenhuma resposta coletada
              </span>
            )}
          </div>
        ) : (
          <></>
        )}

        {/* <div className={styles.customListContainer} style={{ marginLeft: 75 }}>
                    {order.header.surveyCaption.pt}
                </div> */}
        <div className={`${styles.blocksBody}`}>
          {order &&
            order.questionnaire &&
            order.questionnaire.map((block, index) => {
              let blockId = block.blockId;
              let indexOfBlock = index;
              let lastItemType = null;
              return (
                <>
                  <DefaultReportBlockInformation
                    blockInfo={block}
                    index={index}
                  />
                  {questions[index].collection.questions &&
                    questions[index].collection.questions.map(
                      (block, index) => {
                        if (index > 0) {
                          lastItemType =
                            questions[indexOfBlock].collection.questions[
                              index - 1
                            ].questionType;
                        }

                        if (block.type === "question") {
                          if (block.questionType === "rating-scale") {
                            return (
                              <>
                                {lastItemType != "rating-scale" ? (
                                  <div className={styles.groupedRatings}>
                                    <div className={styles.ratingBox}>
                                      <table style={{ width: "100%" }}>
                                        <thead
                                          style={{
                                            height: 10,
                                            backgroundColor: "transparent",
                                          }}
                                        >
                                          <tr
                                            className={styles.trDefaultReport}
                                          >
                                            <th
                                              className={
                                                styles.questionHeaderLeft
                                              }
                                            ></th>
                                            <th
                                              className={
                                                styles.questionHeaderDefault
                                              }
                                              style={{ width: "4.1rem" }}
                                            >
                                              1
                                              <Star
                                                size={12}
                                                className={styles.starPosition}
                                              />
                                            </th>
                                            <th
                                              className={
                                                styles.questionHeaderDefault
                                              }
                                              style={{ width: "4.1rem" }}
                                            >
                                              2
                                              <Star
                                                size={12}
                                                className={styles.starPosition}
                                              />
                                            </th>
                                            <th
                                              className={
                                                styles.questionHeaderDefault
                                              }
                                              style={{ width: "4.1rem" }}
                                            >
                                              3
                                              <Star
                                                size={12}
                                                className={styles.starPosition}
                                              />
                                            </th>
                                            <th
                                              className={
                                                styles.questionHeaderDefault
                                              }
                                              style={{ width: "4.1rem" }}
                                            >
                                              4
                                              <Star
                                                size={12}
                                                className={styles.starPosition}
                                              />
                                            </th>
                                            <th
                                              className={
                                                styles.questionHeaderDefault
                                              }
                                              style={{ width: "4.1rem" }}
                                            >
                                              5
                                              <Star
                                                size={12}
                                                className={styles.starPosition}
                                              />
                                            </th>
                                            <th
                                              className={
                                                styles.questionHeaderRight
                                              }
                                              style={{ width: "4.1rem" }}
                                            >
                                              Média
                                            </th>
                                            <th
                                              style={{
                                                width: "3.125rem",
                                                display: "none",
                                              }}
                                            >
                                              <b>D.P</b>
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr
                                            className={styles.trDefaultReport}
                                          >
                                            <td className={styles.parentRating}>
                                              {block.prefix && (
                                                <span
                                                  className={
                                                    styles.spanDefaultReport
                                                  }
                                                >
                                                  {block.prefix} -{" "}
                                                </span>
                                              )}
                                              {block.header && block.header.pt}
                                            </td>
                                            <td>
                                              {getsRatingScaleAnswers(
                                                blockId,
                                                block.id,
                                                1
                                              )}
                                            </td>
                                            <td>
                                              {getsRatingScaleAnswers(
                                                blockId,
                                                block.id,
                                                2
                                              )}
                                            </td>
                                            <td>
                                              {getsRatingScaleAnswers(
                                                blockId,
                                                block.id,
                                                3
                                              )}
                                            </td>
                                            <td>
                                              {getsRatingScaleAnswers(
                                                blockId,
                                                block.id,
                                                4
                                              )}
                                            </td>
                                            <td>
                                              {getsRatingScaleAnswers(
                                                blockId,
                                                block.id,
                                                5
                                              )}
                                            </td>
                                            <td>
                                              {getAverageRating(
                                                blockId,
                                                block.id
                                              )}
                                            </td>
                                            <td style={{ display: "none" }}>
                                              {calculateStandardDeviation(
                                                blockId,
                                                block.id,
                                                [1, 2, 3, 4, 5]
                                              )}
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                ) : (
                                  <div className={styles.ratingBox}>
                                    <table style={{ width: "100%" }}>
                                      <tr className={styles.trDefaultReport}>
                                        <td className={styles.parentRating}>
                                          {block.prefix && (
                                            <span
                                              className={
                                                styles.spanDefaultReport
                                              }
                                            >
                                              {block.prefix} -{" "}
                                            </span>
                                          )}
                                          {block.header && block.header.pt}
                                        </td>
                                        <td style={{ width: "3.125rem" }}>
                                          {getsRatingScaleAnswers(
                                            blockId,
                                            block.id,
                                            1
                                          )}
                                        </td>
                                        <td style={{ width: "3.125rem" }}>
                                          {getsRatingScaleAnswers(
                                            blockId,
                                            block.id,
                                            2
                                          )}
                                        </td>
                                        <td style={{ width: "3.125rem" }}>
                                          {getsRatingScaleAnswers(
                                            blockId,
                                            block.id,
                                            3
                                          )}
                                        </td>
                                        <td style={{ width: "3.125rem" }}>
                                          {getsRatingScaleAnswers(
                                            blockId,
                                            block.id,
                                            4
                                          )}
                                        </td>
                                        <td style={{ width: "3.125rem" }}>
                                          {getsRatingScaleAnswers(
                                            blockId,
                                            block.id,
                                            5
                                          )}
                                        </td>
                                        <td style={{ width: "3.125rem" }}>
                                          {getAverageRating(blockId, block.id)}
                                        </td>
                                        <td
                                          style={{
                                            width: "3.125rem",
                                            display: "none",
                                          }}
                                        >
                                          {calculateStandardDeviation(
                                            blockId,
                                            block.id,
                                            [1, 2, 3, 4, 5]
                                          )}
                                        </td>
                                      </tr>
                                    </table>
                                  </div>
                                )}
                              </>
                            );
                          } else {
                            return (
                              <>
                                <div className={styles.groupedQuestion}>
                                  <div className={styles.labelQuestion}>
                                    {block.prefix && (
                                      <span
                                        className={styles.spanDefaultReport}
                                      >
                                        {block.prefix} -{" "}
                                      </span>
                                    )}
                                    {block.header && block.header.pt}
                                  </div>
                                  {block.questionType === "single-choice" && (
                                    <div className={styles.spaceBeforeContent}>
                                      <div
                                        className={styles.customListContainer}
                                      >
                                        <ul
                                          className={
                                            styles.customList +
                                            " " +
                                            styles.orderInfoListColor +
                                            " " +
                                            styles.resultHeader
                                          }
                                        >
                                          {block.alternatives &&
                                            block.alternatives.map((a, i) => {
                                              return (
                                                <li
                                                  className={`${
                                                    styles.groupedQuestion
                                                  } ${
                                                    i % 2 === 1
                                                      ? styles.oddLine
                                                      : styles.regularLine
                                                  }`}
                                                >
                                                  <div
                                                    className={styles.subItem}
                                                  >
                                                    {a.pt}
                                                  </div>
                                                  <span
                                                    className={
                                                      styles.result +
                                                      " " +
                                                      styles.spanDefaultReport
                                                    }
                                                  >
                                                    {countsNumberOfResponsesForAlternative(
                                                      blockId,
                                                      block.id,
                                                      a.id
                                                    )}
                                                  </span>
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </div>
                                    </div>
                                  )}

                                  {block.questionType === "nps" && (
                                    <div className={styles.spaceBeforeContent}>
                                      <div className={styles.npsContainer}>
                                        {/* <div style={{ marginBottom: '0.25rem', fontSize: '1.25rem' }}>
                                            <b>NPS</b>
                                            <span
                                              className={
                                                styles.npsScore +
                                                " " +
                                                styles.spanDefaultReport
                                              }
                                            >
                                              {
                                                calculateNPS(blockId, block.id)
                                                  .nps
                                              }
                                            </span>
                                          </div> */}
                                        <div className={styles.container}>
                                          <div
                                            id={"circleContainer"}
                                            className={styles.circleContainer}
                                          >
                                            <div
                                              className={styles.innerCircle}
                                            ></div>
                                            <div
                                              id={"centerText"}
                                              className={styles.centerText}
                                            ></div>
                                            <div
                                              id="p1"
                                              className={styles.point}
                                              style={{ left: "0", top: "0" }}
                                            ></div>
                                            <div
                                              id="p2"
                                              className={styles.point}
                                              style={{ left: "0", top: "0" }}
                                            ></div>
                                            <div
                                              id="px"
                                              className={
                                                styles.point + " " + styles.px
                                              }
                                              style={{ left: "0", top: "0" }}
                                            ></div>
                                            <span className={styles.topLabel}>
                                              0
                                            </span>
                                          </div>
                                          <div className={styles.rectangle}>
                                            <span>-100</span>
                                            <span>100</span>
                                          </div>
                                        </div>
                                        <ul
                                          className={`
                                            ${styles.customList}
                                            ${styles.orderInfoListColor}
                                            ${styles.npsGapColumn}
                                            `}
                                        >
                                          <li className={styles.npsResultLabel}>
                                            <div className={styles.detractor}>
                                              0 - 6
                                            </div>
                                            <span className={styles.npsRow}>
                                              <span className={styles.npsLabel}>
                                                Detratores
                                              </span>
                                              <span
                                                className={styles.npsResult}
                                              >
                                                {
                                                  calculateNPS(
                                                    blockId,
                                                    block.id
                                                  ).detractor
                                                }
                                                %
                                              </span>
                                            </span>
                                          </li>
                                          <li className={styles.npsResultLabel}>
                                            <div className={styles.passive}>
                                              7 - 8
                                            </div>
                                            <span className={styles.npsRow}>
                                              <span className={styles.npsLabel}>
                                                Passivos
                                              </span>
                                              <span
                                                className={styles.npsResult}
                                              >
                                                {
                                                  calculateNPS(
                                                    blockId,
                                                    block.id
                                                  ).passive
                                                }
                                                %
                                              </span>
                                            </span>
                                          </li>
                                          <li className={styles.npsResultLabel}>
                                            <div className={styles.promoter}>
                                              9 - 10
                                            </div>
                                            <span className={styles.npsRow}>
                                              <span className={styles.npsLabel}>
                                                Promotores
                                              </span>
                                              <span
                                                className={styles.npsResult}
                                              >
                                                {
                                                  calculateNPS(
                                                    blockId,
                                                    block.id
                                                  ).promoter
                                                }
                                                %
                                              </span>
                                            </span>
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  )}

                                  {block.questionType === "text-area" && (
                                    <div className={styles.spaceBeforeTextArea}>
                                      <div
                                        className={styles.customListContainer}
                                      >
                                        <ul
                                          className={
                                            styles.customList +
                                            " " +
                                            styles.orderInfoListColor
                                          }
                                        >
                                          {getsTextAnswersForQuestion(
                                            blockId,
                                            block.id
                                          ).map((textAnswer, i) => {
                                            return (
                                              <li
                                                className={`${
                                                  styles.groupedQuestion
                                                } ${styles.result} ${
                                                  i % 2 === 1
                                                    ? styles.oddLine
                                                    : styles.regularLine
                                                }`}
                                              >
                                                {`${i + 1}. `}
                                                {textAnswer}
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  )}

                                  {block.questionType === "multiple-choice" && (
                                    <div className={styles.spaceBeforeContent}>
                                      <div
                                        className={styles.customListContainer}
                                      >
                                        <ul
                                          className={
                                            styles.customList +
                                            " " +
                                            styles.orderInfoListColor +
                                            " " +
                                            styles.resultHeader
                                          }
                                        >
                                          {block.alternatives.map((a, i) => {
                                            return (
                                              <li>
                                                <div className={styles.subItem}>
                                                  {a.pt}
                                                </div>
                                                <span
                                                  className={
                                                    styles.result +
                                                    " " +
                                                    styles.spanDefaultReport
                                                  }
                                                >
                                                  {countsNumberOfResponsesForAlternative(
                                                    blockId,
                                                    block.id,
                                                    a.id
                                                  )}
                                                </span>
                                              </li>
                                            );
                                          })}
                                        </ul>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </>
                            );
                          }
                        }

                        if (block.type == "group") {
                          let lastQuestionType = null;
                          return (
                            <>
                              <div className={styles.labelQuestion}>
                                {block.prefix && (
                                  <span className={styles.spanDefaultReport}>
                                    {block.prefix} -{" "}
                                  </span>
                                )}
                                {block.header && block.header.pt}
                              </div>
                              <div className={styles.groupOfQuestions}>
                                {block.questions.map((q, i) => {
                                  if (i > 0) {
                                    lastQuestionType =
                                      block.questions[i - 1].questionType;
                                  }

                                  return (
                                    <div
                                      className={`${styles.groupedQuestion} ${
                                        q.questionType === "rating-scale" &&
                                        i % 2 === 1
                                          ? styles.oddBackground
                                          : ""
                                      }`}
                                    >
                                      {q.questionType === "rating-scale" ? (
                                        <>
                                          {lastQuestionType !=
                                          "rating-scale" ? (
                                            <>
                                              {/* {console.log(questions)} */}
                                              <div className={styles.ratingBox}>
                                                <table
                                                  style={{ width: "100%" }}
                                                >
                                                  <thead
                                                    style={{
                                                      height: 10,
                                                      backgroundColor:
                                                        "transparent",
                                                    }}
                                                  >
                                                    <tr
                                                      className={
                                                        styles.trDefaultReport
                                                      }
                                                    >
                                                      <th
                                                        className={
                                                          styles.questionHeaderLeft
                                                        }
                                                      ></th>
                                                      <th
                                                        className={
                                                          styles.questionHeaderDefault
                                                        }
                                                        style={{
                                                          width: "4.1rem",
                                                        }}
                                                      >
                                                        1
                                                        <Star
                                                          size={12}
                                                          className={
                                                            styles.starPosition
                                                          }
                                                        />
                                                      </th>
                                                      <th
                                                        className={
                                                          styles.questionHeaderDefault
                                                        }
                                                        style={{
                                                          width: "4.1rem",
                                                        }}
                                                      >
                                                        2
                                                        <Star
                                                          size={12}
                                                          className={
                                                            styles.starPosition
                                                          }
                                                        />
                                                      </th>
                                                      <th
                                                        className={
                                                          styles.questionHeaderDefault
                                                        }
                                                        style={{
                                                          width: "4.1rem",
                                                        }}
                                                      >
                                                        3
                                                        <Star
                                                          size={12}
                                                          className={
                                                            styles.starPosition
                                                          }
                                                        />
                                                      </th>
                                                      <th
                                                        className={
                                                          styles.questionHeaderDefault
                                                        }
                                                        style={{
                                                          width: "4.1rem",
                                                        }}
                                                      >
                                                        4
                                                        <Star
                                                          size={12}
                                                          className={
                                                            styles.starPosition
                                                          }
                                                        />
                                                      </th>
                                                      <th
                                                        className={
                                                          styles.questionHeaderDefault
                                                        }
                                                        style={{
                                                          width: "4.1rem",
                                                        }}
                                                      >
                                                        5
                                                        <Star
                                                          size={12}
                                                          className={
                                                            styles.starPosition
                                                          }
                                                        />
                                                      </th>
                                                      <th
                                                        className={
                                                          styles.questionHeaderRight
                                                        }
                                                        style={{
                                                          width: "4.1rem",
                                                        }}
                                                      >
                                                        Média
                                                      </th>
                                                      <th
                                                        style={{
                                                          width: "4.1rem",
                                                          display: "none",
                                                        }}
                                                      >
                                                        D.P
                                                      </th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    <tr
                                                      className={
                                                        styles.trDefaultReport
                                                      }
                                                    >
                                                      <td
                                                        className={
                                                          styles.questionHeaderText
                                                        }
                                                      >
                                                        {q.prefix && (
                                                          <span
                                                            className={
                                                              styles.spanDefaultReport
                                                            }
                                                          >
                                                            {q.prefix} -{" "}
                                                          </span>
                                                        )}
                                                        {q.header &&
                                                          q.header.pt}
                                                      </td>
                                                      <td>
                                                        {getsRatingScaleAnswers(
                                                          blockId,
                                                          q.id,
                                                          1
                                                        )}
                                                      </td>
                                                      <td>
                                                        {getsRatingScaleAnswers(
                                                          blockId,
                                                          q.id,
                                                          2
                                                        )}
                                                      </td>
                                                      <td>
                                                        {getsRatingScaleAnswers(
                                                          blockId,
                                                          q.id,
                                                          3
                                                        )}
                                                      </td>
                                                      <td>
                                                        {getsRatingScaleAnswers(
                                                          blockId,
                                                          q.id,
                                                          4
                                                        )}
                                                      </td>
                                                      <td>
                                                        {getsRatingScaleAnswers(
                                                          blockId,
                                                          q.id,
                                                          5
                                                        )}
                                                      </td>
                                                      <td>
                                                        {getAverageRating(
                                                          blockId,
                                                          q.id
                                                        )}
                                                      </td>
                                                      <td
                                                        style={{
                                                          display: "none",
                                                        }}
                                                      >
                                                        {calculateStandardDeviation(
                                                          blockId,
                                                          q.id,
                                                          [1, 2, 3, 4, 5]
                                                        )}
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </table>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div className={styles.ratingBox}>
                                                <table
                                                  style={{ width: "100%" }}
                                                >
                                                  <tr
                                                    className={
                                                      styles.trDefaultReport
                                                    }
                                                  >
                                                    <td
                                                      className={
                                                        styles.questionHeaderText
                                                      }
                                                    >
                                                      {q.prefix && (
                                                        <span
                                                          className={
                                                            styles.spanDefaultReport
                                                          }
                                                        >
                                                          {q.prefix} -{" "}
                                                        </span>
                                                      )}
                                                      {q.header && q.header.pt}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                      }}
                                                    >
                                                      {getsRatingScaleAnswers(
                                                        blockId,
                                                        q.id,
                                                        1
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                      }}
                                                    >
                                                      {getsRatingScaleAnswers(
                                                        blockId,
                                                        q.id,
                                                        2
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                      }}
                                                    >
                                                      {getsRatingScaleAnswers(
                                                        blockId,
                                                        q.id,
                                                        3
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                      }}
                                                    >
                                                      {getsRatingScaleAnswers(
                                                        blockId,
                                                        q.id,
                                                        4
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                      }}
                                                    >
                                                      {getsRatingScaleAnswers(
                                                        blockId,
                                                        q.id,
                                                        5
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                      }}
                                                    >
                                                      {getAverageRating(
                                                        blockId,
                                                        q.id
                                                      )}
                                                    </td>
                                                    <td
                                                      style={{
                                                        width: "4.1rem",
                                                        display: "none",
                                                      }}
                                                    >
                                                      {calculateStandardDeviation(
                                                        blockId,
                                                        q.id,
                                                        [1, 2, 3, 4, 5]
                                                      )}
                                                    </td>
                                                  </tr>
                                                </table>
                                              </div>
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          <div className={styles.questionTitle}>
                                            {q.prefix && (
                                              <span
                                                className={
                                                  styles.spanDefaultReport
                                                }
                                              >
                                                {q.prefix} -{" "}
                                              </span>
                                            )}
                                            {q.header && q.header.pt}
                                          </div>
                                          {q.questionType ===
                                            "multiple-choice" && (
                                            <div
                                              className={
                                                styles.spaceBeforeContent
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.customListContainer
                                                }
                                              >
                                                <ul
                                                  className={
                                                    styles.customList +
                                                    " " +
                                                    styles.orderInfoListColor +
                                                    " " +
                                                    styles.resultHeader
                                                  }
                                                >
                                                  {q.alternatives &&
                                                    q.alternatives.map(
                                                      (a, i) => {
                                                        return (
                                                          <li
                                                            className={`${
                                                              styles.groupedQuestion
                                                            } ${
                                                              i % 2 === 1
                                                                ? styles.oddLine
                                                                : styles.regularLine
                                                            }`}
                                                          >
                                                            <div
                                                              className={
                                                                styles.subItem
                                                              }
                                                            >
                                                              {a.pt}
                                                            </div>
                                                            <span
                                                              className={
                                                                styles.result +
                                                                " " +
                                                                styles.spanDefaultReport
                                                              }
                                                            >
                                                              {countsNumberOfResponsesForAlternative(
                                                                blockId,
                                                                q.id,
                                                                a.id
                                                              )}
                                                            </span>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          )}

                                          {q.questionType === "text-area" && (
                                            <div
                                              className={
                                                styles.spaceBeforeTextArea
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.customListContainer
                                                }
                                              >
                                                <ul
                                                  className={
                                                    styles.customList +
                                                    " " +
                                                    styles.orderInfoListColor
                                                  }
                                                >
                                                  {getsTextAnswersForQuestion(
                                                    blockId,
                                                    q.id
                                                  ).map((textAnswer, i) => {
                                                    return (
                                                      <li
                                                        className={`${
                                                          styles.groupedQuestion
                                                        } ${styles.result} ${
                                                          i % 2 === 1
                                                            ? styles.oddLine
                                                            : styles.regularLine
                                                        }`}
                                                      >
                                                        {`${i + 1}. `}
                                                        {textAnswer}
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </div>
                                            </div>
                                          )}

                                          {q.questionType === "nps" && (
                                            <div
                                              className={
                                                styles.spaceBeforeContent
                                              }
                                            >
                                              <div
                                                className={styles.npsContainer}
                                              >
                                                <div
                                                  className={styles.container}
                                                >
                                                  <div
                                                    id={"circleContainer"}
                                                    className={
                                                      styles.circleContainer
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.innerCircle
                                                      }
                                                    ></div>
                                                    <div
                                                      id={"centerText"}
                                                      className={
                                                        styles.centerText
                                                      }
                                                    ></div>
                                                    <div
                                                      id="p1"
                                                      className={styles.point}
                                                      style={{
                                                        left: "0",
                                                        top: "0",
                                                      }}
                                                    ></div>
                                                    <div
                                                      id="p2"
                                                      className={styles.point}
                                                      style={{
                                                        left: "0",
                                                        top: "0",
                                                      }}
                                                    ></div>
                                                    <div
                                                      id="px"
                                                      className={
                                                        styles.point +
                                                        " " +
                                                        styles.px
                                                      }
                                                      style={{
                                                        left: "0",
                                                        top: "0",
                                                      }}
                                                    ></div>
                                                    <span
                                                      className={
                                                        styles.topLabel
                                                      }
                                                    >
                                                      0
                                                    </span>
                                                  </div>
                                                  <div
                                                    className={styles.rectangle}
                                                  >
                                                    <span>-100</span>
                                                    <span>100</span>
                                                  </div>
                                                </div>
                                                <ul
                                                  className={`
                                            ${styles.customList}
                                            ${styles.orderInfoListColor}
                                            ${styles.npsGapColumn}
                                            `}
                                                >
                                                  <li
                                                    className={
                                                      styles.npsResultLabel
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.detractor
                                                      }
                                                    >
                                                      0 - 6
                                                    </div>
                                                    <span
                                                      className={styles.npsRow}
                                                    >
                                                      <span
                                                        className={
                                                          styles.npsLabel
                                                        }
                                                      >
                                                        Detratores
                                                      </span>
                                                      <span
                                                        className={
                                                          styles.npsResult
                                                        }
                                                      >
                                                        {
                                                          calculateNPS(
                                                            blockId,
                                                            q.id
                                                          ).detractor
                                                        }
                                                        %
                                                      </span>
                                                    </span>
                                                  </li>
                                                  <li
                                                    className={
                                                      styles.npsResultLabel
                                                    }
                                                  >
                                                    <div
                                                      className={styles.passive}
                                                    >
                                                      7 - 8
                                                    </div>
                                                    <span
                                                      className={styles.npsRow}
                                                    >
                                                      <span
                                                        className={
                                                          styles.npsLabel
                                                        }
                                                      >
                                                        Passivos
                                                      </span>
                                                      <span
                                                        className={
                                                          styles.npsResult
                                                        }
                                                      >
                                                        {
                                                          calculateNPS(
                                                            blockId,
                                                            q.id
                                                          ).passive
                                                        }
                                                        %
                                                      </span>
                                                    </span>
                                                  </li>
                                                  <li
                                                    className={
                                                      styles.npsResultLabel
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.promoter
                                                      }
                                                    >
                                                      9 - 10
                                                    </div>
                                                    <span
                                                      className={styles.npsRow}
                                                    >
                                                      <span
                                                        className={
                                                          styles.npsLabel
                                                        }
                                                      >
                                                        Promotores
                                                      </span>
                                                      <span
                                                        className={
                                                          styles.npsResult
                                                        }
                                                      >
                                                        {
                                                          calculateNPS(
                                                            blockId,
                                                            q.id
                                                          ).promoter
                                                        }
                                                        %
                                                      </span>
                                                    </span>
                                                  </li>
                                                </ul>
                                              </div>
                                            </div>
                                          )}

                                          {q.questionType ===
                                            "single-choice" && (
                                            <div
                                              className={
                                                styles.spaceBeforeContent
                                              }
                                            >
                                              <div
                                                className={
                                                  styles.customListContainer
                                                }
                                              >
                                                <ul
                                                  className={
                                                    styles.customList +
                                                    " " +
                                                    styles.orderInfoListColor +
                                                    " " +
                                                    styles.resultHeader
                                                  }
                                                >
                                                  {q.alternatives &&
                                                    q.alternatives.map(
                                                      (a, i) => {
                                                        return (
                                                          <li
                                                            className={`${
                                                              styles.groupedQuestion
                                                            } ${
                                                              i % 2 === 1
                                                                ? styles.oddLine
                                                                : styles.regularLine
                                                            }`}
                                                          >
                                                            <div
                                                              className={
                                                                styles.subItem
                                                              }
                                                            >
                                                              {a.pt}
                                                            </div>
                                                            <span
                                                              className={
                                                                styles.result +
                                                                " " +
                                                                styles.spanDefaultReport
                                                              }
                                                            >
                                                              {countsNumberOfResponsesForAlternative(
                                                                blockId,
                                                                q.id,
                                                                a.id
                                                              )}
                                                            </span>
                                                          </li>
                                                        );
                                                      }
                                                    )}
                                                </ul>
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          );
                        }
                      }
                    )}
                </>
              );
            })}
        </div>
      </div>
    </PDFExport>
  );
};

export const DefaultReport = () => {
  let { orderId } = useParams();
  const [order, setOrder] = useState();
  const [results, setResults] = useState();
  const [loading, setLoading] = useState(true);
  const [groupedQuestions, setGroupedQuestions] = useState();
  const [isPopupOpenned, setIsPopupOpenned] = useState(false);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  const navigate = useNavigate();

  const pdfExportComponent = React.useRef(
    <DefaultReportTemplate
      order={order}
      questions={groupedQuestions}
      results={results}
    />
  );

  const exportPDFWithComponent = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
    }
  };

  function organizeBlocks(questionnaire) {
    let organizedQuestionnaire = [];
    //let _questionnaire = [...questionnaire]
    questionnaire &&
      questionnaire.map((block, index) => {
        //let organizedBlock = []
        let organizedQuestions = collectionOrganizer(
          block.collection.questions
        );
        //block.collection.questions = collectionOrganizer(listOfQuestions)
        let thisBlock = JSON.parse(JSON.stringify(block));
        thisBlock.collection.questions = organizedQuestions;

        organizedQuestionnaire.push(thisBlock);
      });
    return organizedQuestionnaire;
  }

  function collectionOrganizer(questions) {
    let organizedCollection = [];
    let group = null;
    let lastGroupIndex = null;
    questions &&
      questions.map((question, index) => {
        if (question.questionType === "label") {
          group = {
            ...question,
            type: "group",
            label: question.header,
            questions: [],
          };

          lastGroupIndex = index;
          organizedCollection.push(group);
        } else {
          if (lastGroupIndex != null) {
            group.questions.push(question);
          } else {
            let _question = { ...question };
            _question.type = "question";
            organizedCollection.push(_question);
          }
        }
      });
    return organizedCollection;
  }

  function controlPopUp() {
    if (isPopupOpenned == true) {
      setIsPopupOpenned(false);
    } else {
      setIsPopupOpenned(true);
    }
  }

  // //Integration
  useEffect(() => {
    const fetchOrderAndResults = () => {
      getAssessmentById(orderId)
        .then((orderData) => {
          setOrder(orderData);
          setGroupedQuestions(organizeBlocks(orderData.questionnaire));
          // console.log();
          // After setting the order data, fetch the results data
          getGroupedResults(orderId)
            .then((resultsData) => {
              // console.log(resultsData);
              // console.log(order);

              setResults(resultsData);
              setLoading(false);
            })
            .catch((error) => {
              console.error("Error fetching results data:", error);
              navigate("/notFound");
            });
        })
        .catch((error) => {
          console.error("Error fetching assessment data:", error);
          setOrder(false);
          navigate("/notFound");
        });
    };

    fetchOrderAndResults();
  }, []);

  const generatePDF = () => {
    // Find the report div by its ID
    const reportDiv = document.getElementById("report");
    setLoading(true);
    reportDiv.style.padding = "50px";
    if (reportDiv) {
      // Use html2canvas to capture the content of the "report" div
      html2canvas(reportDiv).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF("p", "mm", "a4");

        // Add the captured content as an image to the PDF
        const width = pdf.internal.pageSize.getWidth();
        const height = (canvas.height * width) / canvas.width;
        pdf.addImage(imgData, "PNG", 0, 0, width, height);

        // Save the PDF with a specific filename
        pdf.save("report.pdf");
        reportDiv.style.padding = "0px";
        setLoading(false);
      });
    }
  };

  let content;

  // Use a switch to handle different cases
  switch (true) {
    case capabilities.includes("resultados") === false:
      content = (
        <div className={styles.errorMessage}>
          Você não tem permissão para acessar resultados de avaliações.
          <br />
          <WarningCircle style={{ marginTop: 15 }} size={32} />
        </div>
      );
      break;

    case loading:
      content = (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
      );
      break;
    case !order.id === null:
      content = (
        <div className={styles.errorMessage}>
          Essa avaliação não existe.
          <br />
          <WarningCircle style={{ marginTop: 15 }} size={32} />
        </div>
      );
      break;
    case Array.isArray(results && results.results) &&
      results.results.length > 0 &&
      groupedQuestions != null:
      content = (
        <div id="fontPdf">
          <Button
            className={styles.transparent}
            onClick={() => setIsPopupOpenned(true)}
          >
            <DotsThreeOutline size={32} color="#603e3e" weight="fill" />
          </Button>
          {isPopupOpenned == true ? (
            <>
              <div className={styles.newItemPopUp}>
                <>
                  <span
                    className={
                      styles.titlePopUp + " " + styles.spanDefaultReport
                    }
                  >
                    Ações
                  </span>
                </>
                <div className={styles.popUpDivisor}></div>
                <>
                  <Button
                    className={styles.optionsPopUp + " " + "transparent"}
                    onClick={() => exportPDFWithComponent()}
                  >
                    <span className={styles.spanDefaultReport}>
                      Exportar PDF
                    </span>
                  </Button>
                </>
              </div>
              <div
                className={styles.overlay}
                onClick={() => controlPopUp()}
              ></div>
            </>
          ) : (
            <div className={styles.overlayOff}></div>
          )}
          <DefaultReportTemplate
            order={order}
            questions={groupedQuestions}
            results={results}
            pdfExportComponent={pdfExportComponent}
            exportPDFWithComponent={exportPDFWithComponent}
          />
          {/* {console.log(results)} */}
        </div>
      );
      break;

    case results === "Permission denied.":
      content = (
        <div className={styles.errorMessage}>
          Você não tem permissão para visualizar esta avaliação.
          <br />
          <WarningCircle style={{ marginTop: 15 }} size={32} />
        </div>
      );
      break;
    case results.totalSubmissions === 0:
      content = (
        <div className={styles.errorMessage}>
          Não há respostas para esta avaliação.
          <br />
          <WarningCircle style={{ marginTop: 15 }} size={32} />
        </div>
      );
      break;
    default:
      content = (
        <div className={styles.errorMessage}>
          Não há respostas para esta avaliação.
          <br />
          <WarningCircle style={{ marginTop: 15 }} size={32} />
        </div>
      ); // Or any other default behavior you want
  }

  return <Layout>{content}</Layout>;
};

import fetch from 'node-fetch';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from "aws-amplify";
import { verifySession } from 'Services/authenticationServices';
import imageCompression from 'browser-image-compression';


const stage = process.env.REACT_APP_STAGE;

async function compressImage(imageFile) {
    const options = {
      maxSizeMB: 0.2,  // Further reduce size limit for greater compression
      maxWidthOrHeight: 1024,  // Reduce max dimensions for greater compression
      useWebWorker: true,
    };
  
    try {
      console.log("Compressing image:", imageFile);
      const compressedFile = await imageCompression(imageFile, options);
      console.log("Compressed file:", compressedFile);
      return compressedFile;
    } catch (error) {
      console.error("Error during image compression:", error);
      throw error;  // Re-throw the error after logging it
    }
  }
  
  function convertImageToPNG(imageFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
  
          canvas.toBlob((blob) => {
            resolve(blob);
          }, 'image/png');
        };
        img.src = event.target.result;
      };
      reader.onerror = (error) => {
        console.error("Error reading image file:", error);
        reject(error);
      };
      reader.readAsDataURL(imageFile);
    });
  }
  
  function convertToBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => {
        console.error("Error converting to base64:", error);
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  }
  
  function base64ToBlob(base64, contentType = '') {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }


export async function NewUser(first_name, last_name, email, photo, role, requester) {
    
    let apiResponse; 

    const fetchData = async (jwtToken) => { 
        const informacoesParaEnvio = {
            method: 'POST',
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': jwtToken 
            },
            body: JSON.stringify({
                email:email,
                first_name:first_name,
                last_name:last_name,
                photo:photo,
                role:role,
                created_by:requester.username
            })
        };

        await fetch(`https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users`, informacoesParaEnvio)
            .then(response => response.json())
            .then(data => {
                apiResponse = data
            }); 
  
    }

    //Verifies user session to check if it's authenticated
    let getJwt = await verifySession() 

    await fetchData(getJwt)
 
    return apiResponse

}

export async function PatchUser(id, first_name, last_name, email, photo, role, requester) {
    console.log("Patching user...");
    let apiResponse;
    console.log(requester);
  
    const fetchData = async (jwtToken, photo) => {
      const informacoesParaEnvio = {
        method: 'PATCH',
        headers: { 
          'Content-Type': 'application/json', 
          'Authorization': jwtToken 
        },
        body: JSON.stringify({
          email: email,
          first_name: first_name,
          last_name: last_name,
          photo: photo,
          role: role,
          created_by: requester.username
        })
      };
      try {
        const response = await fetch(`https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users/` + id, informacoesParaEnvio);
        apiResponse = await response.json();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  
    try {
      // Verifies user session to check if it's authenticated
      let getJwt = await verifySession();
  
      // Process the photo if it is not already in the correct format
      console.log("photo before processing:", photo);

      if (!photo.includes('/images/')) {
  
        if (typeof photo === 'string' && photo.includes('data:image/')) {
            // Convert base64 to Blob
            const contentType = photo.match(/data:(.*?);base64/)[1];
            photo = base64ToBlob(photo, contentType);
        }
    
        if (!(photo instanceof Blob)) {
            throw new Error("The file given is not an instance of Blob or File");
        }
    
        console.log("photoFile before PNG conversion:", photo);
    
        if (!photo.type.includes("png")) {
            photo = await convertImageToPNG(photo);
        }
    
        console.log("photoFile after PNG conversion:", photo);
    
        const compressedFile = await compressImage(photo);
        photo = await convertToBase64(compressedFile);
    
        console.log("converted and compressed image:", photo);
    }
  
      await fetchData(getJwt, photo);

    } catch (error) {
      console.error("Error in PatchUser:", error);
      console.log(photo)
    }
  
    console.log(apiResponse);
    return apiResponse;
  }
import { XCircle } from "phosphor-react"; 

const CloseButton = ({color="#0064a2"}) => {
  return (
    <span>
      <XCircle size={32} color={color} />
    </span>
  );
};

export default CloseButton;

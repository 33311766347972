import Avatar from "components/Avatar/avatar";
import UserName from "components/UserName/userName";
import avatar from "../../assets/avatar.jpg";
import styles from "./user.module.scss";
import { useAuthenticator } from "@aws-amplify/ui-react";

const User = () => {

  const { user } = useAuthenticator((context) => [context.user]);

  const id = user.username;

  const userPhoto = localStorage.getItem("userInfo#" + id);

  const validateURL = (url) => {
    return !!url ? url : avatar;
  };

  return (
    <div className={styles.user}>
      <div className={styles.userAvatar}>
        <Avatar src={validateURL(userPhoto)}></Avatar>
      </div>
      <div className={styles.userNameEdit}>
        <UserName className={"userNameMenuWhite"} isEditable={true} />
      </div>
    </div>
  );
};

export default User;

import React from "react";
import styles from "./NPSfield.module.scss";
import Field from "components/Field/field";

const NPSfield = ({ languages, npsItems, setNPSItems, disabled }) => {
  return (
    <>
      {languages.pt ? (
        <div>
          <span>Qual é a probabilidade de você recomendar&nbsp;&nbsp;</span>
          <Field
            className={styles.textField}
            placeholder={"Exemplo: esta sessão"}
            defaultValue={npsItems.pt}
            disabled={disabled}
            onChange={(event) => {
              let _npsItems = { ...npsItems };
              event.target.value == ""
                ? delete _npsItems.pt
                : (_npsItems.pt = event.target.value);
              setNPSItems(_npsItems);
            }}
          />
          <span>&nbsp;&nbsp;a um(a) amigo(a) ou colega?</span>
        </div>
      ) : null}
      {languages.en ? (
        <div>
          <span>How likely are you to recommend&nbsp;&nbsp;</span>
          <Field
            className={styles.textField}
            placeholder={"Example: this session"}
            defaultValue={npsItems.en}
            disabled={disabled}
            onChange={(event) => {
              let _npsItems = { ...npsItems };
              event.target.value == ""
                ? delete _npsItems.en
                : (_npsItems.en = event.target.value);
              setNPSItems(_npsItems);
            }}
          />
          <span>&nbsp;&nbsp;to a friend or colleague?</span>
        </div>
      ) : null}
      {languages.es ? (
        <div>
          <span>¿Qué tan probable es que recomiende&nbsp;&nbsp;</span>
          <Field
            className={styles.textField}
            placeholder={"Ejemplo: esta sesión"}
            defaultValue={npsItems.es}
            disabled={disabled}
            onChange={(event) => {
              let _npsItems = { ...npsItems };
              event.target.value == ""
                ? delete _npsItems.es
                : (_npsItems.es = event.target.value);
              setNPSItems(_npsItems);
            }}
          />
          <span>&nbsp;&nbsp;a un(a) amigo(a) o colega?</span>
        </div>
      ) : null}
    </>
  );
};

export default NPSfield;

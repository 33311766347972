import { SearchOutlined } from "@ant-design/icons";
import Button from "components/Button/button";
import Field from "components/Field/field";
import GridMenu from "components/GridMenu/gridMenu";
import Layout from "components/Layout/layout";
import SurveyStatus from "components/SurveyStatus/surveyStatus";
import UserName from "components/UserName/userName";
import ReactLoading from "react-loading";
import * as constants from "../../constants/menuItemsContants";
import styles from "./dashboard.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { defaultAvatar } from "components/UserSettings/defaultAvatar";
import Loading from "components/Loading/loading";

const Dashboard = () => {
  let { userParams } = useParams();

  const [name, setName] = useState();
  const [photo, setPhoto] = useState(defaultAvatar);

  const [role, setRole] = useState();

  const [loading, setLoading] = useState(true);

  const [listOfCapabilities, setListOfCapabilities] = useState([]);

  const { user } = useAuthenticator((context) => [context.user]);
  const stage = process.env.REACT_APP_STAGE;
  const navigate = useNavigate();
  const fetchData = () => {
    const id = user.username;

    if (userParams != "new") {
      fetch(
        `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/users/` +
        id
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => { 
           
          // Extract and format the capabilities
          console.log(data)
          console.log(data["capabilities"])
          const capabilities = data["capabilities"].map(item => item.S);
          const formattedCapabilities = JSON.stringify(capabilities); // Convert to a string for storage

          setName(data["first_name"]);
          setPhoto(data["photo"]);
          localStorage.setItem("userInfo#" + id, data["photo"]);
          localStorage.setItem("firstName#" + id, data["first_name"]);
          localStorage.setItem("role#" + id, data["role"]);
          setRole(data["role"]);
          localStorage.setItem("capabilities#" + id, formattedCapabilities); 
          setListOfCapabilities(capabilities)
          if(data["role"] === "Docente" && capabilities.length === 1) {
            navigate("/reports")
          }
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  });

  return (
    <Layout>
      {loading == true ? (
        <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
      ) : (
        <div className={styles.dashboard}>
          {/* após ter a requisição de BE para dados do usuário (autenticação), fazer a lógica para pegar o nome dentro do componente UserName e retirar a props children */}
          <div className={styles.dashboardWelcome}>
            <span>Olá, </span>
            <UserName className="userNameMenuCyan" />
          </div>
          <div className={styles.dashboardElements}>
            <Field
              className={"fieldMedium"}
              placeholder="Buscar"
              type={"text"}
              iconComponent={<SearchOutlined />}
            ></Field>
          </div>
          <div className={styles.dashboardGrid}>
            {listOfCapabilities.length > 0 && constants.gridsMenu.map((gridMenu) => {
  
              let capabilities = listOfCapabilities

              if(role === "Administrador")  { 
                capabilities.push("Admin") 
              }

              if(capabilities.includes(gridMenu.title)) {
              return(
              <GridMenu
                key={gridMenu.title}
                menuItemsProps={gridMenu.menuItemsProps}
                colorLine={gridMenu.colorLine}
                title={gridMenu.title}
                iconUrl={gridMenu.iconUrl}
                imgColor={gridMenu.imgColor}
              ></GridMenu>
            )}
            
            })}
          </div>
          <div className={styles.dashboardElements}>
            <SurveyStatus></SurveyStatus>
          </div>
          <div className={styles.dashboardElements}>
            <Button className="primaryOrange">
              Avaliações encerradas nos últimos 15 dias
            </Button>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Dashboard;

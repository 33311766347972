import { Auth } from "aws-amplify";


// Returns user data such as id, name, email
export async function checkUser() {
    let user = await Auth.currentAuthenticatedUser();     
    return user
}


// Verifies user session to check if it's authenticated
export const verifySession = async () => { 
    let jwt;
    await Auth.currentSession()
    .then(data => {
        // the user session infos will be stored in the data variable.
        // to recover jwt token we'll use "data.getIdToken().getJwtToken()". 
        jwt = data.getIdToken().getJwtToken();    
        console.log(jwt) 
             
    }) 
    .catch(error => {
        // in case session is not OK it will throw an error
        console.log('Erro ao recuperar a sessão:', error);
        jwt = 'Erro ao recuperar a sessão'
    });

    // return JWT token which is responsible for authenticating in every request to back-end
    // JWT will be returned as a string
    return jwt
}
// import button.module.css in page where the component will added
import "./button.scss";
const Button = ({
  className,
  children,
  onClick,
  icon,
  title,
  isDisabled,
  id,
}) => {
  return (
    <button
      disabled={isDisabled}
      id={id}
      className={className}
      onClick={onClick}
      title={title}
    >
      {icon}
      {children}
    </button>
  );
};

export default Button;

import { Funnel } from "phosphor-react";
import styles from "./TableFilter.module.scss";
import { useState } from "react";
import Checkbox from "components/Checkbox/checkbox";
import Field from "components/Field/field";

// - list: the values that will be shown in the table.
// - setList: responsible for returning back to the parent component the filtered list.
// - filterableParameter: what are the parameters that will be available to be used as a filter.
//ex: [{parameter:'category', displayName:'Categoria',dataType:'string'}, {parameter:'dateCreated', displayName:'Data de criação',dataType:'date'}]

const TableFilter = ({ list, setList, filterableParameters, className }) => {
  // overlay is the grey wall under the popup, if it's true then it will be visible
  // overlayState and popupState should always have the same value, if Overlay is visible (true), then popup should also be visible
  const [overlayState, setOverlayState] = useState(false);
  const [popupState, setPopupState] = useState(false);

  // this is the copy of the first version of the variable list, in case the user wants to clean all filters, it'll be used to recover all existing values
  const [memmory, setMemmory] = useState(list);

  const [valuesToFilter, setValuesToFilter] = useState(getFilterableValues());

  // list containing all parameters applied to filter (ex: category, role, date)
  const [parametersFiltered, setParametersFiltered] = useState([]);

  // object containing all values filtered by parameter (ex: {role: ['admin','moderator]} )
  // if this object contains a parameter as a key, the same parameter should be present in parametersFiltered array
  const [filtersApplied, setFiltersApplied] = useState({});

  function allAreTrue(arr) {
    return arr.every((element) => element === true);
  }

  /**
   * Filters the list of items based on the specified filters and parameters.
   *
   * @param {Object} _filtersApplied - The filters applied to the list.
   * @param {Array} _parametersFiltered - The list of parameters used for filtering.
   * @param {string} type - The type of filtering to apply (not used in the function).
   */
  function filterList(_filtersApplied, _parametersFiltered, type) {
    let _filteredList = [];

    // Loop through each item in the 'memmory' array (assuming 'memmory' is defined somewhere).
    memmory.map((i) => {
      let hasObjectProperty;
      let accordanceStatus = [];

      // Check if there are parameters to be filtered.
      if (_parametersFiltered.length > 0) {
        _parametersFiltered.map((parameter) => {
          // Check if the current parameter exists in the applied filters.
          if (_filtersApplied[parameter] != undefined) {
            // Check the data type of the current parameter.
            if (typeof i[parameter] == "string") {
              // If the parameter is a string, check if it matches any of the applied filters.
              if (_filtersApplied[parameter].includes(i[parameter])) {
                accordanceStatus.push(true);
              } else {
                accordanceStatus.push(false);
              }
            } else if (typeof i[parameter] == "object") {
              // If the parameter is an object, check if it contains all the variations in the applied filters.
              hasObjectProperty = true;
              let objectAccordanceStatus = [];

              // For each parameter variation added to the filter.
              _filtersApplied[parameter].forEach((element) => {
                console.log(element);

                // Check if the current parameter variation exists in the list of keys.
                if (i[parameter].includes(element)) {
                  objectAccordanceStatus.push(true);
                } else {
                  objectAccordanceStatus.push(false);
                }
              });

              // If all parameter variations are true, push true to the accordanceStatus.
              if (objectAccordanceStatus.length > 0) {
                if (allAreTrue(objectAccordanceStatus) === true) {
                  accordanceStatus.push(true);
                } else {
                  accordanceStatus.push(false);
                }
              }
            } else if (parameter.startsWith("date#")) {
              let cleanedParameterName = parameter.split("#");
              cleanedParameterName = cleanedParameterName[1];
              let rowDate = new Date(i[cleanedParameterName]);
              let conditions = [];

              // Check if 'from' date is specified and compare with the row's date.
              if (
                _filtersApplied[parameter][0].from != null &&
                _filtersApplied[parameter][0].from != ""
              ) {
                let _from = _filtersApplied[parameter][0].from + " 00:00:00";
                let fromDate = new Date(_from);
                if (rowDate >= fromDate) {
                  conditions.push(true);
                } else {
                  conditions.push(false);
                }
              }

              // Check if 'to' date is specified and compare with the row's date.
              if (
                _filtersApplied[parameter][0].to != null &&
                _filtersApplied[parameter][0].to != ""
              ) {
                let _to = _filtersApplied[parameter][0].to + " 23:59:59";
                let toDate = new Date(_to);
                if (rowDate <= toDate) {
                  conditions.push(true);
                } else {
                  conditions.push(false);
                }
              }

              if (
                _filtersApplied[parameter][0].from == null ||
                (_filtersApplied[parameter][0].from == "" &&
                  _filtersApplied[parameter][0].to == null) ||
                _filtersApplied[parameter][0].to == ""
              ) {
                conditions.push(true);
              }

              // If all conditions are true, push true to the accordanceStatus.
              if (!conditions.includes(false) && conditions.length > 0) {
                accordanceStatus.push(true);
              } else {
                accordanceStatus.push(false);
              }
            }
          }
        });
      } else {
        // If no parameters to filter, add the item to the filtered list.
        _filteredList.push(i);
      }

      // Check if accordanceStatus contains true for all filters applied and add the item to the filtered list.
      if (accordanceStatus.length > 0) {
        if (allAreTrue(accordanceStatus) == true) {
          if (!_filteredList.includes(i)) {
            _filteredList.push(i);
          }
        }
      }
    });

    // Set the filtered list as the new list (assuming 'setList' is defined somewhere).
    setList(_filteredList);
  }

  // State hook to manage the date range filter
  const [dateRange, setDateRange] = useState({ from: null, to: null });

  /**
   * Handles the application of a filter value to the list.
   *
   * @param {string} type - The type of filter being applied (e.g., "date").
   * @param {string} parameter - The parameter to which the filter is applied.
   * @param {any} value - The value of the filter being applied.
   */
  function handleApplyValueToFilter(type, parameter, value) {
    // Create local copies of the filters and filtered parameters
    let _filtersApplied = { ...filtersApplied };
    let _parametersFiltered = [...parametersFiltered];

    // Check if the selected parameter is already applied
    // If the parameter is being added to the filter for the FIRST TIME, it won't be a key in _filtersApplied dictionary, so the key should be placed in the dictionary
    if (parameter in _filtersApplied) {
      if (type == "date") {
        parameter = "date#" + parameter;
      }
      // Removes a value-parameter from the filter
      if (_filtersApplied[parameter].includes(value)) {
        let indexOfElement = _filtersApplied[parameter].indexOf(value);
        _filtersApplied[parameter].splice(indexOfElement, 1);

        // In case no value is selected for a parameter, then it should not be a key anymore, and its key will be removed
        // Its element in 'parametersFiltered' will also be removed
        if (_filtersApplied[parameter].length === 0) {
          delete _filtersApplied[parameter];

          _parametersFiltered.splice(_parametersFiltered.indexOf(parameter), 1);
          setParametersFiltered(_parametersFiltered);
        }
      }
      // Adds a value-parameter to the filter
      else {
        _filtersApplied[parameter].push(value);
      }
    } else {
      // If the filter is of type "date", create a parameter name with the "date#" prefix
      if (type === "date") {
        let parameterName = "date#" + parameter;
        _filtersApplied[parameterName] = [];
        _filtersApplied[parameterName].push(value);
        _parametersFiltered.push(parameterName);
        setParametersFiltered(_parametersFiltered);
        console.log(_parametersFiltered);
      } else {
        _filtersApplied[parameter] = [];
        _filtersApplied[parameter].push(value);
        _parametersFiltered.push(parameter);
        setParametersFiltered(_parametersFiltered);
      }
    }

    // Apply the filters to the list using the 'filterList' function (assuming 'filterList' is defined somewhere)
    filterList(_filtersApplied, _parametersFiltered, type);

    // Update the state with the new filters
    setFiltersApplied(_filtersApplied);
  }

  // Opens and closes pop-up/overlay
  function controlPopUp() {
    if (popupState == true) {
      setPopupState(false);
      setOverlayState(false);
    } else {
      setPopupState(true);
      setOverlayState(true);
    }
  }

  // this function will loop the list and get all the values of the filterable parameters
  // ex: if there's a filterable parameter called color, this function will get all the existing variations of color values in the list
  function getFilterableValues() {
    let values = {};
    filterableParameters.map((i) => {
      let parameter = i.parameter;
      let _values = [];
      list.map((row) => {
        if (i.dataType == "string") {
          if (_values.includes(row[parameter]) == false) {
            _values.push(row[parameter]);
          }
        } else if (i.dataType == "array") {
          let parameterArray = row[parameter];
          parameterArray.forEach((element) => {
            if (_values.includes(element) == false) {
              _values.push(element);
            }
          });
        }
      });
      values[parameter] = {
        parameterDisplayName: i.displayName,
        values: _values,
      };
    });

    // list.map((i) => {
    //   filterableParameters.map((parameter) => {
    //     let parameterName = parameter.parameter; //parameter.languages
    //     if (parameterName in values) {
    //       let listOfValues = values[parameterName].values;
    //       if (i.dataType == "string") {
    //           if (listOfValues.includes(i[parameterName]) == false) {
    //             listOfValues.push(i[parameterName]);
    //             values[parameterName].values = listOfValues;
    //           }
    //       } else if (i.dataType == "array") {
    //       }
    //     } else {
    //       values[parameterName] = {
    //         parameterDisplayName: parameter.displayName,
    //         values: [i[parameterName]],
    //       };
    //     }
    //   });
    // });

    return values;
  }

  return (
    <div className={className}>
        <Funnel
          className={styles.funnelIcon}
          onClick={() => controlPopUp()}
          size={22}
        />
      {overlayState == true ? (
        <div className={styles.overlay} onClick={() => controlPopUp()}></div>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
      {popupState == true && (
        <div className={styles.filterPopUp}>
          <div className={styles.header}>
            Filtro
            <div
              className={styles.cleanFilter}
              onClick={() => {
                setList(memmory);
                let _filtersApplied = filtersApplied;
                parametersFiltered.map((i) => {
                  delete _filtersApplied[i];
                });
                setFiltersApplied(_filtersApplied);
                setPopupState(false);
                setOverlayState(false);
              }}
            >
              Limpar filtros
            </div>
          </div>
          <div className={styles.filterBody}>
            {filterableParameters.map((i, k) => {
              let listOfFilterableValues = valuesToFilter[i.parameter].values;

              if (
                i.parameter in filtersApplied ||
                "date#" + i.parameter in filtersApplied
              ) {
                return (
                  <div key={k} className={styles.parameterItem}>
                    <div className={styles.headerParameter}>
                      <div className={styles.square}></div>
                      {i.displayName}
                      <div className={styles.filterableValuesList}>
                        {i.dataType === "date" && (
                          <div>
                            <div>
                              De:
                              <Field
                                type={"date"}
                                value={dateRange.from}
                                onChange={(event) => {
                                  let _dateRange = dateRange;
                                  _dateRange.from = event.target.value;
                                  setDateRange(_dateRange);
                                  console.log(_dateRange);
                                  handleApplyValueToFilter(
                                    i.dataType,
                                    i.parameter,
                                    _dateRange
                                  );
                                }}
                              />
                            </div>
                            <div style={{ marginTop: 5 }}>
                              Até:
                              <Field
                                type={"date"}
                                value={dateRange.to}
                                onChange={(event) => {
                                  let _dateRange = dateRange;
                                  _dateRange.to = event.target.value;
                                  setDateRange(_dateRange);
                                  console.log(_dateRange);
                                  handleApplyValueToFilter(
                                    i.dataType,
                                    i.parameter,
                                    _dateRange
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {(i.dataType === "string" || i.dataType === "array") &&
                          listOfFilterableValues.map((item) => {
                            return (
                              <div className={styles.filterableValue}>
                                {filtersApplied[i.parameter].includes(item) ? (
                                  <span>
                                    {" "}
                                    <Checkbox
                                      className={styles.filterableValueCheckbox}
                                      checked={true}
                                      onClick={() =>
                                        handleApplyValueToFilter(
                                          i.dataType,
                                          i.parameter,
                                          item
                                        )
                                      }
                                    />{" "}
                                  </span>
                                ) : (
                                  <Checkbox
                                    className={styles.filterableValueCheckbox}
                                    onClick={() =>
                                      handleApplyValueToFilter(
                                        i.dataType,
                                        i.parameter,
                                        item
                                      )
                                    }
                                  />
                                )}
                                {item}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={k} className={styles.parameterItem}>
                    <div className={styles.headerParameter}>
                      {i.displayName}
                      <div className={styles.filterableValuesList}>
                        {i.dataType === "date" && (
                          <div>
                            <div>
                              De:
                              <Field
                                type={"date"}
                                onChange={(event) => {
                                  let _dateRange = dateRange;
                                  _dateRange.from = event.target.value;
                                  setDateRange(_dateRange);
                                  console.log(_dateRange);
                                  handleApplyValueToFilter(
                                    i.dataType,
                                    i.parameter,
                                    _dateRange
                                  );
                                }}
                              />
                            </div>
                            <div style={{ marginTop: 5 }}>
                              Até:
                              <Field
                                type={"date"}
                                onChange={(event) => {
                                  let _dateRange = dateRange;
                                  _dateRange.to = event.target.value;
                                  setDateRange(_dateRange);
                                  console.log(_dateRange);
                                  handleApplyValueToFilter(
                                    i.dataType,
                                    i.parameter,
                                    _dateRange
                                  );
                                }}
                              />
                            </div>
                          </div>
                        )}
                        {(i.dataType == "string" || i.dataType == "array") &&
                          listOfFilterableValues.map((item) => {
                            return (
                              <div className={styles.filterableValue}>
                                <Checkbox
                                  className={styles.filterableValueCheckbox}
                                  onClick={() =>
                                    handleApplyValueToFilter(
                                      i.dataType,
                                      i.parameter,
                                      item
                                    )
                                  }
                                />{" "}
                                {item}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default TableFilter;

import Alert from "components/Alert/Alert";
import moment from 'moment';
import Loading from "../Loading/loading";
import ReactDOM from "react-dom";
import { archiveAssessmentById } from "components/AssessmentLists/assessmentListsServices";
import { getGroupedResults } from "Services/results";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  Archive,
  ArrowsDownUp,
  DotsThree,
  Eye,
  FilePdf,
  FileXls,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import { useNavigate, useParams } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";
import { useAuthenticator } from "@aws-amplify/ui-react";
import styles from "./reportsList.module.scss";
import { getAllOldReports, getAllReports, getFilteredOldReports, getOldReportsFiltered } from "./reportsService";
import Button from "components/Button/button";
import { DefaultReportTemplate } from "components/DefaultReport/defaultReport";
import { styled, Tooltip, tooltipClasses } from "@mui/material";
import { getAssessmentById } from "components/EditAssessment/assessmentService";
import Field from "components/Field/field";
const { PDFExport } = require("@progress/kendo-react-pdf");

const OldReportsList = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [alerts, setAlerts] = useState([]);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [ascendingOrDescending, setAscendingOrDescending] =
    useState("ascending");
  let { orderId } = useParams();
  const [order, setOrder] = useState();
  const [results, setResults] = useState();
  const [groupedQuestions, setGroupedQuestions] = useState();
  const pdfExportComponent = useRef(null);
  const [evaluationStartDate, setEvaluationStartDate] = useState('');
  const [evaluationEndDate, setEvaluationEndDate] = useState('');
  const [overlayState, setOverlayState] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [disabledFilter, setDisabledFilter] = useState(true);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  useEffect(() => {

    if (capabilities.includes("resultados")) {
      if (resultList.length == 0) {
        getAllOldReports(setResultList, setMemResultList, setLoading);
      }
    } else {
      setLoading(false);
    }
  }, []);

  const handleDateChange = (event, setDate) => {
    const date = new Date(event.target.value);
    const formattedDate = date.toISOString().split('T')[0];
    setDate(formattedDate);
  };

  const handleClass = () => {
    const buttonDisabled = disabledFilter ? styles.buttonDisabled : "";
    const className =
      `${styles.filterButton} ${"primaryOrange"} ${buttonDisabled}`;
    // styles.filterButton + " " + "primaryOrange" + buttonDisabled;
    return className;
  };

  const filterAlert = () => {
    // let message = "Aguarde enquanto o filtro é carregado...";
    // let updateAlertList = addNewAlert([], {
    //   message,
    //   type: "info",
    // });
    // setAlerts(updateAlertList);
    setLoading(true);
  };

  // Opens and closes pop-up/overlay
  function controlPopUp() {
    if (overlayState == true) {
      // setPopupState(false);
      setOverlayState(false);
    } else {
      // setPopupState(true);
      setOverlayState(true);
    }
  }

  const disabledStartDate = (current) => {
    if (!endDate) {
      return current && current > moment().endOf('day'); // Only disable future dates if endDate is not set
    }

    // Calculate the minimum date allowed (3 years before endDate)
    const minDate = moment(endDate).subtract(3, 'years');

    // Disable dates that are not in the range of [minDate, endDate]
    return current && (current < minDate || current > endDate || current > moment().endOf('day'));
  };

  const disabledEndDate = (current) => {
    if (!startDate) {
      return current && current < moment().startOf('day'); // Only disable past dates if startDate is not set
    }

    // Calculate the maximum date allowed (3 years from startDate)
    const maxDate = moment(startDate).add(3, 'years');

    // Disable dates that are not in the range of [startDate, maxDate]
    return current && (current < startDate || current > maxDate);
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };


  //Sort list by specific key or restore original response
  function sortObjByKey(key, order = null) {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        // Assuming the date format is "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) {
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        // Parse dates and compare
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB);

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB; // Default sort (ascending)

      } else {
        // Compare as strings or numbers
        const type =
          typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string') {
          _resultList = dateA.localeCompare(dateB);
        }

        else {
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    // Check if the list was ever sorted during the session
    if (memResultList.length === 0 && listIsSorted === false) {
      // Save the original API response to memory
      setMemResultList(resultList);

      // Sort the user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      // In case the list was already sorted before
      // In case the list isn't currently sorted
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        // In case the list is currently sorted
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  //Sort list by specific key or restore original response
  function sortObjByKeyTitle(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
    },
  }));

  function organizeBlocks(questionnaire) {
    let organizedQuestionnaire = [];
    //let _questionnaire = [...questionnaire]
    questionnaire &&
      questionnaire.map((block, index) => {
        //let organizedBlock = []
        let organizedQuestions = collectionOrganizer(
          block.collection.questions
        );
        //block.collection.questions = collectionOrganizer(listOfQuestions)
        let thisBlock = JSON.parse(JSON.stringify(block));
        thisBlock.collection.questions = organizedQuestions;

        organizedQuestionnaire.push(thisBlock);
      });
    return organizedQuestionnaire;
  }

  function collectionOrganizer(questions) {
    let organizedCollection = [];
    let group = null;
    let lastGroupIndex = null;
    questions &&
      questions.map((question, index) => {
        if (question.questionType === "label") {
          group = {
            ...question,
            type: "group",
            label: question.header,
            questions: [],
          };

          lastGroupIndex = index;
          organizedCollection.push(group);
        } else {
          if (lastGroupIndex != null) {
            group.questions.push(question);
          } else {
            let _question = { ...question };
            _question.type = "question";
            organizedCollection.push(_question);
          }
        }
      });
    return organizedCollection;
  }

  const renderContent = () => {
    switch (loading) {
      case true:
        return (
          <Loading />
        );
      // return (
      //   <div className={styles.loading}>
      //     <div className="large">Carregando informações</div>
      //     <center>
      //       <ReactLoading
      //         type="bubbles"
      //         color="#0064a2"
      //         height={50}
      //         width={175}
      //       />
      //     </center>
      //   </div>
      // );
      default:

        if (capabilities.includes("resultados")) {


          return (
            <div>
              <TableControl
                resultList={resultList}
                setResultList={setResultList}
                originalListTable={memResultList}
                setTablePagination={setTablePagination}
                setSlice={setSlice}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                showByPageDefault={10}
                searchPlaceholder={"Buscar Avaliação"}
                hideBulkActions={true}
                hideFilter={true}
              ></TableControl>
              <div className={styles.dateFilter}>
                <div className={styles.itemRow}>
                  <div className={styles.titleDate}>Selecione o período das avaliações que deseja buscar</div>
                  <div className={styles.dateFieldRow}>
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      de
                    </span>
                    <Field
                      className={styles.dateField}
                      type={"date"}
                      id={"initialDate"}
                      onChange={(event) => {
                        setDisabledFilter(false);
                        handleDateChange(event, setStartDate, handleStartDateChange);

                      }}
                      defaultValue={startDate ? new Date(startDate).toISOString().substr(0, 10) : ''}
                      disabledDate={disabledStartDate}

                    />
                    <span style={{ fontSize: "14px", fontWeight: "600" }}>
                      até
                    </span>
                    <Field
                      className={styles.dateField}
                      type={"date"}
                      id={"initialDate"}
                      onChange={(event) => {
                        setDisabledFilter(false);
                        handleDateChange(event, setEndDate, handleEndDateChange)
                      }}
                      defaultValue={endDate ? new Date(endDate).toISOString().substr(0, 10) : ''}
                      disabledDate={disabledEndDate}
                    />
                    <Button
                      className={handleClass()}
                      onClick={async () => {
                        filterAlert();
                        let results = await getOldReportsFiltered(startDate, endDate);
                        console.log(results);
                        setResultList(results);
                        controlPopUp()
                        setAlerts([]);
                        setLoading(false);
                      }}
                      isDisabled={disabledFilter}
                    >
                      Filtrar
                    </Button>
                    <Button
                      className={styles.resetButton + " " + "secondaryOrange"}
                      onClick={window.location.reload}
                    >
                      Redefinir a busca
                    </Button>

                  </div>
                </div>
              </div>
              <div className={styles.info}>Ao clicar em um relatório, você será redirecionado para a versão anterior do sistema. Ressaltamos que a navegação nesta versão pode demandar um tempo maior do que o habitual.</div>
              {alerts.map((i, k) => {
                return <Alert message={i.message} type={i.type} />;
              })}

              <Pagination
                tablePagination={tablePagination}
                slice={slice}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              ></Pagination>
              <table className={styles.table}>
                <thead>
                  <tr style={{ backgroundColor: "white" }}>
                    <th
                      style={{
                        width: "25%",
                        textAlign: "left",
                        paddingLeft: "30px",
                      }}
                    >
                      Título
                      {/* <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("post_title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "10%" }}>
                      Data da Avaliação
                      {/* <ArrowsDownUp
                      onClick={() => {
                        if (ascendingOrDescending === "ascending")
                          setAscendingOrDescending("descending");
                        else setAscendingOrDescending("ascending");
                        sortObjByKey(
                          "Data",
                          ascendingOrDescending === "descending"
                            ? "ascending"
                            : "descending"
                        );
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "15%" }}>
                      Área
                      {/* <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("Área")}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "10%" }}>
                      Programa
                      {/* <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("Programa")}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "10%" }}>
                      Turma
                      {/* <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("Turma")}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "10%" }}>
                      Cabeçalho
                      {/* <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("Linha1")}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "10%" }}>
                      Empresa
                      {/* <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("Empresa")}
                      className={styles.arrowsDownUp}
                      size={16}
                    /> */}
                    </th>
                    <th style={{ width: "10%" }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {slice.map((item, i) => {
                    let id = item.ID;
                    return (
                      <tr key={i} id={"actions" + i} className={styles.tableRow}>
                        <td
                          className={
                            styles.reportRow +
                            " " +
                            styles.reportTitle +
                            " " +
                            styles.rowTitle
                          }
                        >
                          {item.post_title}
                        </td>
                        <td className={styles.reportRow}>{item.Data}</td>
                        <td className={styles.reportRow}>{item.Área}</td>
                        <td
                          className={styles.reportRow}
                        >
                          {item.Programa}
                        </td>
                        <td
                          className={styles.reportRow}
                        >
                          {item.Turma === "sem cadastro" ? "N/A" : item.Turma}
                        </td>
                        <td
                          title={item.Linha1 + " | " + item.Linha2 + " | " + item.Linha3 + "|" + item.Linha4}
                          className={styles.reportRow + " " + styles.reportHeaderRow}
                        >
                          {item.Linha1 + " | " + item.Linha2 + " | " + item.Linha3 + "|" + item.Linha4}

                        </td>
                        <td className={styles.reportRow}>
                          {item.Empresa === "sem cadastro" ? "N/A" : item.Empresa}
                        </td>
                        <td className={styles.actionsContainer}>
                          <Eye
                            alt="Ver Relatório"
                            size={28}
                            className={styles.showReportButton}
                            onClick={() =>
                              window.open(`https://survey.ise.org.br/wp-admin/admin.php?page=single_report&preview=single_report&order_id=${id}`, "_blank").focus()
                            }
                          />

                          <FilePdf
                            alt="Download PDF"
                            size={28}
                            className={styles.downloadReportButton}
                            onClick={() => {
                              window.open(`https://survey.ise.org.br/wp-admin/admin.php?page=ended&action=download_pdf&request=${id}`, "_blank").focus()
                            }}
                          />
                          <FileXls
                            alt="Download XLS"
                            size={28}
                            className={styles.downloadReportButton}
                            onClick={() => {
                              window.open(`https://survey.ise.org.br/wp-admin/admin.php?page=single_report&export_report_xlsx=true&order_id=${id}`, "_blank").focus()
                            }}
                          />
                          {/* <div className={styles.helpPopupdownload}>
                            Download XLS
                          </div> */}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )



        } else {
          return (
            <div className={styles.error}>
              <center>
                <Alert
                  type={"danger"}
                  message={
                    "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                  }
                />
              </center>
            </div>
          )
        }

    }
  }

  return (
    <Layout>
      <div>
        <div className={styles.oldPageHeader}>
          <PageTitle center={true}>Relatórios Antigos</PageTitle>
        </div>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default OldReportsList;

import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseAssessmentURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/orders/`;

export async function getAllAssessmentByStatus(
  status,
  setList,
  setMemList,
  setLoading
) {
  const assessment = await getAssessmentByStatus(status);
  setList(assessment);
  setMemList(assessment);
  setLoading(false);
}

export async function getAssessmentByStatus(status) {
  let jwtToken = await verifySession();
  let assessmentData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  const getAssessmentByStatus = baseAssessmentURL + "status/" + status;

  await fetch(getAssessmentByStatus, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      assessmentData = data;

      assessmentData.map((assessment, index) => {
        assessmentData[index].initialDate = formatDate(
          assessment.meta.opening,
          "DD/MM/YYYY"
        );
        assessmentData[index].finalDate = formatDate(
          assessment.meta.closing,
          "DD/MM/YYYY"
        );
        assessmentData[index].link = window.location.href.replace(
          "readyOrders",
          assessment.id
        );
      });
      console.log(assessmentData);
    });

  return assessmentData;
}

export async function updateAssessmentStatusById(id, assessmentBody) {
  let apiResponse;
  const updateAssessmentURL = baseAssessmentURL + id + "/status";

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(assessmentBody),
    };

    await fetch(updateAssessmentURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

export async function archiveAssessmentById(id) {
  let apiResponse;
  const archiveAssessmentURL = baseAssessmentURL + "archive/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(archiveAssessmentURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

export async function getPPTX(id, qrCode, title, powerPoint = null){

  let apiResponse;
  const pptxUrl = baseAssessmentURL + id + '/pptx';

  const body = {
    qrCode: qrCode
  }

  if(powerPoint !== null){
    body.powerPoint = powerPoint
  }

  const fetchData = async (body) => {
    body = JSON.stringify(body)
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz",
      },
      body 
    };
    console.log(parameters)
    await fetch(pptxUrl, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj; 
    });

  }
 
  await fetchData(body)
  console.log(apiResponse)

  const downloadPPTX = document.createElement('a');
  downloadPPTX.href = `data:pptx;base64,${apiResponse.body.pptx}`;
  downloadPPTX.download = `${id}_${title}.pptx`
  downloadPPTX.click()
  return apiResponse
}

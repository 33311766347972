import { verifySession } from "Services/authenticationServices";

const stage = process.env.REACT_APP_STAGE;
const baseCollectionURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/collections/`;

export async function getCollectionById(id) {
  let jwtToken = await verifySession();
  let collectionData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "SVNFX1N1cnZleTphcGk0cHVibDFjUjNzMHVyY2Vz",
    },
  };

  const getCollectionByIdURL = baseCollectionURL + id;

  await fetch(getCollectionByIdURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      collectionData = data;
    });
  return collectionData;
}

export async function createCollection(collectionBody) {
  let apiResponse;
  let jwtToken = await verifySession();

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(collectionBody),
    };

    await fetch(baseCollectionURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function updateCollectionById(collectionBody, id) {
  let apiResponse;
  let jwtToken = await verifySession();
  let updateCollectionURL = baseCollectionURL + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(collectionBody),
    };

    await fetch(updateCollectionURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function archiveCollectionById(id) {
  let apiResponse;
  const archiveCollectionURL = baseCollectionURL + "archive/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(archiveCollectionURL, parameters)
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((obj) => {
        apiResponse = obj;
      });
  };

  let getJwt = await verifySession();

  await fetchData(getJwt);
  return apiResponse;
}

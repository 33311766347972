import { Modal } from "antd";
import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import {
  archiveCollectionById,
  createCollection,
  getCollectionById,
} from "components/EditCollection/collectionServices";
import Field from "components/Field/field";
import { useAuthenticator } from "@aws-amplify/ui-react";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl"; 
import {
  Archive,
  ArrowsDownUp,
  Copy,
  DotsThree,
  Pencil,
  PlusCircle,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";
import { addNewAlert } from "Services/alertsManagement";
import { getUsers } from "components/UsersList/usersServices";
import styles from "./collectionsList.module.scss";
import { getAllCollections, removeAccessOfUserInCollection, grantAccessToUserInCollection } from "./collectionsService";
import Select from "react-select";
import Loading from "components/Loading/loading";

const CollectionsList = () => {
  const navigate = useNavigate();

  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [defaultTitle, setDefaultTitle] = useState("");
  const [collectionIdCopy, setCollectionIdCopy] = useState();
  const [listIsSorted, setListIsSorted] = useState(false);

  const [alertsPopup, setAlertsPopup] = useState([]); 

  const [disableCopy, setDisableCopy] = useState(false);

  const [isPopupPermissionOpenned, setIsPopupPermissionOpenned] = useState(false);
  const [filteredUsersList, setFilteredUsersList] = useState([])
  const [indexOfItemInSlice, setIndexOfItemInSlice] = useState()
  const [opennedItem, setOpennedItem] = useState()
  const [usersList, setUsersList] = useState([]);
  const [selectedUser, setSelectedUser] = useState([]); 
  const [accessList, setAccessList] = useState([]);
  const [currentItemId, setCurrentItemId] = useState();
  const [ascendingOrDescending, setAscendingOrDescending] = useState(null);  
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);
  
  

  const getUsersList = async () => {
    let dataUsers = await getUsers();
    let _usersList = [];
    dataUsers.map((user) => {
      const userLabel = user.first_name + " (" + user.email + ")";
      _usersList.push({ value: user.id, label: userLabel, photo: user.photo, name: user.first_name + " " + user.last_name });
    });
    console.log(_usersList)
    setUsersList(_usersList);
  };
 

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setDisableCopy(false);
    setIsModalOpen(false);
  };

  const copyModal = () => {
    showModal();
  };

  useEffect(() => {
    if (resultList.length == 0) {
      getAllCollections(setResultList, setMemResultList, setLoading);
      getUsersList();
    }
  }, []);

  

  const handleCopy = async () => {
    let updateAlertList = "";
    let collectiontoCopy = await getCollectionById(collectionIdCopy);
    collectiontoCopy.title = defaultTitle;

    createCollection(collectiontoCopy).then((newCollection) => {
      if (newCollection.status == 200) {
        const id = newCollection.body.collection.pk.split("#");
        navigate("/collection/" + id[1]);
      } else {
        let message = newCollection.body.message;
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
      setLoading(false);
    });
  };

   //Sort list by specific key or restore original response
   function sortObjByKey(key, order = null) {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        // Assuming the date format is "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) {
        console.log(obj)
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);    
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        // Parse dates and compare
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB); 

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB; // Default sort (ascending)

      } else {
        // Compare as strings or numbers
        const type =
          typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string' && ascendingOrDescendingTitle === 'A') {    
          setAscendingOrDescendingTitle('B')
          _resultList = dateA.localeCompare(dateB);
        }         
          
        else {       
          setAscendingOrDescendingTitle('A')
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    // Check if the list was ever sorted during the session
    if (memResultList.length === 0 && listIsSorted === false) {
      // Save the original API response to memory
      setMemResultList(resultList);

      // Sort the user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      // In case the list was already sorted before
      // In case the list isn't currently sorted
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        // In case the list is currently sorted
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }
  //Sort list by specific key or restore original response
  function sortObjByKeyTitle(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const renderContent = () => {

    switch (loading) {
      case true:
        return (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        );
      case false:
        if (capabilities.includes("cadastros")) {
          return(
            <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Padrão"}
              filterableParameters={[]}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  {" "}
                  <th style={{ width: "50vw" }} className={styles.tableTitle}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "25%" }}>
                    Data
                    <ArrowsDownUp 
                      onClick={() => {
                        console.log(ascendingOrDescending)
                        if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending') 
                        else setAscendingOrDescending('ascending')
                        sortObjByKey("createdAt", ascendingOrDescending === 'descending' ? 'ascending' : 'descending')
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "25%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        className={
                          styles.collectionRow + " " + styles.collectionTitle
                        }
                      >
                        {item.title}
                      </td>
                      <td
                        className={
                          styles.collectionRow + " " + styles.alignCenter
                        }
                      >
                        {item.date}
                      </td>
                      <td className={styles.actionsContainer}>
                        <Copy
                          className={styles.copyCollectionButton}
                          size={28}
                          onClick={() => {
                            let title = item.title + " - Copy";
                            setCollectionIdCopy(item.id);
                            setDefaultTitle(title);
                            copyModal();
                          }}
                        />
                        <div className={styles.helpPopupCopy}>
                          Copiar Padrão
                        </div>
                        <Pencil
                          className={styles.editCollectionButton}
                          onClick={() => window.open("/collection/" + item.id, '_blank').focus()}
                          size={28}
                        />
                        <div className={styles.helpPopupEdit}>
                          Editar Padrão
                        </div>
                        <DotsThree
                          size={28}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            if (!showMoreActions[id]) {
                              _showMoreActions[id] = true;
                            }
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] == true ? (
                          <MoreActionsCollectionsList
                            index={i}
                            id={item.id}
                            item={item}
                            setShowMoreActions={setShowMoreActions}
                            showMoreActions={showMoreActions}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setResultList={setResultList}
                            setMemResultList={setMemResultList}
                            setLoading={setLoading}
                            setIsPopupPermissionOpenned={setIsPopupPermissionOpenned}
                            setCurrentItemId={setCurrentItemId}
                            setFilteredUsersList={setFilteredUsersList}
                            usersList={usersList}
                            setAccessList={setAccessList}
                            setIndexOfItemInSlice={setIndexOfItemInSlice}
                            setOpennedItem={setOpennedItem}
                          ></MoreActionsCollectionsList>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          </div>
          )
        }else{
          return(
            <div className={styles.error}>
            <center>
              <Alert
                type={"danger"}
                message={
                  "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                }
              />
            </center>
          </div>
          )
        }
    }
  };

           

  return (
    <Layout>
      <div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.createCollectionButton + " " + "primaryOrange"}
            onClick={() => navigate("/collection/new")}
          >
            <PlusCircle size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Criar Padrão</span>
          </Button>
          <Button
            title={"Padrões Arquivados"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedCollections")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
        </div>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Cadastro de Padrões</PageTitle>
        </div>
        {renderContent()}
        <Modal
          title={"Título do novo padrão a ser copiado:"}
          open={isModalOpen}
          width={"25rem"}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="cancel" onClick={handleCancel} className={styles.tabsButtonFooter + " " + "secondaryOrange"}>
              Cancelar
            </Button>,
            <Button
              key="copiar"
              type="primary"
              loading={loading}
              onClick={handleCopy}
              isDisabled={disableCopy}
              className={styles.tabsButtonFooter + " " + "primaryOrange"}
            >
              Copiar
            </Button>,
          ]}
          centered
        >
          <Field
            key={"titleField"}
            name={"titleField"}
            id={"titleField"}
            value={defaultTitle}
            onChange={(event) => {
              if (event.target.value == "") {
                setDisableCopy(true);
              } else {
                setDisableCopy(false);
              }
              setDefaultTitle(event.target.value);
            }}
          ></Field>
        </Modal>
      </div>
      {isPopupPermissionOpenned == true ? (
        <>
          <div className={styles.permissionPopup}>
            <div className={styles.header}>Usuarios com permissão ao padrão:<br/> {opennedItem.title}</div>
            <div className={styles.popupRow}> 
              <span>
                Adicionar Usuario
              </span>  
              <Select 
                options={filteredUsersList} 
                placeholder="Selecione um usuario" 
                onChange={(event) => setSelectedUser(event)}  
              /> 
              <Button
                  className={styles.tabsButtonFooter + " " + "primaryOrange"}
                  style={{marginTop:15}}  
                  onClick={() => {
                    let grantAccess = grantAccessToUserInCollection(currentItemId, selectedUser.value)
                    console.log(grantAccess)
                    let accessGrantedList = [...accessList]
                    console.log(accessList)
                    accessGrantedList.push({grantedOn:"Agora", userId:selectedUser.value})
                    setAccessList(accessGrantedList)

                    let _slice = {...slice}
                    _slice[indexOfItemInSlice].accessGranted = accessGrantedList  
                    console.log(selectedUser)
                    let updateAlertList = "";
                    let message = selectedUser.name + " adicionado(a).";
                    updateAlertList = addNewAlert([], { message, type: "success" });
                    setAlertsPopup(updateAlertList);
                  }}
                >
                  Adicionar
              </Button>
              {console.log(opennedItem)}
            </div>
            <div> 
            <div className={styles.accessList}> 

            {accessList.map((item, index) => {
                // Find the corresponding user from usersList
                const user = usersList.find(user => user.value === item.userId);
                
                // If user is found, display their name
                const userName = user ? user.name : '...carregando' 
                const userPhoto = user ? user.photo : '...carregando'; 

                console.log(index + " / " + (opennedItem.accessGranted.length - 1))
              
                return ( 
                    <div key={index} className={styles.userInformation}>
                        <div className={styles.permissionUserPhoto}>
                          <img src={userPhoto} style={{width: 50, borderRadius:50}} /> 
                        </div>
                        <div className={styles.permissionUserData}>
                          <span>{userName}</span><br/>
                          <span className={styles.accessGrantedDate}>Concedido em: {item.grantedOn}</span><br/>
                          <a className={styles.removePermission} onClick={() => {   
                            let accessGrantedList = [...accessList]
                            accessGrantedList.splice(index, 1)
                            setAccessList(accessGrantedList) 
                            console.log(index)
                            console.log(accessGrantedList)
                            removeAccessOfUserInCollection(currentItemId, item.userId) 
                            let _slice = {...slice}
                            _slice[indexOfItemInSlice].accessGranted = accessGrantedList  
                            let updateAlertList = "";
                            let message = userName + " removido(a).";
                            updateAlertList = addNewAlert([], { message, type: "danger" }); 
                          }} style={{color:"red"}}>Remover</a>
                        </div>
                    </div>
                  
                );
            })}
            
            </div>
            
            </div>
            <div className={styles.popupFooter}> 

              <Button
                className={styles.tabsButtonFooter + " " + "secondaryOrange"}
                onClick={() => { 
                  setIsPopupPermissionOpenned(false); 
                }}
              >
                Cancelar
              </Button>
            </div>
          </div>
          <div
            className={styles.overlay} 
          ></div>
        </>
      ) : (
        <div className={styles.overlayOff}></div>
      )}
    </Layout>
  );
};

export default CollectionsList;

export const MoreActionsCollectionsList = ({
  index,
  id,
  item,
  setShowMoreActions,
  showMoreActions,
  alerts,
  setAlerts,
  setResultList,
  setMemResultList,
  setLoading,
  setIsPopupPermissionOpenned,
  setCurrentItemId,
  setFilteredUsersList,
  usersList,
  setAccessList,
  setIndexOfItemInSlice,
  setOpennedItem
}) => {
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  const wrapperRef = useRef(null);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  const [activePopUp, setActivePopUp] = useState(true);

  const archiveCollection = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);

    archiveCollectionById(id).then((response) => {
      if (response.status == 200) {
        setArchiveConfirmationModal(false);
        let message = "Padrão arquivado com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllCollections(setResultList, setMemResultList, setLoading);
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)} />

      </div>
      <ConfirmationModal
        id={"archiveCollection"}
        iconComponent={<div className={styles.popupOption}>Arquivar</div>}
        title={"Deseja realmente arquivar este padrão?"}
        onClick={() => setArchiveConfirmationModal(true)}
        open={archiveConfirmationModal}
        onOk={() => archiveCollection()}
        onCancel={() => {
          setArchiveConfirmationModal(false);
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }}
      />
      <span className={styles.popupOption} onClick={() => {  
          setShowMoreActions(false);
          setIsPopupPermissionOpenned(true); 
          setOpennedItem(item)

          // Check if item.accessGranted exists, if not, initialize it as an empty array
            if (!item.hasOwnProperty('accessGranted')) {
              item.accessGranted = [];
          }


          setAccessList(item.accessGranted)
          console.log(item)
          let splitId = item.id;
          setCurrentItemId(splitId); 
          let _usersList = [...usersList]
          setIndexOfItemInSlice(index)
          if(item.accessGranted.length > 0) {
            // Assuming _usersList and item.accessGranted are arrays of objects
            let updatedUsersList = _usersList.filter(user => {
              // Check if user ID is not present in any item.accessGranted
              return !item.accessGranted.some(grantedUser => grantedUser.userId === user.value);
            });

            // Now updatedUsersList contains users from _usersList excluding those present in item.accessGranted
            setFilteredUsersList(updatedUsersList)
          }else {
            setFilteredUsersList(_usersList)
          }
        
      }}>Permissoes</span>
    </div>
  );
};

import "@aws-amplify/ui-react/styles.css";
// loginArea.scss can't be created was module, because the Amplify component doesn't changed with module css
import "./loginArea.scss";
import {
  Authenticator,
  View,
  useAuthenticator,
  withAuthenticator,
} from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";

import awsExports from "../../aws-exports";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";

import { I18n } from "aws-amplify";
// Impot dict created to ptBR
import dict from "../../i18n.js";

// set the dict was created
I18n.putVocabularies(dict);
// set the language to ptBR
I18n.setLanguage("pt-br");

Amplify.configure(awsExports);

Amplify.configure(awsExports);

function LoginArea({ signOut, user }) {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/dashboard";
  useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
      console.log(location.state);
    }
  }, []);
  return (
    <>
      <View className="auth-wrapper">
        <Authenticator></Authenticator>
      </View>
    </>
  );
}

export default withAuthenticator(LoginArea);

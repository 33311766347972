import { UploadOutlined } from "@ant-design/icons";
import Avatar from "components/Avatar/avatar";
import React, { useState } from "react";

import avatar from "../../assets/avatar.jpg";

import styles from "./uploadAvatarField.module.scss";

const UploadAvatarField = ({ setAvatar, defaultValue }) => {
  const [imageAvatar, setImageAvatar] = useState(defaultValue);

  const toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  const changeImageAvatar = (event) => {
    if (event.target.files && event.target.files[0]) {
      let _url = URL.createObjectURL(event.target.files[0]);
      //console.log(_url)
      toDataURL(_url).then((dataUrl) => {
        console.log("RESULT:", dataUrl);
        setAvatar(dataUrl);
      });
      setImageAvatar(_url);
    }
  };

  const validateURL = (url) => {
    return !!url ? url : avatar;
  };

  return (
    <div className={styles.uploadAvatarField}>
      <span className={styles.uploadAvatarTitle}>Foto do perfil</span>
      <div className={styles.uploadAvatarImage}>
        <Avatar src={validateURL(imageAvatar)} width="4rem"></Avatar>
      </div>
      <label className={styles.uploadAvatarLabel}>
        Carregar Imagem
        <UploadOutlined style={{ paddingLeft: "5px", fontSize: "16px" }} />
        <input
          type="file"
          id="uploadAvatar"
          className={styles.inputUpload}
          onChange={changeImageAvatar}
        />
      </label>
    </div>
  );
};

export default UploadAvatarField;

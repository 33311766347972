import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseAssetsURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/assets`;

export async function getAllAssetsItems(
  setList,
  setMemList,
  setLoading,
  asset
) {
  const items = await getAssetsItems(asset);
  setList(items);
  setMemList(items);
  setLoading(false);
}

export async function getAssetsItems(asset) {
  let jwtToken = await verifySession();
  let assetUrl = baseAssetsURL + "/" + asset;
  let assetData;
  const parametersRequest = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(assetUrl, parametersRequest)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      assetData = data;
      if (!data.message) {
        assetData.map((asset) => {
          asset.date = formatDate(asset.created_At, "DD/MM/YYYY");
        });
      }
    });
  return assetData;
}

export async function createAssetItem(itemBody, asset) {
  let apiResponse;
  let jwtToken = await verifySession();
  let assetUrl = baseAssetsURL + "/" + asset;
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(itemBody),
    };

    await fetch(assetUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function updateAssetById(itemBody, asset, id) {
  let apiResponse;
  let jwtToken = await verifySession();
  let assetUrl = baseAssetsURL + "/" + asset + "/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify(itemBody),
    };

    await fetch(assetUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function archiveAssetById(asset, id) {
  let apiResponse;
  let jwtToken = await verifySession();
  let assetUrl = baseAssetsURL + "/" + asset + "/archive/" + id;

  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
    };

    await fetch(assetUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data;
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

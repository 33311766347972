import MenuItems from "components/MenuItems/menuItems";
import MenuSubCategory from "components/MenuSubCategory/menuSubCategory";
import { useState } from "react";
import { useNavigate } from "react-router";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import styles from "./menuCategory.module.scss";

const MenuCategory = ({
  menuItemsProps,
  title,
  iconUrl,
  iconClass,
  labelClass,
  menuItemClass,
  smallMenu,
}) => {
  const [isShowMenuItems, setIsShownMenuItems] = useState(!smallMenu);

  const navigate = useNavigate();

  const handleClick = () => {
    if (smallMenu) {
      setIsShownMenuItems(!isShowMenuItems);
    }
  };
  const currentUrl = window.location.href;

  const [closingConfirmationModal, setClosingConfirmationModal] =
    useState(false);
  const [goTo, setGoTo] = useState("");

  return (
    <div>
      <ConfirmationModal
        onClick={() => setClosingConfirmationModal(true)}
        title={"Deseja sair sem alterar os dados?"}
        open={closingConfirmationModal}
        onOk={() => {
          navigate(goTo);
        }}
        onCancel={() => setClosingConfirmationModal(false)}
        iconComponent={null}
      />
      <div onClick={handleClick}>
        <MenuItems
          key={title}
          iconUrl={iconUrl}
          iconClass={iconClass}
          labelClass={labelClass}
          menuItemClass={menuItemClass}
          smallMenu={smallMenu}
        >
          {title}
        </MenuItems>
      </div>
      {isShowMenuItems
        ? menuItemsProps.map((menuItem) =>
            menuItem.type === "menuSubCategory" ? (
              <MenuSubCategory
                key={menuItem.children}
                iconUrl={menuItem.iconUrl}
                iconClass={menuItem.iconClass}
                labelClass={menuItem.labelClass}
                menuItemClass={menuItem.menuItemClass}
                menuSubItemsProps={menuItem.menuSubItemsProps}
                smallMenu={smallMenu}
              >
                {menuItem.children}
              </MenuSubCategory>
            ) : (
              <MenuItems
                key={menuItem.children}
                iconUrl={menuItem.iconUrl}
                iconClass={
                  currentUrl.includes(menuItem.url)
                    ? styles.iconCurrentPage
                    : menuItem.iconClass
                }
                labelClass={
                  currentUrl.includes(menuItem.url)
                    ? styles.labelCurrentPage
                    : menuItem.labelClass
                }
                menuItemClass={`${
                  currentUrl.includes(menuItem.url)
                    ? styles.menuItemsCurrentPage
                    : menuItem.menuItemClass
                }`}
                smallMenu={smallMenu}
                onClick={() => {
                  if (
                    currentUrl.includes("/order/") ||
                    currentUrl.includes("/question/") ||
                    currentUrl.includes("/collection/") ||
                    currentUrl.includes("/user/")
                  ) {
                    setGoTo(menuItem.url);
                    setClosingConfirmationModal(true);
                  }
                  if (
                    "url" in menuItem &&
                    !currentUrl.includes("/order") &&
                    !currentUrl.includes("/collection/") &&
                    !currentUrl.includes("/question/") &&
                    !currentUrl.includes("/user/")
                  ) {
                    navigate(menuItem.url);
                  }
                }}
              >
                {menuItem.children}
              </MenuItems>
            )
          )
        : ""}
    </div>
  );
};

export default MenuCategory;

import styles from "./surveyStatusItem.module.scss";

const SurveyStatusItem = ({ iconUrl, number, label }) => {
  return (
    <div className={styles.surveyStatusItem}>
      <img className={styles.itemImg} src={iconUrl} alt={label}></img>
      <span className={styles.number}>{number}</span>
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default SurveyStatusItem;

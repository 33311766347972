import styles from './ListControl.module.scss'
import { PlusCircle, MinusCircle } from "phosphor-react"; 


//This component will interact with functions provided from the parent component for adding or removing items of a list
//as addNewItem you should pass the function that will add a new 'empty' item to your list
//as newItemDefaultContent you should pass the content you want to display as default in your new item
//as removeAnItem you should pass the function that will delete a given item from a list
//as indexOfItemToInteractWith you should pass the key of the item

//A great example of how to use this example can be found in the addOptions component code
const ListControl = ({addsNewItem,newItemDefaultContent,removesAnItem,indexOfItemToInteractWith, className}) => {
    return(
        <div className={styles.listControlContainer + ' ' + className}>
            <div >
                <PlusCircle onClick={() => addsNewItem(newItemDefaultContent, indexOfItemToInteractWith+1)} className={styles.plus}  />
            </div>
            <div >
                <MinusCircle onClick={() => removesAnItem(indexOfItemToInteractWith)} className={styles.minus}   />
            </div>
        </div>
    )
}

export default ListControl;
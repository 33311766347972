import { Modal } from "antd";
import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import {
  archiveQuestionById,
  getQuestionById,
} from "components/EditQuestion/questionService";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import Question from "components/Question/question";
import TableControl from "components/TableControl/tableControl";
import {
  Archive,
  ArrowsDownUp,
  DotsThree,
  Eye,
  Pencil,
  PlusCircle,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import ReactLoading from "react-loading";
import { useNavigate } from "react-router";
import { addNewAlert } from "Services/alertsManagement";
import { handleHeaderLabel } from "Services/globalServices";
import { useAuthenticator } from "@aws-amplify/ui-react";
import brazilIcon from "../../assets/icons/brazil.svg";
import spainIcon from "../../assets/icons/spain.svg";
import usIcon from "../../assets/icons/us.svg";
import styles from "./questionsList.module.scss";
import { getAllQuestions } from "./questionsService";
import { useInView } from "react-intersection-observer";
import QuestionPreview from "components/Question/questionPreview";
import Loading from "components/Loading/loading";

const QuestionsList = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionTitle, setQuestionTitle] = useState();
  const [questionData, setQuestionData] = useState();
  const [listIsSorted, setListIsSorted] = useState(false);
  const [questionType, setQuestionType] = useState();
  const [questionHeader, setQuestionHeader] = useState("");
  const [ascendingOrDescending, setAscendingOrDescending] = useState(null);
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  useEffect(() => {
    if (resultList.length == 0) {
      getAllQuestions(setResultList, setMemResultList, setLoading);
    }
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const flagClasses = (hasLanguage) => {
    let disabled = !hasLanguage ? styles.disableFlag : null;
    return styles.flag + " " + disabled;
  };

  function questionModal(i) {
    setQuestionData(i);
    setQuestionTitle(i.title);
    setQuestionType(i.questionType);
    setQuestionHeader(i.header);
    showModal()
  };

  //Sort list by specific key or restore original response
  function sortObjByKey(key, order = null) {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        // Assuming the date format is "DD/MM/YYYY"
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) { 
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);    
      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        // Parse dates and compare
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB); 

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB; // Default sort (ascending)

      } else {
        // Compare as strings or numbers
        const type =
          typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string' && ascendingOrDescendingTitle === 'A') {    
          setAscendingOrDescendingTitle('B')
          _resultList = dateA.localeCompare(dateB);
        }         
          
        else {       
          setAscendingOrDescendingTitle('A')
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    // Check if the list was ever sorted during the session
    if (memResultList.length === 0 && listIsSorted === false) {
      // Save the original API response to memory
      setMemResultList(resultList);

      // Sort the user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      // In case the list was already sorted before
      // In case the list isn't currently sorted
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        // In case the list is currently sorted
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const renderContent = () => {

    switch (loading) {
      case true:
        return (
          <div className={styles.loading}>
          <div className="large">Carregando informações</div>
          <center>
            <ReactLoading
              type="bubbles"
              color="#0064a2"
              height={50}
              width={175}
            />
          </center>
        </div>
        // <Loading />
        );
      case false:
        if(capabilities.includes("cadastros")){
          return (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Questão"}
              filterableParameters={[
                {
                  parameter: "questionTypeLabel",
                  displayName: "Tipo de Escala",
                  dataType: "string",
                },
                {
                  parameter: "languages",
                  displayName: "Idioma",
                  dataType: "array",
                },
                {
                  parameter: "createdAt",
                  displayName: "Data de cadastro",
                  dataType: "date",
                },
              ]}
              hideBulkActions={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  {" "}
                  <th style={{ width: "40vw" }}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>Cabeçalho</th>
                  <th style={{ width: "12%" }}>
                    Tipo de Escala
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("questionTypeLabel")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "10%" }}>
                    Data
                    <ArrowsDownUp
                      onClick={() => { 
                        if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending') 
                        else setAscendingOrDescending('ascending')
                        sortObjByKey("createdAt", ascendingOrDescending === 'descending' ? 'ascending' : 'descending')
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Idiomas</th>
                  <th style={{ width: "15%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id;
                  if ("id" in item) {
                    id = item.id
                  } else {
                    let pk = item.pk
                    pk = pk.split("#")
                    id = pk[1]
                    item.id = id
                  }

                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        className={
                          styles.questionRow + " " + styles.questionTitle
                        }
                      >
                        {item.title}

                      </td>
                      <td className={styles.questionRow}>
                        {handleHeaderLabel(item.header)}
                      </td>
                      <td className={styles.questionRow}>
                        {item.questionTypeLabel}
                      </td>
                      <td className={styles.questionRow}>{item.date}</td>
                      <td className={styles.flagsRow}>
                        <img
                          className={flagClasses(item.header.pt)}
                          src={brazilIcon}
                          alt={"Brazil"}
                          title={item.header.pt}
                        ></img>
                        <img
                          className={flagClasses(item.header.es)}
                          src={spainIcon}
                          alt={"Spain"}
                          title={item.header.es}
                        ></img>
                        <img
                          className={flagClasses(item.header.en)}
                          src={usIcon}
                          alt={"US"}
                          title={item.header.en}
                        ></img>
                      </td>
                      <td className={styles.actionsContainer}>
                        <Pencil
                          className={styles.editQuestionButton}
                          onClick={() => window.open("/question/" + item.id, '_blank').focus()}
                          size={28}
                        />
                        <div className={styles.helpPopupEdit}>
                          Editar Questão
                        </div>
                        <Eye
                          size={28}
                          className={styles.showQuestionButton}
                          onClick={() => questionModal(slice[i])}
                        />
                        <div className={styles.helpPopupPreview}>
                          Ver Questão
                        </div>
                        <DotsThree
                          size={28}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            if (!showMoreActions[id]) {
                              _showMoreActions[id] = true;
                            }
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] == true ? (
                          <MoreActionsQuestionsList
                            index={i}
                            id={item.id}
                            setShowMoreActions={setShowMoreActions}
                            showMoreActions={showMoreActions}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setResultList={setResultList}
                            setMemResultList={setMemResultList}
                            setLoading={setLoading}
                          ></MoreActionsQuestionsList>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
          </div>
          );
        }else{
          return (
            <div className={styles.error}>
            <center>
              <Alert
                type={"danger"}
                message={
                  "Você não possui permissão para acessar essa página. Por favor, entre em contato com o administrador do sistema."
                }
              />
            </center>
          </div>
          );
        }
      }
      }


  return (
    <Layout>
      <div>
        <div className={styles.buttonsContainer}>
          <Button
            className={styles.createQuestionButton + " " + "primaryOrange"}
            onClick={() => navigate("/question/new")}
          >
            <PlusCircle size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Criar Questão</span>
          </Button>
          <Button
            title={"Questões Arquivadas"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedQuestions")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
        </div>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Cadastro de Questões</PageTitle>
        </div>
        {renderContent()} 
        <Modal
          title={questionTitle}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
          centered
        >

          <QuestionPreview
            questionData={questionData}
            questionType={questionType}
            header={questionHeader}
          />
        </Modal>
      </div>
    </Layout>
  );
};

export default QuestionsList;

export const MoreActionsQuestionsList = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
  alerts,
  setAlerts,
  setResultList,
  setMemResultList,
  setLoading,
}) => {
  const [archiveConfirmationModal, setArchiveConfirmationModal] =
    useState(false);

  const wrapperRef = useRef(null);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  const [activePopUp, setActivePopUp] = useState(true);

  const archiveQuestion = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);

    archiveQuestionById(id).then((response) => {
      if (response.status == 200) {
        setArchiveConfirmationModal(false);
        let message = "Questão arquivada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllQuestions(setResultList, setMemResultList, setLoading);
      } else {
        setArchiveConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
      }
    });
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)} />
      </div>
      <ConfirmationModal
        id={"archiveQuestion"}
        iconComponent={<div className={styles.popupOption}>Arquivar</div>}
        title={"Deseja realmente arquivar esta questão?"}
        onClick={() => setArchiveConfirmationModal(true)}
        open={archiveConfirmationModal}
        onOk={() => archiveQuestion()}
        onCancel={() => {
          setArchiveConfirmationModal(false);
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }}
      />
    </div>
  );
};

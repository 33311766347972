import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import TableControl from "components/TableControl/tableControl";
import {
  FilePpt,
  Archive,
  ArrowsDownUp,
  DotsThree,
  Eye,
  Pencil,
  PlusCircle,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import { Link, useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  getAllAssessmentByStatus,
  updateAssessmentStatusById,
  getPPTX
} from "../assessmentListsServices";
import styles from "./readyAssessmentList.module.scss";
import Loading from "components/Loading/loading";

const ReadyAssessmentList = () => {
  const navigate = useNavigate();
  const [memResultList, setMemResultList] = useState([]);
  const [resultList, setResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [listIsSorted, setListIsSorted] = useState(false);
  const [ascendingOrDescending, setAscendingOrDescending] = useState(null);
  const [ascendingOrDescendingTitle, setAscendingOrDescendingTitle] = useState(null);
  const [loadingError, setLoadingError] = useState(false); // Added for error handling

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);



  useEffect(() => {
    if (resultList.length === 0) {
      getAllAssessmentByStatus("ready", setResultList, setMemResultList, setLoading, setLoadingError);
    }
  }, []);

  function sortObjByKey(key, order = null) {
    let _resultList = [...resultList];

    function compare(a, b) {
      function parseDate(dateString) {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      }

      function getDateValue(obj, key) {
        return key.includes('.')
          ? key.split('.').reduce((acc, cur) => acc && acc[cur], obj)
          : obj[key];
      }

      const dateA = getDateValue(a, key);
      const dateB = getDateValue(b, key);

      const isDateKey = key === 'initialDate' || key === 'finalDate';

      if (isDateKey) {
        const parsedDateA = parseDate(dateA);
        const parsedDateB = parseDate(dateB);

        if (order === 'ascending') return parsedDateA - parsedDateB;
        else if (order === 'descending') return parsedDateB - parsedDateA;
        else return parsedDateA - parsedDateB;

      } else {
        const type = typeof dateA === 'string' || typeof dateB === 'string' ? 'string' : 'number';
        let _resultList;

        if (type === 'string' && ascendingOrDescendingTitle === 'A') {
          setAscendingOrDescendingTitle('B')
          _resultList = dateA.localeCompare(dateB);
        }

        else {
          setAscendingOrDescendingTitle('A')
          _resultList = dateB.localeCompare(dateA);
        }
        return _resultList;
      }
    }

    if (memResultList.length === 0 && listIsSorted === false) {
      setMemResultList(resultList);
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        _resultList = _resultList.sort(compare);
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const downloadAlert = () => {
    let message = "Aguarde enquanto o arquivo é gerado...";
    let updateAlertList = addNewAlert([], {
      message,
      type: "info",
    });
    setAlerts(updateAlertList);
  };

  function sortObjByKeyTitle(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      const type = typeof a === "string" || typeof b === "string" ? "string" : "number";
      return type === "string" ? a.localeCompare(b) : a - b;
    }

    if (memResultList.length === 0 && listIsSorted === false) {
      setMemResultList(resultList);
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    } else {
      if (listIsSorted === false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      } else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const renderContent = () => {
    switch (true) {
      case loading:
        return (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading type="bubbles" color="#0064a2" height={50} width={175} />
            </center>
          </div>
          // <Loading />
        );

      case !capabilities.includes("avaliações"):
        return (
          <div className={styles.error}>
            <center><Alert type={"danger"} message={"Você não tem permissão para acessar esta página"} /></center>
          </div>
        );

      case capabilities.includes("avaliações"):
        return (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Item"}
              hideBulkActions={true}
              hideFilter={true}
            />
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
            {alerts.map((alert, index) => (
              <Alert key={index} message={alert.message} type={alert.type} />
            ))}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  <th style={{ width: "40vw", textAlign: "left", paddingLeft: "30px" }}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKeyTitle("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>
                    Data da avaliação
                    <ArrowsDownUp
                      onClick={() => {
                        if (ascendingOrDescending === 'ascending') setAscendingOrDescending('descending');
                        else setAscendingOrDescending('ascending');
                        sortObjByKey("initialDate", ascendingOrDescending === 'descending' ? 'ascending' : 'descending');
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>
                    Final da Coleta
                    <ArrowsDownUp
                      onClick={() => {
                        const newOrder = ascendingOrDescending === 'ascending' ? 'descending' : 'ascending';
                        setAscendingOrDescending(newOrder);
                        sortObjByKey("finalDate", newOrder);
                      }}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Link</th>
                  <th style={{ width: "15%" }}>
                    Autor
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("author.first_name")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {slice.map((item, i) => {
                  let id = item.id;

                  if (!item.hasOwnProperty('powerPoint')) {
                    item.powerPoint = { value: null };
                  }

                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td className={`${styles.assessmentRow} ${styles.assessmentTitle} ${styles.rowTitle}`}>
                        {item.title}
                      </td>
                      <td className={styles.assessmentRow}>
                        {item.initialDate}
                      </td>
                      <td className={styles.assessmentRow}>{item.finalDate}</td>
                      <td className={styles.assessmentRow}>
                        <a href={item.link} target="_blank" rel="noreferrer">{item.link}</a>
                      </td>
                      <td className={styles.assessmentRow}>
                        {item.author.first_name + " " + item.author.last_name}
                      </td>
                      <td className={styles.actionsContainer}>
                        <Pencil
                          className={styles.editAssessmentButton}
                          onClick={() => window.open("/order/" + item.id, '_blank').focus()}
                          size={28}
                        />
                        <div className={styles.helpPopupEdit}>Editar Avaliação</div>
                        <Link rel="noopener noreferrer">
                          <FilePpt
                            size={28}
                            className={styles.pptButton}
                            weight="fill"
                            onClick={() => {
                              downloadAlert();
                              getPPTX(item.id, item.qrCode, item.title, item.powerPoint.value).then((response) => {
                                let message;
                                if (response.status === 200) {
                                  message = "Download concluído, o arquivo '" + item.id + "_" + item.title + ".pptx' está em sua pasta de Downloads";
                                  setAlerts(addNewAlert([], { message, type: "success" }));
                                } else {
                                  message = "O download não foi realizado, tente novamente ou entre em contato com o suporte";
                                  setAlerts(addNewAlert([], { message, type: "warning" }));
                                }
                              });
                            }}
                          />
                          <div className={styles.helpPopupPPT}>Baixar PowerPoint</div>
                        </Link>
                        <Link to={"/" + id + "/preview"} target="_blank" rel="noopener noreferrer">
                          <Eye className={styles.previewAssessmentButton} size={28} />
                          <div className={styles.helpPopupPreview}>Ver Avaliação</div>
                        </Link>
                        <DotsThree
                          size={28}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            const _showMoreActions = { ...showMoreActions };
                            _showMoreActions[id] = !_showMoreActions[id];
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] && (
                          <MoreActionsReadyList
                            index={i}
                            id={item.id}
                            title={item.title}
                            setShowMoreActions={setShowMoreActions}
                            showMoreActions={showMoreActions}
                            alerts={alerts}
                            setAlerts={setAlerts}
                            setResultList={setResultList}
                            setMemResultList={setMemResultList}
                            setLoading={setLoading}
                          />
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );

      default:
        return (
          <div className={styles.error}>
            <center><Alert type={"danger"} message={"Um problema ocorreu ao carregar os dados. Por favor, tente novamente mais tarde."} /></center>
          </div>
        );
    }
  };

  return (
    <Layout>
      <div>
        <div className={styles.buttonsContainer}>
          <Button
            className={`${styles.createAssessmentButton} primaryOrange`}
            onClick={() => navigate("/order/new")}
          >
            <PlusCircle size={22} className={styles.addIcon} />
            <span style={{ paddingBottom: "3px" }}>Criar Avaliação</span>
          </Button>
          <Button
            title={"Avaliações Arquivadas"}
            className={styles.archiveButton}
            onClick={() => navigate("/archivedOrders")}
          >
            <Archive size={28} className={styles.archiveIcon} />
          </Button>
        </div>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Avaliações Prontas</PageTitle>
        </div>
        {renderContent()}
      </div>
    </Layout>
  );
};

export default ReadyAssessmentList;

export const MoreActionsReadyList = ({
  index,
  id,
  title,
  setShowMoreActions,
  showMoreActions,
  alerts,
  setAlerts,
  setResultList,
  setMemResultList,
  setLoading,
}) => {
  const navigate = useNavigate();
  const [advanceCloseConfirmationModal, setAdvanceCloseConfirmationModal] =
    useState(false);

  const wrapperRef = useRef(null);

  const [activePopUp, setActivePopUp] = useState(true);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();
  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);

  const advanceCloseAssessment = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);
    setLoading(true);
    let body = {
      way: true,
    };

    updateAssessmentStatusById(id, body).then((response) => {
      if (response.status == 200) {
        setAdvanceCloseConfirmationModal(false);
        setLoading(false);
        let message = "Avaliação avançada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllAssessmentByStatus(
          "ready",
          setResultList,
          setMemResultList,
          setLoading
        );
      } else {
        setAdvanceCloseConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
        setLoading(false);
      }
    });
  };

  const returnAssessmentToPreparation = () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);
    setLoading(true);
    let body = {
      way: false,
    };

    updateAssessmentStatusById(id, body).then((response) => {
      if (response.status == 200) {
        setAdvanceCloseConfirmationModal(false);
        setLoading(false);
        let message = "Regressão de status realizada com sucesso!";
        let updateAlertList = addNewAlert([], { message, type: "success" });
        setAlerts(updateAlertList);
        getAllAssessmentByStatus(
          "ready",
          setResultList,
          setMemResultList,
          setLoading
        );
      } else {
        setAdvanceCloseConfirmationModal(false);
        let message = response.body.message;
        let updateAlertList = addNewAlert([], { message, type: "danger" });
        setAlerts(updateAlertList);
        setLoading(false);
      }
    });
  };

  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)} />
      </div>
      <ConfirmationModal
        id={"advanceReadyAssessment"}
        iconComponent={
          <div className={styles.popupOption}>Avançar (Encerrada)</div>
        }
        title={`Deseja realmente mudar o status da avaliação "${title}" para encerrada?`}
        onClick={() => {
          setAdvanceCloseConfirmationModal(true);
        }}
        open={advanceCloseConfirmationModal}
        onOk={() => advanceCloseAssessment(showMoreActions[id])}
        onCancel={() => {
          setAdvanceCloseConfirmationModal(false);
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }}
      />
      <ConfirmationModal
        id={"advanceReadyAssessment"}
        iconComponent={
          <div className={styles.popupOption}>Voltar (Elaboração)</div>
        }
        title={`Deseja realmente mudar o status da avaliação "${title}" para elaboração?`}
        onClick={() => {
          setAdvanceCloseConfirmationModal(true);
        }}
        open={advanceCloseConfirmationModal}
        onOk={() => returnAssessmentToPreparation(showMoreActions[id])}
        onCancel={() => {
          setAdvanceCloseConfirmationModal(false);
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }}
      />
    </div>
  );
};

import { Modal } from "antd";
import Alert from "components/Alert/Alert";
import Button from "components/Button/button";
import ConfirmationModal from "components/confirmationModal/confirmationModal";
import { getQuestionById } from "components/EditQuestion/questionService";
import { UnarchiveIcon } from "components/Icon/Icon";
import Layout from "components/Layout/layout";
import PageTitle from "components/PageTitle/pageTitle";
import Pagination from "components/Pagination/pagination";
import Question from "components/Question/question";
import TableControl from "components/TableControl/tableControl";
import {
  ArrowCircleLeft,
  ArrowsDownUp,
  DotsThree,
  Trash,
  X,
} from "phosphor-react";
import React, { useEffect, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import ReactLoading from "react-loading";
import Loading from "components/Loading/loading";
import { useNavigate } from "react-router-dom";
import { addNewAlert } from "Services/alertsManagement";
import { handleHeaderLabel } from "Services/globalServices";

import brazilIcon from "../../assets/icons/brazil.svg";
import spainIcon from "../../assets/icons/spain.svg";
import usIcon from "../../assets/icons/us.svg";
import styles from "./archivedQuestionsList.module.scss";
import {
  deleteQuestionById,
  getAllArchivedQuestions,
  unarchiveQuestionById,
} from "./archivedQuestionsServices";
import { useAuthenticator } from "@aws-amplify/ui-react";

const ArchivedQuestionsList = () => {
  const [resultList, setResultList] = useState([]);
  const [memResultList, setMemResultList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [tablePagination, setTablePagination] = useState([]);
  const [slice, setSlice] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showMoreActions, setShowMoreActions] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionTitle, setQuestionTitle] = useState();
  const [questionData, setQuestionData] = useState();
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);
  const [listIsSorted, setListIsSorted] = useState(false);

  useEffect(() => {
    if (resultList.length == 0) {
      getAllArchivedQuestions(setResultList, setMemResultList, setLoading);
    }
  }, [resultList]);

  const flagClasses = (hasLanguage) => {
    let disabled = !hasLanguage ? styles.disableFlag : null;
    return styles.flag + " " + disabled;
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const unarchiveQuestion = (id) => {
    let updateAlertList = "";
    let message = "";
    unarchiveQuestionById(id).then((response) => {
      let messageResponse = response.message;
      if (messageResponse.includes("has been unarchived")) {
        message = "Questão desarquivada com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedQuestions(setResultList, setMemResultList, setLoading);
      } else {
        message = "Ocorreu um erro ao desarquivar a questão.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  const deleteQuestion = (id) => {
    let updateAlertList = "";
    let message = "";
    setDeleteConfirmationModal(false);
    deleteQuestionById(id).then((response) => {
      let messageResponse = response.message;

      if (messageResponse.includes("Deleted archived question")) {
        message = "Questão deletada com sucesso!";
        updateAlertList = addNewAlert([], { message, type: "success" });
        getAllArchivedQuestions(setResultList, setMemResultList, setLoading);
      } else {
        message = "Ocorreu um erro ao deletar a questão.";
        updateAlertList = addNewAlert([], {
          message,
          type: "danger",
        });
      }
      setAlerts(updateAlertList);
    });
  };

  //Sort list by specific key or restore original response
  function sortObjByKey(key) {
    let _resultList = [...resultList];
    function compare(a, b) {
      a = a[key];
      b = b[key];
      var type =
        typeof a === "string" || typeof b === "string" ? "string" : "number";
      var result;
      if (type === "string") result = a.localeCompare(b);
      else result = a - b;
      return result;
    }

    //Check if list was ever sorted during the session
    if (memResultList.length == 0 && listIsSorted == false) {
      //Saves original user API response to memmory
      setMemResultList(resultList);

      //sort user list
      _resultList = _resultList.sort(compare);
      setListIsSorted(true);
    }

    //In case list was already sorted before
    else {
      //In case list isn't currently sorted
      if (listIsSorted == false && memResultList.length > 0) {
        _resultList = _resultList.sort(compare);
        setListIsSorted(true);
      }
      //In case list is currently sorted
      else {
        _resultList = memResultList;
        setListIsSorted(false);
      }
    }

    setResultList(_resultList);
    return _resultList;
  }

  const { user } = useAuthenticator((context) => [context.user]);
  const id = user.username;
  const userRole = localStorage.getItem("role#" + id);

  return (
    <Layout>
      <div>
        <Button
          className={styles.backButton + " " + "secondaryOrange"}
          onClick={() => navigate("/questions")}
        >
          <ArrowCircleLeft size={22} className={styles.backIcon} />
          <span className={styles.backLabel}>Voltar</span>
        </Button>
        <div className={styles.pageHeader}>
          <PageTitle center={true}>Questões Arquivadas</PageTitle>
        </div>
        {loading == true ? (
          <div className={styles.loading}>
            <div className="large">Carregando informações</div>
            <center>
              <ReactLoading
                type="bubbles"
                color="#0064a2"
                height={50}
                width={175}
              />
            </center>
          </div>
          // <Loading />
        ) : (
          <div>
            <TableControl
              resultList={resultList}
              setResultList={setResultList}
              originalListTable={memResultList}
              setTablePagination={setTablePagination}
              setSlice={setSlice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              showByPageDefault={10}
              searchPlaceholder={"Buscar Questão"}
              hideBulkActions={true}
              hideFilter={true}
            ></TableControl>
            <Pagination
              tablePagination={tablePagination}
              slice={slice}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            ></Pagination>
            {alerts.map((i, k) => {
              return <Alert message={i.message} type={i.type} />;
            })}
            <table className={styles.table}>
              <thead>
                <tr style={{ backgroundColor: "white" }}>
                  {" "}
                  <th style={{ width: "40vw" }}>
                    Título
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("title")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "20%" }}>Cabeçalho</th>
                  <th style={{ width: "12%" }}>
                    Tipo de Escala
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("questionTypeLabel")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "10%" }}>
                    Data
                    <ArrowsDownUp
                      onClick={() => sortObjByKey("createdAt")}
                      className={styles.arrowsDownUp}
                      size={16}
                    />
                  </th>
                  <th style={{ width: "15%" }}>Idiomas</th>
                  <th style={{ width: "15%" }}>Ações</th>
                </tr>
              </thead>
              <tbody>
                {" "}
                {slice.map((item, i) => {
                  let id = item.id;
                  return (
                    <tr key={i} id={"actions" + i} className={styles.tableRow}>
                      <td
                        className={
                          styles.questionRow + " " + styles.questionTitle
                        }
                      >
                        {item.title}
                      </td>
                      <td className={styles.questionRow}>
                        {handleHeaderLabel(item.header)}
                      </td>
                      <td className={styles.questionRow}>
                        {item.questionTypeLabel}
                      </td>
                      <td className={styles.questionRow}>{item.date}</td>
                      <td className={styles.flagsRow}>
                        <img
                          className={flagClasses(item.header.pt)}
                          src={brazilIcon}
                          alt={"Brazil"}
                          title={item.header.pt}
                        ></img>
                        <img
                          className={flagClasses(item.header.es)}
                          src={spainIcon}
                          alt={"Spain"}
                          title={item.header.es}
                        ></img>
                        <img
                          className={flagClasses(item.header.en)}
                          src={usIcon}
                          alt={"US"}
                          title={item.header.en}
                        ></img>
                      </td>
                      <td className={styles.actionsContainer}>
                        <UnarchiveIcon
                          className={styles.unarchiveQuestionButton}
                          size={26}
                          onClick={() => unarchiveQuestion(id)}
                        ></UnarchiveIcon>
                        <div className={styles.helpPopupUnarchive}>
                          Desarquivar Questão
                        </div>
                        {userRole === "Administrador" &&
                          <ConfirmationModal
                            id={"deleteQuestion"}
                            iconComponent={
                              <>
                                <Trash
                                  size={26}
                                  className={styles.deleteQuestionButton}
                                />
                                <div className={styles.helpPopupDelete}>
                                  Deletar Questão
                                </div>
                              </>
                            }
                            title={"Deseja deletar permanentemente esta questão?"}
                            maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
                            onClick={() => setDeleteConfirmationModal(true)}
                            open={deleteConfirmationModal}
                            onOk={() => deleteQuestion(id)}
                            onCancel={() => setDeleteConfirmationModal(false)}
                          />
                        }
                        <DotsThree
                          size={28}
                          title={"Mais ações"}
                          className={styles.moreActionsButton}
                          onClick={() => {
                            let _showMoreActions = { ...showMoreActions };
                            _showMoreActions = {};
                            if (!showMoreActions[id]) {
                              _showMoreActions[id] = true;
                            }
                            setShowMoreActions(_showMoreActions);
                          }}
                        />
                        <div className={styles.helpPopupAction}>Mais ações</div>
                        {showMoreActions[id] == true ? (
                          <MoreActionsArchived
                            index={i}
                            id={item.id}
                            setShowMoreActions={setShowMoreActions}
                            setIsModalOpen={setIsModalOpen}
                            setQuestionTitle={setQuestionTitle}
                            setQuestionData={setQuestionData}
                          ></MoreActionsArchived>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        <Modal
          title={questionTitle}
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[]}
          centered
        >
          <Question questionData={questionData}></Question>
        </Modal>
      </div>
    </Layout>
  );
};

export default ArchivedQuestionsList;

export const MoreActionsArchived = ({
  index,
  id,
  setShowMoreActions,
  showMoreActions,
  setIsModalOpen,
  setQuestionTitle,
  setQuestionData,
}) => {
  const wrapperRef = useRef(null);

  //This const return if a element is alredy in view
  const [refInView, inView] = useInView();

  const [activePopUp, setActivePopUp] = useState(true);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const questionModal = async () => {
    let _showMoreActions = { ...showMoreActions };
    _showMoreActions = {};
    setShowMoreActions(_showMoreActions);

    const questionData = await getQuestionById(id);
    setQuestionTitle(questionData.title);
    setQuestionData(questionData);
    showModal();
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target) && inView) {
          let _showMoreActions = { ...showMoreActions };
          _showMoreActions = {};
          setShowMoreActions(_showMoreActions);
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutside);
      };
    }, []);
  }

  useOutsideAlerter(wrapperRef);

  return (
    <div
      ref={wrapperRef}
      key={index}
      id={"actions" + index}
      className={styles.moreActionsPopup}
      style={{ display: activePopUp ? 'block' : 'none' }}
    >
      <div ref={refInView} className={styles.popupHeader}>
        Mais ações
        <X size={16} className={styles.closeMoreActions}
          onClick={() => setActivePopUp(false)} />
      </div>
      <div className={styles.popupOption} onClick={() => questionModal()}>
        Ver Questão
      </div>
    </div>
  );
};

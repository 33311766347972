import { useAuthenticator } from "@aws-amplify/ui-react";
import MenuCategory from "components/MenuCategory/menuCategory";
import MenuItems from "components/MenuItems/menuItems";
import { useNavigate } from "react-router";

import homeSVG from "../../assets/icons/house.svg";
import burguerSVG from "../../assets/icons/list.svg";
import logoutSVG from "../../assets/icons/power.svg";
import * as constants from "../../constants/menuItemsContants";
import styles from "./smallMenu.module.scss";
import { useState } from "react";
import ConfirmationModal from "components/confirmationModal/confirmationModal";

const SmallMenu = ({ setMenu }) => {
  const handleClick = () => {
    const menuType = "ExpandedMenu"
    setMenu(menuType);
    localStorage.setItem("Menu#", menuType);
  };

  const navigate = useNavigate();

  const { signOut } = useAuthenticator((context) => [context.signOut]);

  function logOut() {
    signOut();
    navigate("/login");
  }

  const { user } = useAuthenticator((context) => [context.user]);

  const capabilities = localStorage.getItem("capabilities#" + user.username);

  const [closingConfirmationModal, setClosingConfirmationModal] = useState(false);
  const currentUrl = window.location.href
  const [goTo, setGoTo] = useState("");

  return (
    <div className={styles.smallMenu}>
      <ConfirmationModal
        onClick={() => setClosingConfirmationModal(true)}
        title={"Deseja sair sem alterar os dados?"}
        open={closingConfirmationModal}
        onOk={() => {
          if (goTo === "Home") {
            navigate("/dashboard")
          } if (goTo === "Logout") {
            logOut()
          }
        }}
        onCancel={() => setClosingConfirmationModal(false)}
        iconComponent={null}
      />
      <div className={styles.smallMenuItems}>
        <div onClick={handleClick} className={styles.smallMenuImg}>
          <img src={burguerSVG} alt="Menu"></img>
        </div>
        <div>
          {user.userRole === "Docente" && capabilities.length === 1 && 
          <MenuItems
            iconUrl={homeSVG}
            iconClass="iconWhite"
            labelClass="labelWhite"
            menuItemClass="menuItemsCyan"
            smallMenu={true}
            onClick={() => {
              if (currentUrl.includes('/order/') || currentUrl.includes('/collection/') || currentUrl.includes('/question/') || currentUrl.includes('/user/')) {
                setClosingConfirmationModal(true)
                setGoTo("Home")
              } else { navigate("/dashboard") }

            }}
          >
            Home
          </MenuItems>
          }
          {constants.categoryMenu.map((category) => {
            if (capabilities) {
            if (capabilities.includes(category.title)) {
              return (
                <MenuCategory
                key={category.title}
                menuItemsProps={category.menuItemsProps}
                title={category.title}
                iconUrl={category.iconUrl}
                iconClass={category.iconClass}
                labelClass={category.labelClass}
                menuItemClass={category.menuItemClass}
                smallMenu={true}
              ></MenuCategory>
              );
            
            }
          }
        }
          )}
          <div>
            <MenuItems
              iconUrl={logoutSVG}
              iconClass="iconWhite"
              labelClass="labelWhite"
              menuItemClass="menuItemsCyan900Clickable"
              alt={"Sair"}
              onClick={() => {
                if (currentUrl.includes('/order/') || currentUrl.includes('/collection/') || currentUrl.includes('/question/') || currentUrl.includes('/user/')) {
                  setClosingConfirmationModal(true)
                  setGoTo("Logout")
                } else { logOut() }

              }}
            >
              Sair
            </MenuItems>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SmallMenu;

import { verifySession } from "Services/authenticationServices";
import { formatDate } from "Services/globalServices";

const stage = process.env.REACT_APP_STAGE;
const baseCollectionURL =
  `https://i7tm39herd.execute-api.us-east-1.amazonaws.com/${stage}/collections`;

export async function getAllCollections(setList, setMemList, setLoading) {
  const collections = await getCollections();
  setList(collections);
  setMemList(collections);
  setLoading(false);

  console.log(collections)
}

export async function getCollections() {
  let jwtToken = await verifySession();
  let collectionsData;
  const parameters = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwtToken,
    },
  };

  await fetch(baseCollectionURL, parameters)
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      collectionsData = data;
      collectionsData.map((collection) => {
        collection.date = formatDate(collection.createdAt, "DD/MM/YYYY");
      });
    });

  return collectionsData;
}

export async function removeAccessOfUserInCollection(itemId, userId) {
  let apiResponse;
  let jwtToken = await verifySession();
  let parameterUrl = baseCollectionURL + "/" + itemId + "/access";
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({"user":userId}),
    };

    await fetch(parameterUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data; 
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}

export async function grantAccessToUserInCollection(itemId, userId) {
  let apiResponse;
  let jwtToken = await verifySession();
  let parameterUrl = baseCollectionURL + "/" + itemId + "/access";
  const fetchData = async (jwtToken) => {
    const parameters = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: jwtToken,
      },
      body: JSON.stringify({"user":userId}),
    };

    await fetch(parameterUrl, parameters)
      .then((response) => response.json())
      .then((data) => {
        apiResponse = data; 
      });
  };

  await fetchData(jwtToken);
  return apiResponse;
}